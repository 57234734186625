import { useEffect, useState } from 'react';
import {
    getCheckoutsForUser,
    listenForChangesInBoxesCustomer,
    listenForChangesInCheckoutsCustomer,
    listenForChangesInCustomerPostIdent
} from "../services/FirestoreService";

export default function useCustomer(userId) {

    const [customer, setCustomer] = useState();
    const [postIdent, setPostIdent] = useState();
    const [checkouts, setCheckouts] = useState();

    useEffect( () =>{
        if(!userId) return;

        const unsubscribeCustomer = listenForChangesInBoxesCustomer(userId, (data) => {
            if(data) {
                setCustomer(data);
            }
        });

        const unsubscribePostIdent = listenForChangesInCustomerPostIdent(userId, (data) => {
            if(data) {
                setPostIdent(data);
            }
        });

        return () => {
            unsubscribeCustomer();
            unsubscribePostIdent();
        }

    }, [userId]);

    useEffect(() => {

        if(!customer) return;
        const unsubscribeCheckouts = listenForChangesInCheckoutsCustomer(userId, (data) => {
            if(data) {
                setCheckouts(data);
            }
        });

        return () => {
            unsubscribeCheckouts();
        }
    }, [customer, userId]);

    return {customer, checkouts, postIdent};
}
