import {FirestoreApiService} from "services/FirestoreApiService";
import {useEffect, useRef, useState} from "react";

export default function useCustomerSubscription(uid) {

    const [customerSubscription, setCustomerSubscription] = useState();
    let unsubscribeSubscription = useRef();

    useEffect(() => {
        if(!uid) {
            if(unsubscribeSubscription.current) unsubscribeSubscription.current();
            return;
        }

        FirestoreApiService.getLastSubscriptionId(uid, subId => {
            unsubscribeSubscription.current = FirestoreApiService.getSubscriptionUpdates(uid, subId, data => {
                setCustomerSubscription(data);
            }, error => {
                console.log("Error: ", error);
            });
        }, error => {
            console.log("Error: ", error);
        })

        return () => {if(unsubscribeSubscription.current) unsubscribeSubscription.current()};
    }, [uid])

    const resetCustomerSubscription = () => {
        setCustomerSubscription(null);
    }

    return {customerSubscription, resetCustomerSubscription};
}
