import {Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {userDashboardPaths} from "constants/paths";
import {useHistory} from "react-router-dom";
import { useAppState } from "contexts/AppStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import {groupDigits, obfuscateString, sanitizeString} from "utils/mobile-app-helpers";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import LoadingView from "components/LoadingView";
import LoadingIndicator from "components/LoadingIndicator";

export default function AddCardView() {
    const [open, setOpen] = useState(false);
    const [registrationStarted, setRegistrationStarted] = useState(false);
    const [token, setToken] = useState('');
    const [tokenValidity, setTokenValidity] = useState(false);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {strings, goBack, customer, customerGunnebo} = useAppState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if(customer && customerGunnebo) setIsLoading(false);
    }, [customer, customerGunnebo, setIsLoading]);

    useEffect(() => {
        if(customerGunnebo?.hasActiveCard && customer?.cardNumber) {
            setToken(customer.cardNumber);

            if(registrationStarted){
                setIsSaving(false);
                enqueueSnackbar(strings['REGISTER_CARD_VIEW_REGISTERED_NOTIFICATION'], {
                    variant: 'success',
                });
            }
        }
    }, [customer, customerGunnebo, setIsSaving, enqueueSnackbar, strings, registrationStarted]);

    const handleRegisterClick = useCallback(() => {
        if (!registrationStarted) {
            setRegistrationStarted(true);
            return;
        }

        setIsSaving(true);

        const registerCard = async ()=>{
            try {
                await FunctionsApiService.customersRegisterCard({number: sanitizeString(token)})
            } catch (error) {
                setIsSaving(false);
                enqueueSnackbar(strings['ERROR_register_card/registration-failed'], {
                    variant: 'error',
                });
            }

        }

        registerCard();
    }, [token, registrationStarted, enqueueSnackbar, setIsSaving, strings]);

    const tokenContainsOnlyDigits = useCallback((tokenString) => {
        if (tokenString === '') {
            return true;
        }
        const reg = new RegExp('^[0-9]+$');
        return reg.test(tokenString);
    }, [])

    const handleTokenChange = useCallback(e => {
        const cleanToken = sanitizeString(e.target.value);
        if (cleanToken.length > 9 || !tokenContainsOnlyDigits(cleanToken)) {
            return;
        }
        setToken(groupDigits(cleanToken, 3, ' '));
        setTokenValidity(cleanToken.length === 9);
    }, [tokenContainsOnlyDigits]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div className="add-card-section">
            <div className="page-header overlay-header">
                <div className="header-placeholder"></div>
                {strings["REGISTER_CARD_VIEW_TITLE"]}
                <IconButton onClick={goBack} size="large">
                    <CloseRoundedIcon/>
                </IconButton>

            </div>

            <div className="overlay">
                <div className='container-blue'>
                    <img srcSet={`${process.env.PUBLIC_URL}/assets/illu_chipcard.png, ${process.env.PUBLIC_URL}/assets/illu_chipcard@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/illu_chipcard.png`} alt=""/>
                </div>

                <p className="register-card-description">
                    {customerGunnebo?.hasActiveCard && !customerGunnebo?.hasBlockedCard ? strings["REGISTER_CARD_VIEW_DESCRIPTION_REGISTERED"] : strings["REGISTER_CARD_VIEW_DESCRIPTION_NOT_REGISTERED"]}
                </p>

                <Dialog className="dialog-window"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            {strings["REGISTER_CARD_VIEW_MODAL_NO_CARD_RECEIVED"]}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="green-button"
                                onClick={() => history.push(userDashboardPaths.customerService)}>{strings["REPORT_LOSS_VIEW_BTN_CUSTOMER_SERVICE"]}</Button>
                        <Button onClick={handleClose} className="default-button">{strings["GENERAL_OK"]}</Button>
                    </DialogActions>
                </Dialog>

                {
                    (!customerGunnebo?.hasActiveCard || customerGunnebo?.hasBlockedCard) &&
                    <>
                        {
                            registrationStarted &&
                            <div>
                                <FormControl variant="outlined" className="input-form customer-card">
                                    <TextField value={token} placeholder={strings['REGISTER_CARD_VIEW_PLACEHOLDER_ENTER_TOKEN']}
                                               disabled={isSaving}
                                               onChange={handleTokenChange}
                                    />
                                </FormControl>
                            </div>
                        }

                        {isSaving
                            ?   <LoadingIndicator marginTop={32}/>
                            :   <Button variant='contained'
                                      className="blue-button"
                                      disabled={registrationStarted && !tokenValidity}
                                      onClick={handleRegisterClick}>
                                {strings["REGISTER_VIEW_BTN_REGISTER"]}
                                </Button>
                        }

                        {!isSaving &&
                            <p className="dialog-underline" onClick={handleClickOpen}>
                                {strings["REGISTER_CARD_VIEW_BTN_NO_CARD_RECEIVED"]}
                            </p>
                        }

                    </>
                }

                {
                    (customerGunnebo?.hasActiveCard && !customerGunnebo?.hasBlockedCard) &&
                    <>
                        <p className="registered-card-title">{strings['REGISTER_CARD_VIEW_LABEL_REGISTERED_CARD']}</p>
                        <div className="registered-card-number">
                            <FormControl variant="outlined">
                                <TextField value={groupDigits(obfuscateString(token, 3, 9), 3, ' ')}
                                           disabled={true}/>
                            </FormControl>
                            <DoneIcon className="checkmark"/>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
