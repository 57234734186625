import React from 'react';
import styled from 'styled-components';

import {useAppState} from "../../../contexts/AppStateContext";

import CheckBox from '../../../components/CheckBox';

import { colors } from "../../../styles/colors";

import { Title, LabelRegularMedium } from "../../../styles/fontStyles";

export default function SelectBoxItem({docId, boxId, boxSize, onChange, isSelected}){

    const { strings } = useAppState();

    return (
        <Container isSelected={isSelected}>
            <CheckBox checked={isSelected} onChange={() => onChange(docId)} />
            <Right>
                <Title>{strings.GENERAL_BOX + " " + strings.GENERAL_NR + boxId}</Title><br/>
                <Subtitle>{strings["GENERAL_BOX_SIZE_" + boxSize]}</Subtitle>
            </Right>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 24px 24px;
    border: ${props => props.isSelected ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    margin-bottom: ${props => props.isLast ? 0 : '12px'};
    background: ${props => props.isSelected ? 'rgba(30,226,200, .1)' : 'transparent'};
`;

const Right = styled.div`
    margin-left: 16px;
`;

const Subtitle = styled(LabelRegularMedium)`
    color: ${colors.primary.primary_300}
`;
