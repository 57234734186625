import axios from 'axios';

axios.defaults.headers.common['authorization'] = process.env.REACT_APP_REST_API_AUTH_TOKEN;

export const getMigrationBoxesForUser = (customerId) => {
    return getCall('/services/migrate-plans/'+customerId);
}

export const setMigrationBoxesForUser = (customerId, boxes) => {
    return postCall('/services/migrate-plans/'+customerId, boxes);
}

const getCall = async (path) => {
    return axios.get(process.env.REACT_APP_REST_API_URL + path);
}

const postCall = async (path, payload) => {
    return axios.post(process.env.REACT_APP_REST_API_URL + path, payload);
}
