import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Sentry from "@sentry/browser";
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";
import FunctionsApiService from "services/FunctionsApiService";

import {
    isValidCity,
    isValidHouseNr,
    isValidPostcode,
    isValidSreetname
} from "../services/InputValidationService";
import { countries } from "../constants/Countries";
import { objectsAreEqual } from '../services/Utils';

import { PageHeaderLinearFlow } from "@asservato/shared.ui.page-header-linear-flow";
import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/Select";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";
import { CheckBox } from "@asservato/shared.ui.checkbox";

import { LabelRegularSmall } from "../styles/fontStyles";
import { colors } from '../styles/colors';
import * as S from '../styles/sharedStyles';
import {useHistory} from "react-router-dom";
import {signupPaths} from "navigation/SignupRoutes";

export default function EnterAddressView({ rootPath, prevPath, nextPath, index }) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, updateActiveStep, setErrorMessage } = useCheckoutState();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [displayBillingAddress, setDisplayBillingAddress] = useState(!objectsAreEqual(formData.address, formData.billingAddress) && formData.billingAddress);
    const history = useHistory();

    const countriesArray = Object.keys(countries).map(country => {
        const languageCode = 'de' // TODO: re-activate once the country list has been translated -> i18n.language.split('-')[0];
        return {
            value: country,
            label: countries[country][languageCode]
        };
    });

    useEffect(() => {
        updateFormData({heroImage:"../assets/checkout/hero_1.jpg"});
        updateActiveStep(index);
        // only track events in signup
        if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.CONTACT_DATA_ENTERED);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFormData])

    useEffect(() => {
        if(displayBillingAddress) {
            if(!displayBillingAddress) {
                updateFormData({
                    billingAddress: formData.address,
                })
            } else {
                if(objectsAreEqual(formData.address, formData.billingAddress)){
                    updateFormData({
                        billingAddress: {
                            appendix: null,
                            street: '',
                            streetNo: '',
                            zip: '',
                            city: '',
                            country: '',
                        }
                    })
                }
            }
        }
    }, [displayBillingAddress, formData.address, formData.billingAddress, updateFormData])

    const onUpdateCustomerData = useCallback(async () => {

        try {
            let customerPayload =   {
                title: formData.professionalTitle,
                salutation: formData.salutation,
                firstName: formData.firstName,
                lastName: formData.lastName,
                company: formData.company,
                address: formData.address,
                billingAddress: displayBillingAddress ? formData.billingAddress : formData.address,
                email: formData.email,
                mobilePhone: formData.phone,
                signup : {
                    location:formData.selectedLocation,
                    box:formData.selectedSize,
                    signupSource:"WEB_SIGNUP"
                },
                temporaryPassword:formData.temporaryPassword
            }

            if(formData.tracking) customerPayload.tracking = formData.tracking;

            await FunctionsApiService.customersUpdate(customerPayload);

            if(!displayBillingAddress) {
                updateFormData({
                    billingAddress: formData.address,
                })
            }

            setIsLoading(false);
            history.push(rootPath + nextPath);

        } catch (error) {
            Sentry.captureException(error);
            setErrorMessage(t('error'));
            setIsLoading(false);
        }

    }, [history, nextPath, rootPath, displayBillingAddress, updateFormData, formData.temporaryPassword, formData.address, formData.billingAddress, formData.company, formData.firstName, formData.lastName, formData.professionalTitle, formData.salutation, formData.phone, formData.tracking, formData.email, formData.selectedLocation, formData.selectedSize, setIsLoading, setErrorMessage, t]);

    const onConfirm = useCallback(async () => {

        if(isLoading) return;

        setIsLoading(true);

        try {
            onUpdateCustomerData();
        } catch (error) {
            Sentry.captureException(error);
            setErrorMessage(t('error'));
            setIsLoading(false);
        }

    }, [onUpdateCustomerData, setIsLoading, isLoading, setErrorMessage, t])

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, rootPath, prevPath]);

    const isAddressValid =
        isValidSreetname(formData.address.street) &&
        isValidHouseNr(formData.address.streetNo) &&
        isValidPostcode(formData.address.zip) &&
        isValidCity(formData.address.city) &&
        !!formData.address.country;

    const isBillingAddressValid = formData.billingAddress &&
        isValidSreetname(formData.billingAddress.street) &&
        isValidHouseNr(formData.billingAddress.streetNo) &&
        isValidPostcode(formData.billingAddress.zip) &&
        isValidCity(formData.billingAddress.city) &&
        !!formData.billingAddress.country;

    const isFormValid =
        (!displayBillingAddress && isAddressValid) ||
        (displayBillingAddress && isAddressValid && isBillingAddressValid);

    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={onBack}
                progressBrackets={progressBrackets}
                onConfirm={onConfirm}
                isConfirmEnabeled={isFormValid}
                showBackButton={true}
            />
            <S.Content>

                <S.Description>
                    {t('enterContactDetails.subheading')}
                </S.Description>

                <FormSection marginTop={'24px'}>

                    <SectionLabel>Lieferadresse für Kundenkarte</SectionLabel>
                    <FormRow>
                        <TextInput label={t('enterAddressDetails.street')}
                                   disabled={false} error={false}
                                   onChange={(e)=>updateFormData({address:{...formData.address, street:e.target.value}})}
                                   value={formData.address.street}
                                   width={'75%'}
                                   isValid={isValidSreetname(formData.address.street)}
                                   name={"street-address"}
                        />
                        <HorizontalSeparator/>
                        <TextInput label={t('enterAddressDetails.streetNumber.placeholder')}
                                   disabled={false} error={false}
                                   onChange={(e)=>updateFormData({address:{...formData.address, streetNo:e.target.value}})}
                                   value={formData.address.streetNo}
                                   width={'25%'}
                                   isValid={isValidHouseNr(formData.address.streetNo)}
                                   name={"houseNr"}
                        />
                    </FormRow>

                    <FormRow marginTop={'16px'}>
                        <TextInput label={t('enterAddressDetails.postcode')}
                                   disabled={false}
                                   error={false}
                                   onChange={(e)=>updateFormData({address:{...formData.address, zip:e.target.value}})}
                                   value={formData.address.zip}
                                   width={'25%'}
                                   isValid={isValidPostcode(formData.address.zip)}
                                   name={"postal-code"}
                        />
                        <HorizontalSeparator/>
                        <TextInput label={t('enterAddressDetails.city')}
                                   disabled={false} error={false}
                                   onChange={(e)=>updateFormData({address:{...formData.address, city:e.target.value}})}
                                   value={formData.address.city}
                                   width={'75%'}
                                   isValid={isValidCity(formData.address.city)}
                                   name={"city"}
                        />
                    </FormRow>
                </FormSection>

                <Select label={t('enterAddressDetails.country')}
                        disabled={false}
                        error={false}
                        margin={'16px 0 0 0'}
                        options={countriesArray}
                        onChange={(e)=>updateFormData({address:{...formData.address, country:e.target.value}})}
                        value={formData.address.country}
                        isValid={formData.address.country?.length > 0}
                />

                <FormRow marginTop={"24px"}>
                    <CheckBox size={"small"} checked={displayBillingAddress} onChange={(e)=>setDisplayBillingAddress(e.target.checked)}/>
                    <Label>Die Rechnungsadresse weicht von der Lieferadresse ab</Label>
                </FormRow>

                {displayBillingAddress
                    ?   <>
                            <FormSection marginTop={'24px'}>

                                <SectionLabel>Rechnungsadresse</SectionLabel>
                                <FormRow>
                                    <TextInput label={t('enterAddressDetails.street')}
                                               disabled={false} error={false}
                                               onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, street:e.target.value}})}
                                               value={formData.billingAddress?.street}
                                               width={'75%'}
                                               isValid={isValidSreetname(formData.billingAddress?.street)}
                                               name={"street-address"}
                                    />
                                    <HorizontalSeparator/>
                                    <TextInput label={t('enterAddressDetails.streetNumber.placeholder')}
                                               disabled={false} error={false}
                                               onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, streetNo:e.target.value}})}
                                               value={formData.billingAddress?.streetNo}
                                               width={'25%'}
                                               isValid={isValidHouseNr(formData.billingAddress?.streetNo)}
                                               name={"houseNr"}
                                    />
                                </FormRow>

                                <FormRow marginTop={'16px'}>
                                    <TextInput label={t('enterAddressDetails.postcode')}
                                               disabled={false}
                                               error={false}
                                               onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, zip:e.target.value}})}
                                               value={formData.billingAddress?.zip}
                                               width={'25%'}
                                               isValid={isValidPostcode(formData.billingAddress?.zip)}
                                               name={"postal-code"}
                                    />
                                    <HorizontalSeparator/>
                                    <TextInput label={t('enterAddressDetails.city')}
                                               disabled={false} error={false}
                                               onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, city:e.target.value}})}
                                               value={formData.billingAddress?.city}
                                               width={'75%'}
                                               isValid={isValidCity(formData.billingAddress?.city)}
                                               name={"city"}
                                    />
                                </FormRow>
                            </FormSection>

                            <Select label={t('enterAddressDetails.country')}
                                    disabled={false}
                                    error={false}
                                    margin={'16px 0 0 0'}
                                    options={countriesArray}
                                    onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, country:e.target.value}})}
                                    value={formData.billingAddress?.country}
                                    isValid={formData.billingAddress?.country?.length > 0}
                            />
                        </>
                    : null

                }

                {!isLoading
                    ? <ButtonPrimary isDisabled={!isFormValid}
                                     label={t('continue')}
                                     marginTop={32}
                                     onClick={onConfirm}
                    />
                    : <LoadingIndicator marginTop={24}/>
                }

                <FormRow marginTop={"48px"}>
                    <Label>{t('enterContactDetails.policy.part1')}
                        <a href='https://legal.asservato.de/datenschutz' target="_privacy" onClick={() => {trackEvent(EVENTS.PRIVACY_POLICY_VIEWED);}}>{t('enterContactDetails.policy.privacy')}</a>
                        {t('enterContactDetails.policy.part2')}
                    </Label>
                </FormRow>

            </S.Content>
        </S.View>
    );
}

const FormSection = styled.div`
    margin-top: ${props => props.marginTop || null};
`;

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HorizontalSeparator = styled.div`
    min-width: 12px;
    flex: 1;
`;

const SectionLabel = styled(LabelRegularSmall)`
    display: block;
    padding-bottom: 12px;
`;

const Label = styled(LabelRegularSmall)`
    a {
        color: ${colors.tertiary.tertiary_100};
    }
`;
