import React from "react";
import styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

export default function LoadingView({absolutePositioning}) {
    return <>
        {
            <Container absolutePositioning={absolutePositioning}>
                <Indicator>
                    <LoadingIndicator size={32} />
                </Indicator>
            </Container>
        }
    </>;
}

const Container = styled.div`
    position: ${props => props.absolutePositioning ? 'absolute' : 'relative'};
    width: 100%;
    height: ${props => props.absolutePositioning ? '100vh' : '100%'};
    z-index: ${props => props.absolutePositioning ? -1 : 10};
    top: ${props => props.absolutePositioning ? 0 : null};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
