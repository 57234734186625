import React from "react";
import clsx from "clsx";

export default function ButtonBadge({label, className = '_white-text', iconLeft, onPress}) {
    return (
        <div className={clsx('user-box', className)} onClick={onPress}>
            {iconLeft ?
                <img className="user-img"
                     srcSet={`${process.env.PUBLIC_URL}/assets/${iconLeft}.png, ${process.env.PUBLIC_URL}/assets/${iconLeft}@2x.png 2x`}
                     src={`${process.env.PUBLIC_URL}/assets/${iconLeft}.png`} alt=""/> : ''}
            {label}
        </div>
    );
}
