import React, {useCallback, useState} from "react";
import { useAppState } from "contexts/AppStateContext";
import {Button, FormControl, InputAdornment, MenuItem, OutlinedInput, Select} from "@mui/material";
import {ExpandMoreRounded} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import {useHistory} from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FunctionsApiService from "services/FunctionsApiService";
import {from, of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {useSnackbar} from "notistack";
import {userDashboardPaths} from "constants/paths";
import NumberFormat from "react-number-format";

export default function RequestAccessFormView() {
    const [salutation, setSalutation] = useState('');
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [code, setCode] = useState('');
    const history = useHistory();
    const {strings, setIsLoading, getSalutations, customer} = useAppState();
    const {enqueueSnackbar} = useSnackbar();
    const [codeValidity, setCodeValidity] = useState(false);

    const hasRequiredCustomerData = customer && customer.firstName?.length > 0 && customer.lastName?.length > 0 && customer.salutation?.length > 0;

    const handleNextClick = useCallback(() => {
        setIsLoading(true);
        let stream = of(true);
        if (!hasRequiredCustomerData) {
            stream = stream.pipe(
                switchMap(result => {
                    return from(FunctionsApiService.customersPartialUpdate({
                        'title': title,
                        'salutation': salutation,
                        'firstName': firstName,
                        'lastName': lastName,
                    })).pipe(catchError(error => {
                        enqueueSnackbar(strings['ERROR/general'], {variant: 'error'});
                        throw error;
                    }));
                }),
            );
        }
        stream = stream.pipe(
            switchMap(result => {
                return from(FunctionsApiService.requestPartnerRequest({
                    code: code.replace(/\s/g, ''),
                })).pipe(
                    catchError(error => {
                        let message = strings['ERROR_REQUEST_BOX_ACCESS/' + error.code];
                        enqueueSnackbar(message ? message : strings['ERROR/general'], {variant: 'error'});
                        throw error;
                    })
                );
            }),
        );
        stream.subscribe({
            next: result => {
                setIsLoading(false);
                history.push(userDashboardPaths.requestAccessSuccess);
            },
            error: error => {
                setIsLoading(false);
                enqueueSnackbar(strings['ERROR/general'], {
                    variant: 'error',
                });
            }
        });
    }, [title, salutation, firstName, lastName, code, enqueueSnackbar, history, setIsLoading, strings, hasRequiredCustomerData]);

    const isCodeValid = useCallback(codeString => {
        const reg = new RegExp('^[0-9]{9}$');
        return reg.test(codeString);
    }, [])

    const handleCodeChange = useCallback(e => {
        setCode(e.value);
        setCodeValidity(isCodeValid(e.value))
    }, [isCodeValid])

    if (!customer) {
        return <></>;
    }

    return (
        <div className="access-request-container">
            <div className="page-header overlay-header">
                <div className="header-placeholder"></div>
                {strings["REQUEST_BOX_ACCESS_VIEW_TITLE"]}
                <Button onClick={() => history.push(userDashboardPaths.boxesOverview)}><CloseRoundedIcon/></Button>
            </div>

            <div className="overlay">
                {
                    !hasRequiredCustomerData ?
                        <p className="description-unregistered">
                            {strings["REQUEST_BOX_ACCESS_VIEW_DESCRIPTION"]}
                        </p>
                        :
                        <p className="description-registered">
                            {strings["REQUEST_BOX_ACCESS_VIEW_DESCRIPTION_REGISTERED"]}
                        </p>
                }

                {
                    !hasRequiredCustomerData &&
                    <div className="form-unregistered">
                        <FormControl variant="outlined" className="input-form salutation">
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    //getContentAnchorEl: null
                                }}
                                className={salutation ? "underlined-input" : "disabled-input"}
                                placeholder=""
                                aria-describedby="salutation-select-label"
                                id="salutation-select"
                                value={salutation}
                                displayEmpty
                                defaultValue=""
                                onChange={(e) => setSalutation(e.target.value)}
                                IconComponent={ExpandMoreRounded}
                            >
                                <MenuItem value="" disabled>{strings["GENERAL_SALUTATION"]}</MenuItem>
                                {
                                    getSalutations().map((item, index) => {
                                        return <MenuItem key={index + 1} value={item.id}>{item.label}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="input-form title">
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                }}
                                className={title ? "underlined-input" : "disabled-input"}
                                placeholder=""
                                id="title-select"
                                aria-describedby="title-select-helper-label"
                                value={title}
                                displayEmpty
                                defaultValue=""
                                onChange={(e) => setTitle(e.target.value)}
                                labelId="title-select-helper-label"
                                IconComponent={ExpandMoreRounded}
                            >
                                <MenuItem key={0} value="">{strings["GENERAL_TITLE"]}</MenuItem>
                                <MenuItem key={1} value="DR">{strings["GENERAL_TITLE_DR"]}</MenuItem>
                                <MenuItem key={2} value="PROF">{strings["GENERAL_TITLE_PROF"]}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="input-form first-name">
                            <OutlinedInput
                                className={firstName ? "underlined-input" : "disabled-input"}
                                id="first-name-input"
                                placeholder={strings["GENERAL_FIRSTNAME"]}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                aria-describedby="first-name-input-label"
                                labelWidth={0}
                                endAdornment={firstName ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                            />
                        </FormControl>

                        <FormControl variant="outlined" className="input-form last-name">
                            <OutlinedInput
                                className={lastName ? "underlined-input" : "disabled-input"}
                                id="last-name-input"
                                placeholder={strings["GENERAL_LASTNAME"]}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                aria-describedby="last-name-input-label"
                                labelWidth={0}
                                endAdornment={lastName ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                            />
                        </FormControl>
                    </div>
                }

                <FormControl variant="outlined" className="code-input">
                    <NumberFormat className='input-code-format' value={code} decimalSeparator="," thousandSeparator=" " displayType="input" maxLength={11}
                                  isNumericString={true}
                                  placeholder={strings['REQUEST_BOX_ACCESS_VIEW_PLACEHOLDER_CODE']} onValueChange={handleCodeChange}/>
                </FormControl>

                <Button variant='contained'
                        disabled={!codeValidity || (
                            !hasRequiredCustomerData &&
                            (!lastName || !firstName || !salutation)
                        )}
                        className="blue-button"
                        onClick={handleNextClick}
                >
                    {strings["GENERAL_NEXT"]}
                </Button>
            </div>
        </div>
    )
}
