import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import FunctionsApiService from "services/FunctionsApiService";

import {
    isValidEmail,
    isValidFirstName,
    isValidLastName,
    isValidPhonenumber,
} from "concerns/checkout/services/InputValidationService";

import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";

import * as fonts from "styles/fontStyles";
import { colors } from 'styles/colors';
import * as S from 'styles/sharedStyles';
import {useAppState} from "contexts/AppStateContext";
import {IconButton} from "@mui/material";
import * as Icon from "react-feather";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {reauthenticateUser, updateEmailOnUser} from "services/AuthenticationService";
import InfoBox from "components/InfoBox";

export default function EditContactDetailsView() {

    const { customer, authUser } = useAppState();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState();
    const [password, setPassword] = useState('');

    useEffect(()=>{
        if(!customer) return;
        if(customer.company === null) customer.company = ''; // Make sure that null is not passed to TextInput as value
        setFormData(customer)
    }, [customer])

    const updateFormData = (data) => {
        setFormData(prevState => {
            return {...prevState, ...data}
        });
    }

    const onClose =  useCallback(()=> {
        history.goBack()
    }, [history])

    const onUpdateCustomerData = useCallback(async () => {

        try {
            setIsLoading(true);

            if(formData.email !== customer.email) {
                await reauthenticateUser(authUser.email, password);
                await updateEmailOnUser(formData.email);
            }

            await FunctionsApiService.customersUpdate(formData);
            setIsLoading(false);
            onClose();
            enqueueSnackbar(t('saveChangesSuccess'),{ variant: 'success' });

        } catch (error) {
            if(error.code === "auth/wrong-password"){
                enqueueSnackbar(t('errors.wrongPassword'),{ variant: 'error' });
            } else {
                enqueueSnackbar(t('error'),{ variant: 'error' });
            }
            setIsLoading(false);
        }

    }, [formData, setIsLoading, onClose, enqueueSnackbar, t, customer?.email, authUser?.email, password]);

    const isFormValid =
        formData &&
        !!formData.salutation &&
        isValidFirstName(formData.firstName) &&
        isValidLastName(formData.lastName);


    if(!formData) return null;

    return (
        <S.View>

            <Header>
                <fonts.Title>
                    {t('editContactDetails.title')}
                </fonts.Title>

                <CloseButtonContainer>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        <Icon.X color={colors.primary.primary_300} size={24}/>
                    </IconButton>
                </CloseButtonContainer>
            </Header>

            <FormSection marginTop={'16px'}>
                <FormRow>
                    <Select label={t('enterContactDetails.salutation')}
                            disabled={true}
                            error={false}
                            options={FunctionsApiService.getSalutations().map( el => {return {value: el.value, label:t(el.value)}})}
                            onChange={(e)=>updateFormData({salutation:e.target.value})}
                            value={formData.salutation}
                            isValid={formData.salutation.length > 0}
                    />
                    <HorizontalSeparator/>
                    <Select label={t('enterContactDetails.professionalTitle')}
                            disabled={false}
                            error={false}
                            options={FunctionsApiService.getProfessionalTitles().map( el => {return {value: el.value, label:t(el.value)}})}
                            onChange={(e)=>updateFormData({title:e.target.value})}
                            value={formData.title}
                            isValid={formData.title.length > 0}
                    />
                </FormRow>
            </FormSection>
            <FormSection marginTop={'16px'}>
                <FormRow>
                    <TextInput label={t('enterContactDetails.firstName')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({firstName:e.target.value})}
                               value={formData.firstName}
                               isValid={isValidFirstName(formData.firstName)}
                               name={"given-name"}
                    />
                    <HorizontalSeparator/>
                    <TextInput label={t('enterContactDetails.lastName')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({lastName:e.target.value})}
                               value={formData.lastName}
                               isValid={isValidLastName(formData.lastName)}
                               name={"family-name"}
                    />
                </FormRow>

                <FormRow marginTop={'16px'}>
                    <TextInput label={t('enterContactDetails.company')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({company:e.target.value})}
                               value={formData.company}
                               isValid={formData.company?.length > 0}
                               name={"company"}
                    />
                </FormRow>

                <FormRow marginTop={'16px'}>
                    <TextInput label={t('enterContactDetails.phone.placeholder')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({mobilePhone:e.target.value})}
                               value={formData.mobilePhone}
                               isValid={isValidPhonenumber(formData.mobilePhone)}
                               name={"phone"}
                    />
                </FormRow>

                <FormRow marginTop={'16px'}>
                    <TextInput label={t('enterContactDetails.email.placeholder')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({email:e.target.value})}
                               value={formData.email}
                               isValid={isValidEmail(formData.email)}
                               name={"email"}
                    />
                </FormRow>

                {formData.email !== customer.email &&
                    <InfoBox
                        message={t('editContactDetails.changeEmail.info')}
                    >
                        <TextInput label={t('register.password.label')}
                                   disabled={false}
                                   error={false}
                                   onChange={(e)=>setPassword(e.target.value)}
                                   value={password}
                                   isValid={password?.length >= 6}
                                   name={"email"}
                                   type={'password'}
                        />
                    </InfoBox>
                }
            </FormSection>

            {!isLoading
                ? <ButtonPrimary isDisabled={!isFormValid}
                                 label={t('save')}
                                 marginTop={32}
                                 onClick={onUpdateCustomerData}
                />
                : <LoadingIndicator marginTop={24}/>
            }

        </S.View>
    );
}

const Header = styled.div`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
`;

const FormSection = styled.div`
    margin-top: ${props => props.marginTop || null};
`;

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HorizontalSeparator = styled.div`
    min-width: 12px;
    flex: 1;
`;

const CloseButtonContainer = styled.div`
    display: block;
    position:absolute;
    right:16px;
    top:16px;
`;
