import {PartnerRequestStatus} from "constants/enums_partner_request";

export default function useBoxPartnerRequests(requests) {

    requests?.sort( (a, b) => {
        if (a.createdAt.seconds < b.createdAt.seconds) return -1
        if (a.createdAt.seconds > b.createdAt.seconds) return 1
        return 0;
    })

    const getLastPartnerRequest = () => {
        if(requests) return requests[0];
        return null;
    }

    const hasOpenPartnerRequest = () => {
        if(!requests) return false;

        let result = false;
        requests.forEach((el) => {
            if(el.status === PartnerRequestStatus.REQUESTED) result = true;
        })
        return result;
    }

    const hasConfirmedPartnerRequest = () => {
        if(!requests) return false;

        let result = false;
        requests.forEach((el) => {
            if(el.status === PartnerRequestStatus.CONFIRMED) result = true;
        })
        return result;
    }

    return {getLastPartnerRequest, hasOpenPartnerRequest, hasConfirmedPartnerRequest}
}
