import styled from "styled-components";
//import {breakpoints} from "./breakpoints";
import {Body} from "./fontStyles";
import {colors} from "./colors";

export const View = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    padding-bottom: 40px;
`;

export const Description = styled(Body)`
    padding: 32px 0 16px 0;
    text-align: center;
    color: ${colors.primary.primary_100};
    display: block;
`;
