import {useCallback, useEffect, useState} from "react";
import {cardData} from "../constants/card-data";
import {useAppState} from "contexts/AppStateContext";
import {FirestoreApiService} from "services/FirestoreApiService";
import {InsuranceStatus} from "constants/enums_insurance";
import {PartnerRequestStatus} from "constants/enums_partner_request";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import FunctionsApiService from "services/FunctionsApiService";

export default function useBox(boxId, loadVisits) {
    const {authUser, locations, customerBoxes, customerPartnerBoxes } = useAppState();
    const [location, setLocation] = useState([]);
    const [sizeCardData, setSizeCardData] = useState([]);
    const [box, setBox] = useState(null);
    const [visits, setVisits] = useState();
    const [insurance, setInsurance] = useState();
    const [isSharedAccess, setIsSharedAccess] = useState(false); // Whether this is a shared box from someone else.
    const [latestPartnerRequest, setLatestPartnerRequest] = useState(null);
    const [latestPartner, setLatestPartner] = useState(null);

    const fetchVisits = useCallback(async (userId, boxId) => {
        try {
            const visits = await FirestoreApiService.getVisitsForBox(userId, boxId, 1000);
            return visits;
        } catch (e) {
            console.error(e);
        }
    }, []);

    const subscribeToBoxInsuranceUpdates = useCallback(async (userId, boxId) => {
        const successCallback = (insurance) => {
            setInsurance(insurance || {amountInsured: 30000, status: InsuranceStatus.COMPLETED});

            if(loadVisits) {
                fetchVisits(authUser.uid, boxId, 1000).then( visits => {
                    setVisits(visits);
                }).catch(error => {

                });
            }
        };

        const errorCallback = (error) => {
            console.log("Error: ", error);
        };

        try {
            const unsubscribe = FirestoreApiService.getLatestInsuranceForBoxUpdates(userId, boxId, successCallback, errorCallback);
            return unsubscribe;
        } catch (e) {
            console.error(e);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBox = useCallback(() => {
        const customerBox = customerBoxes?.find( b => b.id === boxId);
        const sharedBox = customerPartnerBoxes.find( b => b.id === boxId);

        return customerBox || sharedBox;
    }, [boxId, customerBoxes, customerPartnerBoxes]);

    useEffect(() => {
        if(!customerBoxes || !customerPartnerBoxes || !locations) return;

        let b = getBox();
        if(!b) return;

        setBox(b);
        setIsSharedAccess(!b?.contract.isOwner);

        if(b){
            const l = locations.find(location => location.token === b.location);
            const s = b.size ? cardData[b.size.toLowerCase()] : {};

            setLocation(l);
            setSizeCardData(s);
        }

        const handleAsyncProcess = async () => {
            let unsubscribe = () => {};
            if (b.contract.isOwner) {
                unsubscribe = await subscribeToBoxInsuranceUpdates(authUser.uid, boxId);

                // Set the latest partner
                if (b.partners.length > 0) {
                    const partners = b.partners.filter((el) => el.status !== ContractPartnerStatus.CANCELLED && el.status !== ContractPartnerStatus.REMOVED );
                    partners.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);

                    if(partners?.length > 0) {
                        const partnerData = await FunctionsApiService.getPartnerDetails(partners[0].refs.partnerId, partners[0].tan);
                        setLatestPartner(partnerData.data);
                    }
                }

                // Set the latest partner request
                if (b.requests.length > 0) {
                    const partnerRequests = b.requests.filter((el) => el.status === PartnerRequestStatus.REQUESTED || PartnerRequestStatus.CONFIRMED);
                    partnerRequests.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);

                    if(partnerRequests?.length > 0) {

                        const partnerData = await FunctionsApiService.getPartnerDetails(partnerRequests[0].partnerId, partnerRequests[0].tan);
                        let partnerRequestData = {
                            partnerData: partnerData.data,
                            ...partnerRequests[0]
                        }

                        setLatestPartnerRequest(partnerRequestData);
                    }
                }
            }
            return () => unsubscribe();
        };

        handleAsyncProcess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxId, customerBoxes, customerPartnerBoxes, locations]);

    return {
        location,
        box,
        cardData: sizeCardData,
        latestPartnerRequest,
        latestPartner,
        isSharedAccess,
        visits,
        insurance,
    };
}
