import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {loginUser} from 'services/AuthenticationService';
import {useHistory} from "react-router-dom";
import {userDashboardPaths} from "constants/paths";
import { useAppState } from "contexts/AppStateContext";
import {useSnackbar} from "notistack";
import {isValidEmail} from "../../services/InputValidationService";

import TextInput from "../../components/TextInput";
import PrimaryButton from "../../components/PrimaryButton";
import TextButton from "../../components/TextButton";
import LoadingIndicator from "../../components/LoadingIndicator";

import {breakpoints, colors} from "@asservato/shared.styles";
import {Body} from "../../styles/fontStyles";

export default function LoginView() {
    const {strings} = useAppState();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const onConfirm = useCallback(async () => {
        setIsLoading(true)

        try {
            await loginUser(email,password);
            setIsLoading(false)
            history.replace(userDashboardPaths.boxesOverview);
        } catch(error) {
            setIsLoading(false)

            let message = strings["ERROR_auth/default"];
            if (error && error.message) {
                message = strings["ERROR_" + error.code];
            }
            enqueueSnackbar(message, {variant: 'error'});
        }

    }, [email, password, enqueueSnackbar, history, strings]);

    return (
        <View>
            <Container>
                <LogoContainer>
                    <Logo src={'/assets/logo_asservato.png'}/>
                </LogoContainer>

                <Description>
                    {strings['LOGIN_VIEW_DESCRIPTION']}
                </Description>

                <TextInput name={"email"}
                           label={strings['LOGIN_VIEW_PLACEHOLDER_EMAIL']}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           isValid={isValidEmail(email)}
                />

                <TextInput name={"password"}
                           label={strings['LOGIN_VIEW_PLACEHOLDER_PASSWORD']}
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           isValid={password.length >= 6}
                           margin={'16px 0 0 0'}
                           type={'password'}
                />

                {!isLoading
                    ?   <PrimaryButton label={strings['START_VIEW_BTN_LOGIN']} onClick={onConfirm} isDisabled={!(isValidEmail(email) && password.length >= 6)} marginTop={32}/>
                    :   <LoadingIndicator marginTop={32} padding={'0 0 16px 0'}/>
                }

                <TextButton label={"Passwort vergessen"} marginTop={16} onClick={() => history.push(userDashboardPaths.forgotPassword)} isDisabled={isLoading}/>
            </Container>

            <Footer>
                {strings['LOGIN_VIEW_BTN_TO_REGISTRATION']} <a href={userDashboardPaths.register}>{strings['LOGIN_VIEW_BTN_TO_REGISTRATION_LINK']}</a>{'.'}
            </Footer>

            <LegalLinks>
                <a href='https://legal.asservato.de/datenschutz' target='_blank' rel="noreferrer">{strings['LOGIN_VIEW_LINK_TO_PRIVACY_POLICY']}</a><br/>
                <a href='https://www.asservato.de/impressum' target='_blank' rel="noreferrer">{strings['LOGIN_VIEW_LINK_TO_IMPRINT']}</a>
            </LegalLinks>
        </View>)
}

const View = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(45deg, #091541, #233460);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    @media ${breakpoints.mobileL} {
        justify-content: center;
    }
`;

const Container = styled.div`
    background-color: white;
    width: 100%;
    max-width: 540px;
    padding: 24px;
    box-sizing: border-box;

    @media ${breakpoints.mobileL} {
        border-radius: 5px;
        padding: 72px;
    }
`;

const LogoContainer = styled.div`
    text-align: center;
`;

const Logo = styled.img`
    max-height: 40px;
`;

const Description = styled(Body)`
    padding: 32px 0;
    text-align: left;
    color: ${colors.primary.primary_100};
    display: block;
`;

const Footer = styled(Body)`
    padding: 32px 24px;
    text-align: left;
    color: white;
    display: block;
    
    a {
        color: white !important;
    }
`;

const LegalLinks = styled(Body)`
    padding: 0 24px 32px 24px;
    text-align: center;
    color: white;
    display: block;
    
    a {
        color: white !important;
        text-decoration: none;
        opacity: 55%;
        line-height: 1.7em;
    }

    a:hover {
        opacity: 80%;
    }
`;
