import {useAppState} from "contexts/AppStateContext";
import {formatString, getDateStringFromTimestamp} from "utils/mobile-app-helpers";
import React from "react";

export default function NotificationForCancellation({box}) {
    const {strings} = useAppState();

    let text;
    if (box.cancelling?.endsAt) {
        text = formatString(strings.BOX_DETAIL_VIEW_BOX_CANCELLED, {
            boxNr: box.gunnebo.id,
            date: getDateStringFromTimestamp(box.cancelling.endsAt)
        });
    } else {
        text = formatString(strings.BOX_DETAIL_VIEW_BOX_CANCELLED_NO_DATE, {
            boxNr: box.gunnebo.id
        });
    }
    return <div className="notification warning">
        {text}
    </div>
}
