import clsx from "clsx";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

export default function StaticNotification({text, isPostIdent, isWarning, onPress}) {
    return (
        <div className={clsx('notification', {'is-post-ident': isPostIdent, 'is-warning': isWarning, 'clickable': !!onPress})} onClick={onPress}>
            {text}
            {
                onPress &&
                <ArrowForwardIosIcon className="chevron-right notification-navigate"/>
            }
        </div>
    )
}
