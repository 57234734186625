import React from "react";
import {Route} from "react-router-dom";
import PlanMigrationView from '../concerns/plan-migration/PlanMigrationView';

export const planMigrationPaths = {
    planMigrationRoot: '/plan-migration',
}

export const isPlanMigrationPath = (path) => {
    let included = false;
    if(path === planMigrationPaths.planMigrationRoot) included = true;
    for (const arr of Object.entries(planMigrationPaths)) {
        if(path === planMigrationPaths.planMigrationRoot + arr[1]) included = true;
    }
    return included;
}

export const PlanMigrationRoutes = [
    <Route
        path={planMigrationPaths.planMigrationRoot}
        render={() => {return  <PlanMigrationView />}}
        key={planMigrationPaths.planMigrationRoot}
    />,

];
