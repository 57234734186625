import {BoxRoutes} from "concerns/boxes/BoxRoutes";
import {Route} from "react-router-dom";
import UserDataView from "concerns/user-data/UserDataView";
import InvoicesListView from "concerns/invoices/InvoicesListView";
import ChangePinView from "concerns/change-pin/ChangePinView";
import RequestTanView from "concerns/request-tan/RequestTanView";
import AddressView from "concerns/user-data/AddressView";
import EmailView from "concerns/user-data/EmailView";
import TelephoneView from "concerns/user-data/TelephoneView";
import AddCardView from "concerns/user-data/AddCardView";
import PasswordChangeView from "concerns/user-data/PasswordChangeView";
import ReportLossView from "concerns/report-loss/ReportLossView";
import CustomerServiceView from "concerns/customer-service/CustomerServiceView";
import React from "react";
import {overlayPaths} from "navigation/OverlayRoutes";

export const dashboardPaths = {
    boxesOverview: '/boxes-overview',
    boxDetails: '/box-details',
    singleBoxRequestStatus: '/single-box-request-status',
    accessRequestConfirmation: '/access-request-confirmation',
    singleBoxRequestStatusCheckout: '/single-box-request-status-checkout',
    boxSettings: '/box-settings',
    boxInsurance: '/box-insurance',
    boxContract: '/box-contract',
    boxHistory: '/box-history',
    selectBoxLocations: '/select-box-locations',
    boxAccessConfiguration: '/box-access-configuration',
    confirmBookingAccess: '/confirm-booking-access',
    finishGrantingAccessProcess: '/finish-granting-access-process',
    userData: '/user-data',
    invoices: '/invoices',
    requestTan: '/request-tan',
    changePin: '/change-pin',
    reportLoss: '/report-loss',
    customerService: '/customer-service',
    planYourVisit: '/plan-visit',
    configureSharedAccess: '/configure-shared-access',
    requestAccessForm: '/access-request-form',
    requestAccessSuccess: '/access-request-success',
    mainCustomerInfo: '/main-customer-information',
    postidentConfirmation: '/postident-confirmation',
    postidentSelection: '/postident-selection',
    userDataAddress: '/user-data/address',
    userDataEmail: '/user-data/email',
    userDataTelephone: '/user-data/telefon',
    userDataCard: '/user-data/card',
    userDataPassword: '/user-data/password',
}

export const isDashboardPath = (path) => {
    let included = false;

    // only test on the first part of the path
    if(path !== "/") {
        path = path.split("/")[1];
        path = "/" + path;
    }

    for (const arr of Object.entries(dashboardPaths)) {
        if(path === arr[1]) included = true;
    }

    return included;
}

export const DashboardRoutes =  [
        ...BoxRoutes,
        <Route
            path={[dashboardPaths.userData, overlayPaths.editContactDetails, overlayPaths.editAddress]}
            render={() => <UserDataView />}
            key={dashboardPaths.userData}
            exact
        />,

        <Route
            path={dashboardPaths.invoices}
            render={() => <InvoicesListView />}
            key={dashboardPaths.invoices}
        />,
        <Route
            path={dashboardPaths.changePin}
            render={() => <ChangePinView />}
            key={dashboardPaths.changePin}
        />,
        <Route
            path={dashboardPaths.requestTan}
            render={() => <RequestTanView />}
            key={dashboardPaths.requestTan}
        />,
        <Route
            path={dashboardPaths.userDataAddress}
            render={() => <AddressView />}
            key={dashboardPaths.userDataAddress}
        />,
        <Route
            path={dashboardPaths.userDataEmail}
            render={() => <EmailView />}
            key={dashboardPaths.userDataEmail}
        />,
        <Route
            path={dashboardPaths.userDataTelephone}
            render={() => <TelephoneView />}
            key={dashboardPaths.userDataTelephone}
        />,
        <Route
            path={dashboardPaths.userDataCard}
            render={() => <AddCardView />}
            key={dashboardPaths.userDataCard}
        />,
        <Route
            path={dashboardPaths.userDataPassword}
            render={() => <PasswordChangeView />}
            key={dashboardPaths.userDataPassword}
        />,
        <Route
            path={dashboardPaths.reportLoss}
            render={() => <ReportLossView />}
            key={dashboardPaths.reportLoss}
        />,
        <Route
            path={dashboardPaths.customerService}
            render={() => <CustomerServiceView />}
            key={dashboardPaths.customerService}
        />,
    ]
