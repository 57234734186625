import React from "react";
import { useAppState } from "contexts/AppStateContext";
import styled from 'styled-components';

export default function LoadingOverlay() {
    const {isLoading} = useAppState();

    return <>
        {
            isLoading &&
            <Container>
                <Indicator>
                    <img src={'/assets/puff.svg'} alt={''}/>
                </Indicator>
            </Container>
        }
    </>;
}

const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0, 0.5);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
