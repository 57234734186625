import {useHistory, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import styled from 'styled-components';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, FormGroup, Switch} from "@mui/material";
import {getFormattedPrice} from "utils/mobile-app-helpers";
import {userDashboardPaths} from "constants/paths";
import { useAppState } from "contexts/AppStateContext";
import {formatString} from "utils/mobile-app-helpers";
import useBox from "hooks/useBox";
import usePrices from "hooks/usePrices";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import {useSnackbar} from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoBox from "components/InfoBox";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import LoadingIndicator from "components/LoadingIndicator";
import FunctionsApiService from "services/FunctionsApiService";
import LoadingView from "components/LoadingView";

export default function ConfigureSharedAccessView() {
    const {strings, subscriptionPrices, refreshSubscriptionPrices} = useAppState()
    const history = useHistory();
    const {boxId} = useParams();
    const {box} = useBox(boxId);
    const [isToggleActive, setIsToggleActive] = useState(false);
    const [isToggleInteractionDisabled, setIsToggleInteractionDisabled] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [shareUser, setShareUser] = useState(null);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [boxInfo, setBoxInfo] = useState({
        boxNr: '',
        sharingPartnerInfo: [],
        shareUserId: null,
    });
    const [subscriptionInterval, setSubscriptionInterval] = useState();
    const [partnerPlan, setPartnerPlan] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const {partnerPrices} = usePrices(box?.location);

    useEffect( () => {

        if(!subscriptionPrices || !partnerPrices) return;

        if(!subscriptionPrices[boxId]) {
            refreshSubscriptionPrices();
        } else {
            const interval = subscriptionPrices[boxId].interval.unit;
            const plan = partnerPrices[0]?.plans?.filter( el => el.interval.unit === interval)[0];
            setSubscriptionInterval(interval);
            setPartnerPlan(plan);
        }

    }, [subscriptionPrices, partnerPrices, refreshSubscriptionPrices, boxId])

    useEffect(() => {
        if (!box) return;

        const sharingPartnerInfo = getSharingPartnerInfo(box.partners)

        setBoxInfo({
            boxNr: '<b>Fach Nr. ' + box.gunnebo.id + ' ' + (box.name ? '(' + box.name + ')' : '') + '</b>',
            sharingPartnerInfo: sharingPartnerInfo,
            shareUserId: sharingPartnerInfo?.refs.partnerId,
            code: sharingPartnerInfo?.tan,
        })
    }, [box, box?.partners]);

    useEffect( () => {

        async function fetchData() {
            if (!boxInfo.shareUserId || shareUser) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);

            try {
                const res = await FunctionsApiService.getPartnerDetails(boxInfo.shareUserId, boxInfo.code)
                setShareUser(res.data);
                setIsLoading(false);
            } catch(error) {
                setIsLoading(false);
                enqueueSnackbar(strings['ERROR/general'], {
                    variant: 'error',
                });
            }
        }

        fetchData();

    }, [boxInfo, enqueueSnackbar, setIsLoading, strings, shareUser]);

    useEffect(() => {
        setIsToggleInteractionDisabled(!((boxInfo.sharingPartnerInfo && boxInfo.sharingPartnerInfo?.status === ContractPartnerStatus.ACCESSIBLE) || boxInfo.sharingPartnerInfo?.status === ContractPartnerStatus.LOCKED));
        setIsToggleActive(boxInfo.sharingPartnerInfo?.status === ContractPartnerStatus.ACCESSIBLE);
    }, [boxInfo.sharingPartnerInfo]);

    const handleToggleChange = useCallback(async e => {
        setIsToggleActive(e.target.checked);
        setIsToggleInteractionDisabled(true);

        const payload = {
            boxId: box.id,
            partnerId: boxInfo.shareUserId,
        };

        const request = !e.target.checked ? FunctionsApiService.blockPartnerAccess(payload) : FunctionsApiService.unblockPartnerAccess(payload);

        try {
            await request;

        } catch (error) {
            console.log("Error: ", error);
            setIsToggleActive(!e.target.checked);
            enqueueSnackbar(strings['ERROR/general'], {variant: 'error'});
        }

    }, [box, boxInfo.shareUserId, enqueueSnackbar, strings]);

    const handleCancelClick = useCallback(() => {
        setCancelDialogIsOpen(true);
    }, []);

    const handleCancelDialogClose = useCallback(() => {
        setCancelDialogIsOpen(false);
    }, []);

    const handleCancelDialogConfirm = useCallback(async () => {
        setIsLoading(true);

        try {
            await FunctionsApiService.cancelPartnerAccess({boxId: box.id, partnerId: boxInfo.shareUserId});

            setCancelDialogIsOpen(false);
            setIsCancelled(true);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            enqueueSnackbar(strings['ERROR/general'], {variant: 'error'});
        }
    }, [box, boxInfo.shareUserId, enqueueSnackbar, setIsLoading, strings]);

    const img = !boxInfo.shareUserId ? 'illu_user_add' : 'illu_users';

    return (
        <div className="configure-shared-access-container">
            <div className="page-header overlay-header">
                <div className="header-placeholder"></div>
                {strings["SHARED_ACCESS_SETTINGS_TITLE"]}
                <Button onClick={() => history.push(userDashboardPaths.boxDetails + '/' + box.id)}><CloseRoundedIcon/></Button>
            </div>

            {isLoading
                ?   <LoadingView absolutePositioning={true}/>
                :   <div className="overlay">
                        <div className="container-blue">
                            <img className="add-user-img"
                                 srcSet={`${process.env.PUBLIC_URL}/assets/user-icons/${img}.png, ${process.env.PUBLIC_URL}/assets/user-icons/${img}@2x.png 2x`}
                                 src={`${process.env.PUBLIC_URL}/assets/user-icons/${img}.png`} alt=""/>
                        </div>

                        {box.cancelling
                            ?   <InfoBox message={strings["GENERAL_NOT_AVAILABLE_AFTER_CANCELLATION"]}/>
                            :   <div className="grant-access-container">
                                {
                                    !shareUser
                                        ?   <>
                                            <p className="_title semi-bold">
                                                {strings["SHARED_ACCESS_SETTINGS_INSTRUCTIONS_HEADER"]}
                                            </p>

                                            <p className="_content" dangerouslySetInnerHTML={{
                                                __html: formatString(strings["SHARED_ACCESS_SETTINGS_INSTRUCTIONS_DESCRIPTION"], {boxNr: boxInfo.boxNr})
                                            }}/>

                                            {partnerPlan
                                                ?   <>
                                                    <p className="_content">
                                                        {formatString(strings["SHARED_ACCESS_SETTINGS_INSTRUCTIONS_COST"], {price:getFormattedPrice(partnerPlan?.price.unit) + ' ' + strings['CHECKOUT_SUMMARY_VIEW_PER_'+subscriptionInterval]})}
                                                    </p>

                                                    <ButtonContainer>
                                                        <Button
                                                            variant="contained"
                                                            size="large"
                                                            color="primary"
                                                            className="blue-button"
                                                            onClick={() => history.push(userDashboardPaths.boxAccessConfiguration + '/' + boxId)}
                                                        >
                                                            {strings["SHARED_ACCESS_SETTINGS_BTN_CONFIGURE"]}
                                                        </Button>
                                                    </ButtonContainer>
                                                </>
                                                :  <LoadingIndicator size={32} marginTop={48}/>
                                            }
                                        </>
                                        : !isCancelled
                                            ? <>
                                                <p className="_title semi-bold">
                                                    {strings["SHARED_ACCESS_SETTINGS_HEADER"]}
                                                </p>
                                                <p className="_content">
                                                    {strings.SHARED_ACCESS_SETTINGS_DESCRIPTION}
                                                </p>

                                                <div className="partner-container">
                                                    <div className="partner-info">
                                                        <p className="name semi-bold">{getNameString(shareUser, strings)}</p>
                                                        <p className="email">{shareUser.email}</p>
                                                    </div>

                                                    <CheckCircleIcon className={isToggleActive ? "checked-step" : "hide"}/>
                                                </div>

                                                <FormControl component="fieldset" className="switch-button">
                                                    <FormGroup aria-label="position" row>
                                                        <FormControlLabel
                                                            value="start"
                                                            control={<Switch checked={isToggleActive} onChange={handleToggleChange} name="checked" disabled={isToggleInteractionDisabled}/>}
                                                            label={isToggleActive ? strings["SHARED_ACCESS_SETTINGS_LABEL_ACCESS_GRANTED"] : strings["SHARED_ACCESS_SETTINGS_LABEL_ACCESS_BLOCKED"]}
                                                            labelPlacement="start"
                                                        />
                                                    </FormGroup>
                                                </FormControl>

                                                <div className="alert-message">
                                                    <ErrorOutlinedIcon/>
                                                    <p>{formatString(strings["SHARED_ACCESS_SETTINGS_HINTBOX"], {name: getNameString(shareUser, strings)})}</p>
                                                </div>

                                                {
                                                    !isToggleActive &&
                                                    <ButtonContainer>
                                                        <Button
                                                            variant="contained"
                                                            size="large"
                                                            color="primary"
                                                            className="blue-button"
                                                            onClick={handleCancelClick}
                                                            disabled={isToggleInteractionDisabled}
                                                        >
                                                            {strings["SHARED_ACCESS_SETTINGS_BTN_CANCEL_ACCESS"]}
                                                        </Button>
                                                    </ButtonContainer>
                                                }

                                                <Dialog
                                                    open={cancelDialogIsOpen}
                                                    onClose={handleCancelDialogClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className="dialog-content">
                                                        <div className="dialog-title">{strings["SHARED_ACCESS_SETTINGS_CONFIRM_CANCELLATION_TITLE"]}</div>

                                                        <DialogContentText id="alert-dialog-description">
                                                            {formatString(strings["SHARED_ACCESS_SETTINGS_CONFIRM_CANCELLATION_DESCRIPTION"], {name: getNameString(shareUser, strings)})}
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleCancelDialogClose} className="default-button">{strings["GENERAL_CANCEL"]}</Button>
                                                        <Button onClick={handleCancelDialogConfirm} className="green-button">{strings["SHARED_ACCESS_SETTINGS_CONFIRM_CANCELLATION_BTN"]}</Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                            : <>
                                                <p className="_title semi-bold">
                                                    {strings["SHARED_ACCESS_SETTINGS_CANCELLATION_SUCCESSFUL"]}
                                                </p>
                                                <p className="_content">
                                                    {formatString(strings.SHARED_ACCESS_SETTINGS_CANCELLATION_DESCRIPTION, {name: getNameString(shareUser, strings)})}
                                                </p>
                                            </>

                                }
                            </div>
                        }
                    </div>
            }
        </div>
    )
}

function getSharingPartnerInfo(partners) {
    let sharingPartners = [];
    if (partners?.length > 0) {
        partners.forEach((el) => {
            if (el.status !== ContractPartnerStatus.CANCELLED && el.status !== ContractPartnerStatus.REMOVED) {
                sharingPartners.push(el);
            }
        });
    }

    return sharingPartners?.[sharingPartners.length-1];
}

function getNameString(user, strings) {
    return (user.title ? strings['GENERAL_TITLE_' + user.title] + ' ' : '') + user.firstName + ' ' + user.lastName;
}


const ButtonContainer = styled.div`
  text-align: center;
`;
