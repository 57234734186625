import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import strings_de from '../translations/de.json';
import strings_en from '../translations/en.json';
import {FirestoreApiService} from "services/FirestoreApiService";
import useCustomer from "hooks/useCustomer";
import useCustomerGunnebo from "hooks/useCustomerGunnebo";
import useCustomerSubscription from "hooks/useCustomerSubscription";
import useCustomerBoxes from "hooks/useCustomerBoxes";
import useCustomerPartnerBoxes from "hooks/useCustomerPartnerBoxes";
import useAuthUser from "hooks/useAuthUser";
import useLocations from "hooks/useLocations";
import useSubscriptionPrices from "hooks/useSubscriptionPrices";

export const AppStateContext = React.createContext(null);

const strings = Object.assign({}, strings_de, strings_en);

export const AppStateProvider = ({children}) => {

    const [customerPostIdent, setCustomerPostIdent] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { authUser } = useAuthUser();
    const history = useHistory();

    const {locations} = useLocations();
    const {customer, customerRegistrationIsComplete, resetCustomerData} = useCustomer(authUser?.uid);
    const {customerGunnebo, resetCustomerGunnebo} = useCustomerGunnebo(authUser?.uid);
    const {customerSubscription, resetCustomerSubscription} = useCustomerSubscription(authUser?.uid);
    const {customerBoxes, resetCustomerBoxesData} = useCustomerBoxes(authUser?.uid);
    const {customerPartnerBoxes, resetCustomerPartnerBoxesData} = useCustomerPartnerBoxes(authUser?.uid);
    const {subscriptionPrices, refreshSubscriptionPrices} = useSubscriptionPrices(authUser, customerBoxes);

    /*
    const setIsLoading = (state, caller = "N/A") => {
        console.log("setIsLoading to: ", state, "from", caller);
        _setIsLoading(state);
    }*/

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const replacePlaceholderInStrings = useCallback((str, target) => {

        if(!Array.isArray(target)) target = [target];

        let startIndices = [];
        let endIndices = [];

        for(let i=0; i < str.length; i++){
            if(str[i] === '{') startIndices.push(i);
            if(str[i] === '}') endIndices.push(i);
        }

        let result = "";

        if(startIndices.length > 0){

            let slices = [];
            slices.push(str.slice(0, startIndices[0]));
            endIndices.forEach((el, i)=>{
                if(i < endIndices.length -1){
                    let slice = str.slice(endIndices[i] + 1, startIndices[i+1]);
                    slices.push(slice);
                }
            })
            slices.push(str.slice(endIndices[endIndices.length-1] + 1));

            slices.forEach((el, i)=>{
                result += el;
                if(i < target.length){
                    result += target[i];
                }
            })
        }

        return <Fragment>
            {result}
        </Fragment>
    }, []);

    const getSalutations = useCallback(() => {
        return [
            {id: 'MR', label: 'Herr'},
            {id: 'MS', label: 'Frau'},
        ];
    }, []);

    useEffect(() => {
        if(!authUser?.uid) return;

        const unsubscribeCustomerPostIdent = FirestoreApiService.getCustomerPostIdentUpdates(authUser.uid, data => {
            setCustomerPostIdent(data);
        }, error => {
            console.log("Error: ", error);
        });

        return () => {
            unsubscribeCustomerPostIdent();
        }

    }, [authUser]);

    const resetAppData = () => {
        resetCustomerData();
        resetCustomerGunnebo();
        resetCustomerSubscription();
        resetCustomerBoxesData();
        resetCustomerPartnerBoxesData();
    }

    return (
        <AppStateContext.Provider value={{
            isLoading,
            setIsLoading,
            strings: strings['de'],
            goBack,
            getSalutations,
            replacePlaceholderInStrings,
            locations,
            customer,
            customerRegistrationIsComplete,
            customerGunnebo,
            customerSubscription,
            customerPostIdent,
            customerBoxes,
            customerPartnerBoxes,
            subscriptionPrices,
            refreshSubscriptionPrices,
            resetCustomerPartnerBoxesData,
            resetAppData,
            authUser,
        }}>
            {children}
        </AppStateContext.Provider>
    )
}


export const useAppState = () => useContext(AppStateContext);
