import React from 'react';
import styled from 'styled-components';

import {LabelBoldSmall} from 'styles/fontStyles';
import {colors} from 'styles/colors';

export default function Badge({label}){
    return (
        <Container>
            <Label>{label}</Label>
        </Container>
    )
}

const Container = styled.div`
    background:${colors.tertiary.tertiary_100};
    border-radius: 2px;
    padding: 0 4px;
    margin: 0 auto;
    box-sizing: border-box;
    display: inline-block;
`;

const Label = styled(LabelBoldSmall)`
  color:#fff;
`;
