import {useEffect, useState, useCallback} from "react";
import FunctionsApiService from "services/FunctionsApiService";
import * as Sentry from "@sentry/browser";

export default function useSubscriptionPrices(authUser, customerBoxes) {

    const [subscriptionPrices, setSubscriptionPrices] = useState();

    const refreshSubscriptionPrices =  useCallback( async () => {
        if(!authUser || !customerBoxes || customerBoxes?.length === 0) {
            return;
        }

        try {

            const prices = await FunctionsApiService.fetchSubscriptionPrices();

            if(prices?.data) {

                let boxPrices = [];

                prices.data.forEach( el => boxPrices[el.id] = el);
                setSubscriptionPrices(boxPrices);
            }

        } catch (error) {
            Sentry.captureException(error);
            console.log("There was a problem, loading the subscription prices:", error);
        }
    }, [authUser, customerBoxes])

    useEffect(()=>{
        if(authUser && customerBoxes && customerBoxes?.length > 0) refreshSubscriptionPrices();
    }, [authUser, customerBoxes, refreshSubscriptionPrices]);

    return {subscriptionPrices, refreshSubscriptionPrices};
}
