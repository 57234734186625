export const CustomerStatus = {
  CREATED : "CREATED",
  REGISTERED : "REGISTERED",
  FROZEN : "FROZEN",
  DELETED : "DELETED"
};

export const CustomerAuthentication = {
  OPEN : "OPEN",
  REQUESTED : "REQUESTED",
  AUTHENTICATED : "AUTHENTICATED",
  FAILED : "FAILED"
};

export const BraintreeSubscription = {
  ACTIVE : 'Active',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  PAST_DUE: 'Past Due',
  PENDING: 'Pending'
}