import { Route } from "react-router-dom";
import { userDashboardPaths } from "constants/paths";

import BoxesOverviewView from "./concerns/boxes-overview/BoxesOverviewView";
import SingleBoxDetailsView from "./concerns/single-box-details/SingleBoxDetailsView";
import SingleBoxRequestStatusView from "./concerns/single-box-request-status/SingleBoxRequestStatusView";
import RequestAccessSuccess from "./concerns/request-shared-access/RequestAccessSuccessView";
import BoxSettingsView from "./concerns/BoxSettingsView";
import BoxInsuranceView from "./concerns/BoxInsuranceView";
import BoxContractView from "./concerns/BoxContractView";
import BoxAccessConfigurationView from "./concerns/grant-access-to-box/BoxAccessConfigurationView";
import ConfirmBookingAccessView from "./concerns/grant-access-to-box/ConfirmBookingAccessView";
import FinishGrantingAccessProcessView from "./concerns/grant-access-to-box/FinishGrantingAccessProcessView";
import PlanVisitView from "./concerns/plan-visit/PlanVisitView";
import BoxAccessHistoryView from "./concerns/BoxAccessHistoryView";
import ConfigureSharedAccessView from "./concerns/grant-access-to-box/ConfigureSharedAccessView";
import RequestAccessFormView from "./concerns/request-shared-access/RequestAccessFormView";
import RequestAccessSuccessView from "./concerns/request-shared-access/RequestAccessSuccessView";
import MainCustomerInformationView from "./concerns/main-customer-information/MainCustomerInformationView";

export const BoxRoutes = [
        <Route
            path={userDashboardPaths.boxesOverview}
            render={() => <BoxesOverviewView />}
            key={userDashboardPaths.boxesOverview}
        />,

        <Route
            path={userDashboardPaths.boxDetails + '/:boxId'}
            render={() => <SingleBoxDetailsView />}
            key={userDashboardPaths.boxDetails}
        />,

        <Route
            path={userDashboardPaths.singleBoxRequestStatus + '/:boxId'}
            render={() => <SingleBoxRequestStatusView />}
            key={userDashboardPaths.singleBoxRequestStatus}
        />,

        <Route
            path={userDashboardPaths.accessRequestConfirmation + '/:boxId'}
            render={() => <RequestAccessSuccess />}
            key={userDashboardPaths.accessRequestConfirmation}
        />,

        <Route
            path={userDashboardPaths.boxSettings + '/:boxId'}
            render={() => <BoxSettingsView />}
            key={userDashboardPaths.boxSettings}
        />,

        <Route
            path={userDashboardPaths.boxInsurance + '/:boxId'}
            render={() => <BoxInsuranceView />}
            key={userDashboardPaths.boxInsurance}
        />,

        <Route
            path={userDashboardPaths.boxContract + '/:boxId'}
            render={() => <BoxContractView />}
            key={userDashboardPaths.boxContract}
        />,

        <Route
            path={userDashboardPaths.boxAccessConfiguration + '/:boxId'}
            render={() => <BoxAccessConfigurationView />}
            key={userDashboardPaths.boxAccessConfiguration}
        />,

        <Route
            path={userDashboardPaths.confirmBookingAccess + '/:boxId'}
            render={() => <ConfirmBookingAccessView />}
            key={userDashboardPaths.confirmBookingAccess}
        />,

        <Route
            path={userDashboardPaths.finishGrantingAccessProcess + '/:boxId'}
            render={() => <FinishGrantingAccessProcessView />}
            key={userDashboardPaths.finishGrantingAccessProcess}
        />,
        /* TODO: Handle partner registration flow here
        <Route
            path={userDashboardPaths.singleBoxRequestStatusCheckout}
            render={() => <NewBoxFlow step={3} isNotCheckoutFlow={true} />}
            key={userDashboardPaths.singleBoxRequestStatusCheckout}
        />,
        */

        <Route
            path={userDashboardPaths.planYourVisit + '/:boxId'}
            render={() => <PlanVisitView />}
            key={userDashboardPaths.planYourVisit}
        />,
        <Route
            path={userDashboardPaths.boxHistory + '/:boxId'}
            render={() => <BoxAccessHistoryView />}
            key={userDashboardPaths.boxHistory}
        />,
        <Route
            path={userDashboardPaths.configureSharedAccess + '/:boxId'}
            render={() => <ConfigureSharedAccessView />}
            key={userDashboardPaths.configureSharedAccess}
        />,

        <Route
            path={userDashboardPaths.requestAccessForm}
            render={() => <RequestAccessFormView />}
            key={userDashboardPaths.requestAccessForm}
        />,

        <Route
            path={userDashboardPaths.requestAccessSuccess}
            render={() => <RequestAccessSuccessView />}
            key={userDashboardPaths.requestAccessSuccess}
        />,
        <Route
            path={userDashboardPaths.mainCustomerInfo + '/:boxId'}
            render={() => <MainCustomerInformationView />}
            key={userDashboardPaths.mainCustomerInfo}
        />,
        ]
