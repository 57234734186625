import {Button, FormControl, FormHelperText, InputAdornment, MenuItem, OutlinedInput, Select} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import DoneIcon from "@mui/icons-material/Done";
import {ExpandMoreRounded} from "@mui/icons-material";
import countries from "constants/countries";
import { useAppState } from "contexts/AppStateContext";
import {useSnackbar} from "notistack";
import {from} from "rxjs";
import FunctionsApiService from "services/FunctionsApiService";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const countriesArray = Object.keys(countries).map(country => {
    return {
        id: country,
        label: countries[country]
    };
});

export const userDataTemplate = {
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    address: {
        street: '',
        streetNo: '',
        zip: '',
        city: '',
        country: '',
    },
}

export default function AddressView() {
    const {setIsLoading, strings, goBack, customer} = useAppState();
    const [data, setData] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setIsLoading(true);

        if(!customer) return;

        setData({
            title: customer.title,
            salutation: customer.salutation,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address: {
                ...customer.address,
                appendix: null,
            },
            email: customer.email,
            mobilePhone: customer.mobilePhone,
        });
        setIsLoading(false);
    }, [setData, setIsLoading, strings, customer]);

    const handleSaveClick = useCallback(() => {
        setIsLoading(true);
        data.mobilePhone = data.mobilePhone ? data.mobilePhone : '';
        data.title = ['DR', 'PROF'].includes(data.title) ? data.title : '';
        from(FunctionsApiService.customersUpdate(data)).subscribe({
            next: () => {
                setIsLoading(false);
                enqueueSnackbar(strings["EDIT_ADDRESS_VIEW_NOTIFICATION_DATA_SAVED"], {variant: 'success'})
            },
            error: (err) => {
                setIsLoading(false);
                enqueueSnackbar(strings["ERROR/general"], {variant: 'error'})
            },
        })
    }, [data, enqueueSnackbar, setIsLoading, strings]);

    if (!data) {
        return <></>;
    }

    return (
        <div className="address-container user-form-container">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["EDIT_ADDRESS_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <FormControl variant="outlined" className="user-data-input-container salutation">
                    <FormHelperText className="input-label"
                                    id="first-name-input-label">{data.salutation ? strings["GENERAL_SALUTATION"] : ' '}</FormHelperText>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        className={data.salutation ? "underlined-input" : "disabled-input"}
                        placeholder=""
                        aria-describedby="salutation-select-label"
                        id="salutation-select"
                        value={data.salutation}
                        displayEmpty
                        onChange={(e) => setData({...data, salutation: e.target.value})}
                        IconComponent={ExpandMoreRounded}
                    >
                        <MenuItem value={null} disabled>{strings["GENERAL_SALUTATION"]}</MenuItem>
                        <MenuItem value="MR">{strings["GENERAL_SALUTATION_MR"]}</MenuItem>
                        <MenuItem value="MS">{strings["GENERAL_SALUTATION_MS"]}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container title">
                    <FormHelperText className="input-label"
                                    id="first-name-input-label">{data.title ? strings["GENERAL_TITLE"] : ' '}</FormHelperText>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        className={data.title ? "underlined-input" : "disabled-input"}
                        placeholder=""
                        id="title-select"
                        aria-describedby="title-select-helper-label"
                        value={['DR', 'PROF'].includes(data.title) ? data.title : 'Default'}
                        onChange={(e) => setData({...data, title: e.target.value !== 'Default' ? e.target.value : ''})}
                        labelId="title-select-helper-label"
                        IconComponent={ExpandMoreRounded}
                    >
                        <MenuItem value="Default">{strings["GENERAL_TITLE"]}</MenuItem>
                        <MenuItem value="DR">{strings["GENERAL_TITLE_DR"]}</MenuItem>
                        <MenuItem value="PROF">{strings["GENERAL_TITLE_PROF"]}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container first-name">
                    <FormHelperText className="input-label"
                                    id="first-name-input-label">{strings["GENERAL_FIRSTNAME"]}</FormHelperText>
                    <OutlinedInput
                        className={data.firstName ? "underlined-input" : "disabled-input"}
                        id="first-name-input"
                        placeholder={strings["GENERAL_FIRSTNAME"]}
                        value={data.firstName}
                        onChange={(e) => setData({...data, firstName: e.target.value})}
                        aria-describedby="first-name-input-label"
                        endAdornment={data.firstName ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container last-name">
                    <FormHelperText className="input-label"
                                    id="last-name-input-label">{strings["GENERAL_LASTNAME"]}</FormHelperText>
                    <OutlinedInput
                        className={data.lastName ? "underlined-input" : "disabled-input"}
                        id="last-name-input"
                        placeholder={strings["GENERAL_LASTNAME"]}
                        value={data.lastName}
                        onChange={(e) => setData({...data, lastName: e.target.value})}
                        aria-describedby="last-name-input-label"
                        endAdornment={data.lastName ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container street">
                    <FormHelperText className="input-label" id="address-input-label">{strings["GENERAL_STREET"]}</FormHelperText>
                    <OutlinedInput
                        className={data.address?.street ? "underlined-input" : "disabled-input"}
                        id="address-input"
                        placeholder={strings["GENERAL_STREET"]}
                        value={data.address?.street}
                        onChange={(e) => {
                            setData({...data, address: {...data.address, street: e.target.value}})
                        }}
                        aria-describedby="address-input-label"
                        endAdornment={data.address?.street ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container street-number">
                    <FormHelperText className="input-label" id="streetNumber-input-label">{strings["GENERAL_NR"]}</FormHelperText>
                    <OutlinedInput
                        className={data.address?.streetNo ? "underlined-input" : "disabled-input"}
                        id="streetNumber-input"
                        placeholder={strings["GENERAL_NR"]}
                        value={data.address?.streetNo}
                        onChange={(e) => {
                            setData({...data, address: {...data.address, streetNo: e.target.value}})
                        }}
                        aria-describedby="streetNumber-input-label"
                        endAdornment={data.address?.streetNo ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container postcode">
                    <FormHelperText className="input-label" id="postcode-input-label">{strings["GENERAL_ZIP"]}</FormHelperText>
                    <OutlinedInput
                        className={data.address?.zip ? "underlined-input" : "disabled-input"}
                        id="postcode-input"
                        placeholder={strings["GENERAL_ZIP"]}
                        value={data.address?.zip}
                        onChange={(e) => {
                            setData({...data, address: {...data.address, zip: e.target.value}})
                        }}
                        aria-describedby="postcode-input-label"
                        endAdornment={data.address?.zip ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container city">
                    <FormHelperText className="input-label" id="city-input-label">{strings["GENERAL_CITY"]}</FormHelperText>
                    <OutlinedInput
                        className={data.address?.city ? "underlined-input" : "disabled-input"}
                        id="city-input"
                        placeholder={strings["GENERAL_CITY"]}
                        value={data.address?.city}
                        onChange={(e) => {
                            setData({...data, address: {...data.address, city: e.target.value}})
                        }}
                        aria-describedby="city-input-label"
                        endAdornment={data.address?.city ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container">
                    <Select
                        className={data.address?.country ? "underlined-input" : "disabled-input"}
                        aria-describedby="country-select-label"
                        id="country-select"
                        value={data.address?.country || ''}
                        displayEmpty
                        defaultValue=""
                        onChange={(e) => {
                            setData({...data, address: {...data.address, country: e.target.value}})
                        }}
                        IconComponent={ExpandMoreRounded}
                    >
                        <MenuItem value="" disabled>{strings["GENERAL_COUNTRY"]}</MenuItem>
                        {
                            countriesArray.map(country =>
                                <MenuItem key={country.id} value={country.id} disabled={country.id === '--'}>
                                    {country.label}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>

                <Button variant='contained'
                        disabled={!data.salutation || !data.firstName || !data.lastName || !data.address.street || !data.address.streetNo ||
                        !data.address.zip || !data.address.city || !data.address.country}
                        className="user-data-button blue-button"
                        onClick={handleSaveClick}>{strings["GENERAL_SAVE"]}
                </Button>
            </div>
        </div>
    );
}
