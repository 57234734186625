import {useEffect, useState, useRef} from "react";
import {FirestoreApiService} from "services/FirestoreApiService";

export default function useCustomerGunnebo(uid) {
    const [customerGunnebo, setCustomerGunnebo] = useState();
    const [gunneboLocations, setGunneboLocations] = useState();

    let unsubscribeCustomerGunnebo = useRef();

    useEffect(() => {
        if(!uid) {
            if(unsubscribeCustomerGunnebo.current) {
                unsubscribeCustomerGunnebo.current();
                unsubscribeCustomerGunnebo.current = null;
            }
            return;
        }
        unsubscribeCustomerGunnebo.current = FirestoreApiService.getCustomerGunneboLocationsUpdates(uid, data => {
            setGunneboLocations(data);
        }, error => {
            console.log("Error: ", error);
        });
        return () => {if(unsubscribeCustomerGunnebo.current) unsubscribeCustomerGunnebo.current()};
    }, [uid])

    useEffect(() => {

        if(!gunneboLocations) return;

        let active = false;
        let blocked = false;
        let pin = false;

        gunneboLocations.forEach( location => {
            if(location.status === 'BLOCKED_CARD') blocked = true;
            if(location.activated.card) active = true;
            if(location.activated.pin) pin = true;
            return null;
        });

        const joinedData = {
            locations: gunneboLocations,
            hasActiveCard: active,
            hasBlockedCard: blocked,
            hasPin: pin
        }

        setCustomerGunnebo(joinedData);

    }, [gunneboLocations]);

    const resetCustomerGunnebo = () => {
        setCustomerGunnebo(null);
        setGunneboLocations(null);
    }

    return {customerGunnebo, resetCustomerGunnebo};
}
