import React from "react";
import {Route} from "react-router-dom";
import CheckoutFlow from "concerns/checkout/CheckoutFlow";
import {checkoutPaths} from "navigation/CheckoutRoutes";

export const signupPaths = {
    signupRoot: '/signup',
}

export const isSignupPath = (path) => {
    let included = false;
    if(path === signupPaths.signupRoot) included = true;
    for (const arr of Object.entries(checkoutPaths)) {
        if(path === signupPaths.signupRoot + arr[1]) included = true;
    }
    return included;
}

export const SignupRoutes = [
        <Route
            path={signupPaths.signupRoot}
            render={() => {return  <CheckoutFlow rootPath={signupPaths.signupRoot}/>}}
            key={signupPaths.signupRoot}
        />
    ];
