import React from "react";
import {Route} from "react-router-dom";
import DeleteAccountInfoView from "concerns/public-pages/DeleteAccountInfoView";

export const publicPaths = {
    publicPathsRoot: '/public',
    deleteAccountInfo: '/delete-account-info'
}

export const isPublicPath = (path) => {
    let included = false;
    //if(path === publicPaths.publicPathsRoot) included = true;
    for (const arr of Object.entries(publicPaths)) {
        if(path === publicPaths.publicPathsRoot + arr[1]) included = true;
    }
    return included;
}

export const PublicRoutes = [
    <Route
        path={publicPaths.publicPathsRoot + publicPaths.deleteAccountInfo}
        render={() => {return  <DeleteAccountInfoView />}}
        key={publicPaths.publicPathsRoot + publicPaths.deleteAccountInfo}
    />,

];
