import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, EmailAuthProvider, reauthenticateWithCredential, updateEmail, updatePassword } from "firebase/auth";
import { getFirebaseApp } from "./FirebaseService";

export const listenForAuthStateChanges = (callback) => {
    const auth = getAuth(getFirebaseApp());
    return onAuthStateChanged(auth, (user) => {
        callback(user);
    });
}

export const loginUser = (email, password) => {
    const auth = getAuth(getFirebaseApp());
    return signInWithEmailAndPassword(auth, email, password);
}

export const registerUser = (email, password) => {
    const auth = getAuth(getFirebaseApp());
    return createUserWithEmailAndPassword(auth, email, password);
}

export const logoutUser = () => {
    const auth = getAuth(getFirebaseApp());
    return signOut(auth);
}

export const updateEmailOnUser = (email) => {
    const auth = getAuth();
    return updateEmail(auth.currentUser, email);
}

export const updatePasswordOnUser = (password) => {
    const auth = getAuth();
    return updatePassword(auth.currentUser, password)
}

export function getCurrentAuthUser(){
    const auth = getAuth();
    return auth.currentUser;
}

export function reauthenticateUser(email, password){

    return new Promise( (resolve, reject) => {
        // Construct the email link credential from the current URL.
        const credential = EmailAuthProvider.credential(
            email,
            password
        );

        // Re-authenticate the user with this credential.
        const auth = getAuth();
        reauthenticateWithCredential(auth.currentUser, credential)
            .then((usercred) => {
                // The user is now successfully re-authenticated and can execute sensitive
                // operations.
                resolve(usercred);
            })
            .catch((error) => {
                // Some error occurred.
                reject(error)
            });
    });
}
