import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import styled, { css } from 'styled-components';
import { colors } from "@asservato/shared.styles";

export default function Select({options, value, onChange, disabled, label, error, helperText, margin, width, isValid}){

    return (
        <StyledField value={value}
                     disabled={disabled}
                     label={label}
                     variant='outlined'
                     error={error}
                     select={true}
                     helperText={helperText}
                     size={"small"}
                     fullWidth={true}
                     marginoverride={margin}
                     width={width}
                     onChange={onChange ? (e) => onChange(e) : null}
                     isvalid={isValid?.toString()}
        >
            <MenuItem value={""}>-</MenuItem>
            {options?.map((option) => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                </MenuItem>
            ))}
        </StyledField>
    )
}

const StyledField = styled(TextField)`

    margin: ${props => props.marginoverride || null} !important;
    width: ${props => props.width || null} !important;
    text-align: left;
    
    & label.Mui-focused {
        color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_300};
        top: 0px;
    }

    .MuiInputLabel-root {
        color: ${colors.primary.primary_300};
        font-size: 18px;
        top: ${props => props.value?.length > 0 ? '0px' : '3px'};
    }

    .MuiInputLabel-shrink{
        color: ${props => props.disabled ? colors.primary.primary_350 : props.isvalid === "true" ? colors.secondary.secondary_100 : colors.primary.primary_400};
    }

    & .MuiOutlinedInput-root {
        height: 48px;
        font-size: 18px;

        & fieldset{
            border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : colors.primary.primary_450};
            border-width: 1px;
            border-radius: 0;
        }

    ${(props) => {
        if (!props.disabled) {
            return css`
                  &:hover fieldset {
                    border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning_light : colors.primary.primary_100};
                  }`;
        }
    }}
       
        &.Mui-focused fieldset {
          border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_300};
          border-width: 1px;
        }
    }
`;
