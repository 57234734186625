import { useEffect, useState, useCallback } from 'react';
import {useLocation} from "react-router-dom";
import * as Sentry from "@sentry/browser";
import FunctionsApiService from "services/FunctionsApiService";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function useQueryParams() {

    const [queryUserData, setQueryUserData] = useState();
    const [queryLoginData, setQueryLoginData] = useState();
    const [queryTrackingData, setTrackingData] = useState();
    const [queryCouponCode, setCouponCode] = useState();
    const [queryLanguage, setLanguage] = useState();
    const [isQueryEvaluated, setIsQueryEvaluated] = useState(false);
    const query = useQuery();

    const getValidQueryParam = useCallback((p) => {

        let validParam = p;
        let re = /(%7B)|(%7D)|[{}]/;

        if(re.test(p)){
            validParam = null;
            try {
                Sentry.captureMessage("Invalid query params detected: " + p + " in / " + query);
            } catch (error){}
        }

        return validParam;

    }, [query]);

    const mapQueryParam = useCallback(p => {

        switch(p){
            case "Herr":
                p = "MR";
                break;
            case "Frau":
                p = "MS";
                break;
            case "Dr":
                p = "DR";
                break;
            case "Prof":
                p = "PROF";
                break;
            case "None":
                p = null;
                break;
            default:
                break;
        }

        return p;

    }, []);

    const getLoginLink = useCallback(async (email) => {
        const emailValidation = await FunctionsApiService.customersValidateEmail({email: email});
        if(emailValidation?.data.processed === "pending" || emailValidation?.data.processed === "pending_no_password"){
            const queryString = emailValidation.data.loginLink.split('?')[1];
            const urlParams = new URLSearchParams(queryString);

            const loginData = {
                action: urlParams.get('action'),
                email: atob(urlParams.get('u')),
                tp: atob(urlParams.get('tp'))
            }

            return loginData;
        } else {
            resetQueryLoginData();
            setQueryUserData(null);
            return null;
        }

    }, [])


    useEffect(()=>{

        // http://localhost:3000?action=continue&u=ZmFiaWFuamF4dCtjMjBAZ21haWwuY29t&tp=c0RHM3dWSjR6dDdM
        if(query.get('action')){
            try {
                const action = query.get('action');
                const u = query.get('u');
                const p = query.get('tp');

                const email = action === "continue" ? atob(u) : u;
                const tp = action === "continue" ? atob(p) : p;

                setQueryLoginData({
                    action: query.get('action'),
                    email: email,
                    tp: tp
                })
            } catch (error) {
                // there was something wrong with the credentials or the action
                resetQueryLoginData();
            }
        }

        if(query.get('location') && query.get('location') !== "null"){
            setQueryUserData({
                location: query.get('location'),
            });
        }

        // Example query params
        // http://localhost:3000/signup?location=BER&boxSize=MAX&email=guybrush%2Bwa67@gmail.com&firstName=Guybrush&lastName=Threepwood&salutation=Frau&title=Dr
        if(query.get('email')){

            setQueryUserData({
                location: query.get('location'),
                boxSize: query.get('boxSize'),
                email: query.get('email'),
                salutation: mapQueryParam(getValidQueryParam(query.get('salutation'))) || '',
                title: mapQueryParam(getValidQueryParam(query.get('title'))) || '',
                firstName: getValidQueryParam(query.get('firstName')),
                lastName: getValidQueryParam(query.get('lastName')),
            });
        }

        // Example query params
        // http://localhost:3000/signup?utm_source=facebook&utm_medium=CPC&utm_campaign=reisebank&utm_id=REISEBANK&utm_content=my_content&utm_term=my_term
        if(query.get('utm_source')){
            setTrackingData({
                utm_source: query.get('utm_source'),
                utm_medium: query.get('utm_medium'),
                utm_campaign: query.get('utm_campaign'),
                utm_id: query.get('utm_id'),
                utm_content: query.get('utm_content'),
                utm_term: query.get('utm_term'),
            });
        }

        // Example query params
        // http://localhost:3000/1?vc=4WOCHEN
        const vc = query.get('vc');
        if(vc && vc !== "null"){
            setCouponCode(vc);
        }

        if(query.get('lang')){
            setLanguage(query.get('lang'));
        }

        setIsQueryEvaluated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetQueryLoginData = () => {
        setQueryLoginData(null);
    }

    return {queryLoginData, queryUserData, queryTrackingData, queryCouponCode, queryLanguage, isQueryEvaluated, resetQueryLoginData, getLoginLink}
}
