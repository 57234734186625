import React from 'react';
import styled, { css } from 'styled-components';

import { RadioButton } from "@asservato/shared.ui.radio-button";
import { IconSuccess } from "@asservato/shared.ui.icon-success";

import { colors, fonts } from "@asservato/shared.styles";

export default function ItemLocation({name, street, city, isLast, isDisabled, isActive, isSummary, onClick, id}){

    return (
        <Container isLast={isLast} isDisabled={isDisabled} isActive={isActive} isSummary={isSummary} onClick={onClick ? () => onClick(id) : null}>
            {isSummary
                ? <IconSuccess/>
                : <RadioButton isActive={isActive} disableHover={true}/>
            }
            <Right>
                <Name isDisabled={isDisabled}>{name}</Name><br/>
                <Address isDisabled={isDisabled}>{street}</Address><br/>
                <Address isDisabled={isDisabled}>{city}</Address>
            </Right>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    border: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    background: ${props => props.isActive ? 'rgba(30,226,200, .1)' : 'transparent'};
    margin-bottom: ${props => props.isLast ? 0 : "12px"};
    cursor: ${props => !props.isSummary ? 'pointer' : 'inherit'};
    text-align:left;
    
    ${(props) => {
    if(props.isDisabled){
        return css`
                    border: 1px dashed ${colors.primary.primary_450};
                `;
    } else if(!props.isActive && !props.isSummary){
        return css`
                    &:hover {
                        border: 1px solid ${colors.primary.primary_300};
                    }
                `;
    }
}
}
`;

const Right = styled.div`
    margin-left: 24px;
`;

const Name = styled(fonts.Title)`
  color: ${props => props.isDisabled ? colors.primary.primary_300 : colors.primary.primary_100};
`;

const Address = styled(fonts.Body)`
  color: ${props => props.isDisabled ? colors.primary.primary_300 : colors.primary.primary_100};
`;
