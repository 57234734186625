import styled from "styled-components";
import {colors} from './colors';

export const Body = styled.span`
    font-family: 'Aalto Regular', Helvetica, sans-serif;
    font-size: 17px;
    color: ${colors.primary.primary_100};
`;

export const Title = styled.span`
    font-family: 'Aalto SemiBold', Helvetica, sans-serif;
    font-size: 20px;
    color: ${colors.primary.primary_100};
`;

export const LabelSemiBold = styled.span`
    font-family: 'Aalto SemiBold', Helvetica, sans-serif;
    font-size: 17px;
    line-height: 24px;
    color: ${colors.primary.primary_100};
`;

export const LabelRegularMedium = styled.span`
    font-family: 'Aalto Regular', Helvetica, sans-serif;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.primary.primary_100};
`;

export const LabelRegularSmall = styled.span`
    font-family: 'Aalto Regular', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.primary.primary_100};
`;
