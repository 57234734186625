import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useAppState} from "../../contexts/AppStateContext";

import SelectBoxItem from "./components/SelectBoxItem";
import PrimaryButton from '../../components/PrimaryButton';
import InfoBox from '../../components/InfoBox';
import LoadingIndicator from '../../components/LoadingIndicator';
import StaticNotification from '../../components/StaticNotification';

import { useQuery } from '../../concerns/checkout/hooks/useQueryParams';
import { breakpoints } from "../../styles/breakpoints";
import {Title, Body} from "../../styles/fontStyles";
import {authPaths} from "../../navigation/AuthRoutes";
import {useHistory} from "react-router-dom";
import {dashboardPaths} from "../../navigation/DashboardRoutes";
import {getMigrationBoxesForUser, setMigrationBoxesForUser} from "../../services/RESTApiService";


export default function PlanMigrationView() {

    const { authUser, strings } = useAppState();
    const history = useHistory();
    const [boxes, setBoxes] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [boxMigrations, setBoxMigrations] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const query = useQuery();
    const uid = query.get('u');

    useEffect(()=>{

        const getData = async ()=>{
            try {
                let res = await getMigrationBoxesForUser(uid);

                /*
                let data = [
                    {
                        id:'aAaAaAaA',
                        gunnebo: {
                            id: 101,
                        },
                        location: "BER",
                        size: "STD",
                    },
                    {
                        id:'bBbBbBbB',
                        gunnebo: {
                            id: 3251,
                        },
                        location: "BER",
                        size: "MAX",
                    }
                ]*/

                if(res?.data){
                    let migrations = [];

                    res.data?.forEach((el) => {
                        migrations.push(
                            {
                                boxId: el.boxId,
                                migrate: false,
                            }
                        )
                    })

                    setBoxes(res.data);
                    setBoxMigrations(migrations);
                    setIsLoading(false);
                } else {
                    throw new Error();
                }
            } catch(error){
                console.log("There was an error, loading the boxes: ", error);
                setIsLoading(false);
                setErrorMessage(strings['ERROR/general']);
            }
        }

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const onSelectionChange = (docId) => {

        let migrations = boxMigrations.slice();
        migrations.forEach(el => {
            if(el.boxId === docId){
                el.migrate = !el.migrate;
            }
        })

        setBoxMigrations(migrations);
    }

    const onConfirm = () => {
        setErrorMessage(null);
        setIsLoading(true);

        const writeData = async () => {

            try {
                await setMigrationBoxesForUser(uid, boxMigrations);
                setIsSuccess(true);
                setIsLoading(false);
            } catch(error) {
                setErrorMessage(strings['ERROR/general']);
                setIsLoading(false);
            }

        }

        writeData();

    }

    if(!uid) {
        return <View>
            <Container>
                <Title>
                    Dieser Link ist ungültig
                </Title>
                <p>
                    <Body>
                        Dieser link scheint nicht (mehr) gültig zu sein.
                    </Body>
                </p>

                <PrimaryButton marginTop={48} label={"Ok"} onClick={() => history.replace(authUser ? dashboardPaths.boxesOverview : authPaths.login)}/>
            </Container>
        </View>
    }

    if(!boxes || !boxMigrations) {
        return (
        <View>
            <Container>
                {isLoading
                    ?   <LoadingIndicator size={40} padding={'24px'}/>
                    :   <Body>Die Daten konnten nicht geladen werden. Bitte laden Sie die Seite erneut.</Body>
                }

            </Container>
        </View>)
    }

    return <View>
        {!isSuccess
            ?   <Container>
                    <>
                        <Title>
                            Preissicherung für ein Jahr durch Umstellung auf Jahresvertrag
                        </Title>
                        <p>
                        <Body>
                            {boxes.length > 1
                                ? 'Wählen Sie bitte die Fächer aus, die auf die jährliche Abrechnung umgestellt werden sollen.'
                                : 'Wählen Sie bitte das Fach aus, das auf die jährliche Abrechnung umgestellt werden soll.'
                            }
                        </Body>
                        </p>

                        <BoxListContainer>
                            {boxes?.map( el => {
                                return <SelectBoxItem
                                docId={el.boxId}
                                boxId={el.gunneboId}
                                boxSize={el.size}
                                key={el.boxId}
                                onChange={onSelectionChange}
                                isSelected={boxMigrations.filter(b => b.boxId === el.boxId)[0].migrate}
                                />
                            })}
                        </BoxListContainer>

                        <InfoBox message={'Bitte beachten Sie, dass Ihre Entscheidung verbindlichen Charakter hat. Die von Ihnen ausgewählten Fächer können bei Notwendigkeit selbstverständlich nach 12 Monaten wieder auf monatlichen Turnus umgestellt werden.'}/>

                        {errorMessage && <ErrorMessageContainer><StaticNotification isWarning={true} text={errorMessage}/></ErrorMessageContainer>}
                        {isLoading
                            ?   <LoadingIndicator size={40} padding={'8px'} marginTop={48}/>
                            :   <PrimaryButton marginTop={48} label={"Fächer verbindlich umbuchen"} onClick={onConfirm} isDisabled={boxMigrations?.filter(b => b.migrate).length === 0}/>
                        }
                    </>
                </Container>
            :   <Container>
                    <Title>
                        Glückwunsch! Sie haben sich Ihren bisherigen Preis für ein Jahr gesichert.
                    </Title>
                    <p>
                        <Body>
                            Die Umstellung war erfolgreich. Beachten Sie bitte die aktuellen und zukünftigen Entgelte in unserem <a href={'https://www.asservato.de/PuLV'}>Preis- und Leistungsverzeichnis</a>.
                        </Body>
                    </p>
                    <InfoBox message={'Bitte beachten Sie, dass die Umstellung erst zum Ablauf der aktuellen Rechnungsperiode erfolgt und Sie die Änderung auch erst im Anschluss daran in der Vertragsansicht (Kundenportal / App) sehen werden.'}/>
                    <PrimaryButton marginTop={48} label={"Ok"} onClick={() => history.replace(authUser ? dashboardPaths.boxesOverview : authPaths.login)}/>
                </Container>
        }
    </View>
}

const View = styled.div`
    height: 100vh;
    background: linear-gradient(45deg, #091541, #233460);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    
    @media ${breakpoints.mobileL} {
        justify-content: center;
    }
`;

const Container = styled.div`
    background-color: white;
    width: 100%;
    max-width: 540px;
    padding: 48px 24px;
    box-sizing: border-box;

    @media ${breakpoints.mobileL} {
        border-radius: 5px;
        padding: 72px;
        max-height: 90vh;
        overflow: hidden;
        overflow: scroll;
    }
`;


const BoxListContainer = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
`;

const ErrorMessageContainer = styled.div`
    margin-top: 32px;
`;
