export const isValidFirstName = function (input) {
    return input && input.length >= 2;
}

export const isValidLastName = function (input) {
    return input && input.length >= 2;
}

export const isValidEmail = function(email){
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const isValidSreetname = function(input){
    return input && input.length >= 2;
};

export const isValidHouseNr = function(input){
    return input && input.length >= 1;
};

export const isValidPostcode = function(input){
    return input && input.length >= 2;
};

export const isValidCity = function(input){
    return input && input.length >= 2;
};

export const isValidPhonenumber = function(text){
    var re = /^[0-9+ ]+$/;
    return re.test(text);
};

export const isValidDate = function(date){
    var re = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
    return re.test(date);
};

export const isLettersOnly = function(text){
    var re = /^[a-zA-ZäöüßÄÖÜéÉèÈáÁàÀúÚùÙâÂûÛêÊñÑ\- ]+$/;
    return re.test(text);
};

export const isNumbersOnly = function(text){
    var re = /^[0-9]+$/;
    return re.test(text);
};
