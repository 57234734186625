import React from 'react';
import {Route, Switch} from "react-router-dom";

import SelectLocationView from "./SelectLocationView";
import SelectBoxSizeView from "./SelectBoxSizeView";
import EnterContactDetailsView from "./EnterContactDetailsView";
import EnterAddressView from "./EnterAddressView";
import CheckoutSummaryView from "./CheckoutSummaryView";
import RegistrationView from "./RegistrationView";
import IdentificationMethodView from "./IdentificationMethodView";
import CheckoutCompleteView from "./CheckoutCompleteView";
import {checkoutPaths} from "navigation/CheckoutRoutes";
import {dashboardPaths} from "navigation/DashboardRoutes";
import {accountSetupPaths} from "navigation/AccountSetupRoutes";
import SelectPaymentCycleView from "concerns/checkout/views/SelectPaymentCycleView";

export default function NavigationContainer({rootPath, isShortFlow}) {

    const paths = checkoutPaths;

    return (
        <div className="asv-form">
            <Switch>
                <Route
                    path={[rootPath, rootPath + paths.locationSelection]}
                    render={() => <SelectLocationView rootPath={rootPath} prevPath={dashboardPaths.boxesOverview} nextPath={paths.boxSizeSelection} index={1}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.boxSizeSelection}
                    render={() => <SelectBoxSizeView rootPath={rootPath} prevPath={paths.locationSelection} nextPath={paths.paymentCycleSelection} index={2}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.paymentCycleSelection}
                    render={() => <SelectPaymentCycleView rootPath={rootPath} prevPath={paths.boxSizeSelection} nextPath={isShortFlow ? paths.checkoutSummary : paths.enterContactDetails} index={3}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.enterContactDetails}
                    render={() => <EnterContactDetailsView rootPath={rootPath} prevPath={rootPath === accountSetupPaths.accountSetupRoot ? null : paths.paymentCycleSelection} nextPath={paths.enterAddressDetails} index={rootPath === accountSetupPaths.accountSetupRoot ? 1 : 4}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.enterAddressDetails}
                    render={() => <EnterAddressView rootPath={rootPath} prevPath={paths.enterContactDetails} nextPath={rootPath === accountSetupPaths.accountSetupRoot ? paths.choosePostIdent : paths.checkoutSummary} index={rootPath === accountSetupPaths.accountSetupRoot ? 2 : 5}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.checkoutSummary}
                    render={() => <CheckoutSummaryView rootPath={rootPath} prevPath={isShortFlow ? paths.paymentCycleSelection : paths.enterAddressDetails} nextPath={paths.enterPassword} index={isShortFlow ? 3 : 6}/>}
                    exact
                />

                <Route
                    path={rootPath + paths.enterPassword}
                    render={() => <RegistrationView rootPath={rootPath} nextPath={isShortFlow ? paths.checkoutComplete : paths.choosePostIdent} index={isShortFlow ? 4 : 7}/>}
                    exact
                />


                <Route
                    path={rootPath + paths.choosePostIdent}
                    render={() => <IdentificationMethodView rootPath={rootPath} nextPath={paths.checkoutComplete} index={isShortFlow ? 5 : 8}/>}
                    exact
                />


                <Route
                    path={rootPath + paths.checkoutComplete}
                    render={() => <CheckoutCompleteView rootPath={rootPath} nextPath={dashboardPaths.boxesOverview}/>}
                    exact
                />
            </Switch>
        </div>
    );
}
