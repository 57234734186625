export const cardData = {
    std: {
      title: 'Standard',
      imgSrc: `${process.env.PUBLIC_URL}/assets/box-STD.png`,
      imgLargeSrc: `${process.env.PUBLIC_URL}/assets/box-STD.png`,
      imgAlt: '',
      ribbonText: 'Beliebte Größe',
    },
    spl: {
        title: 'Standard Plus',
        imgSrc: `${process.env.PUBLIC_URL}/assets/box-SPL.png`,
        imgLargeSrc: `${process.env.PUBLIC_URL}/assets/box-SPL.png`,
        imgAlt: '',
        ribbonText: 'Beliebte Größe',
    },
    max: {
      title: 'Maxi',
      imgSrc: `${process.env.PUBLIC_URL}/assets/box-MAX.png`,
      imgLargeSrc: `${process.env.PUBLIC_URL}/assets/box-MAX.png`,
      imgAlt: '',
    },
}
