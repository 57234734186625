import styled from 'styled-components';
import Button from "@mui/material/Button";
import {colors} from "../styles/colors";
import React from "react";

export default function TextButton ({label, isDisabled, onClick, marginTop}){

    return (
        <ButtonContainer marginTop={marginTop}>
            <StyledButton variant={"text"} disabled={isDisabled} onClick={onClick ? () => onClick() : null} disableRipple>{label}</StyledButton>
        </ButtonContainer>
    )

}

const ButtonContainer = styled.div`
    margin-top: ${props => props.marginTop ? props.marginTop+'px' : 0};
    display: flex;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    
  && {
      color: ${colors.primary.primary_100};
      height: 32px;
      font-family: "Aalto SemiBold", sans-serif;
      font-size: 17px;
      text-transform: inherit;
      border-bottom: 2px solid ${colors.secondary.secondary_100};
      border-radius: 0;
      padding: 0;
  }
  
  &&:hover {
      color: ${colors.primary.primary_300};
      border-bottom: 2px solid ${colors.secondary.secondary_200};
      background-color: transparent;
  }
    
  &&.Mui-disabled {
      color: ${colors.primary.primary_400};
      border-bottom: 2px solid ${colors.primary.primary_450};
  }
`;
