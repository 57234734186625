import React, {useCallback, useState, useEffect} from 'react';
import styled from "styled-components";
import * as Sentry from "@sentry/browser";
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import FunctionsApiService from "services/FunctionsApiService";
import trackEvent, {EVENTS} from "../services/Tracking";
import { objectsAreEqual } from '../services/Utils';

import { PageHeaderLinearFlow } from "@asservato/shared.ui.page-header-linear-flow";
import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import { CheckBox } from "@asservato/shared.ui.checkbox";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";
import { ItemLocation } from "@asservato/shared.ui.item-location";
import VoucherCodeInput from "../../../components/VoucherCodeInput";
import { PaymentMethodSelection } from "components/PaymentMethodSelection";
import { ItemContactDetails } from "@asservato/shared.ui.item-contact-details";
import { ItemBoxSize } from "components/ItemBoxSize";

import {LabelRegularSmall} from "../styles/fontStyles";
import {colors} from "../styles/colors";
import * as S from '../styles/sharedStyles';
import {ItemAddressDetails} from "../components/ItemAddressDetails";
import {useAppState} from "contexts/AppStateContext";
import {useHistory} from "react-router-dom";
import {signupPaths} from "navigation/SignupRoutes";
import {checkoutPaths} from "navigation/CheckoutRoutes";
import usePrices from "hooks/usePrices";

export default function CheckoutSummaryView({ rootPath, prevPath, nextPath, index }){

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, updateActiveStep, checkouts, setErrorMessage, locations } = useCheckoutState();
    const { customerBoxes, customerPartnerBoxes } = useAppState();
    const { fees } = usePrices(formData.selectedLocation);
    const { t } = useTranslation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethodSelectionStarted, setPaymentMethodSelectionStarted] = useState(false);

    const selectedLocation = locations?.filter(el => el.token === formData.selectedLocation)[0];
    const selectedBoxActivationFee = fees?.filter(el => el.product.ref === formData.selectedPlan.requires[0])[0];

    useEffect(()=>{
        updateFormData({heroImage:"../assets/checkout/hero_2.jpg"});
        updateActiveStep(index);

        // only track events in signup
        if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.ADDRESS_DATA_ENTERED);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConfirm = useCallback(async () => {
        let coupon = null;

        if(isLoading) return;

        setIsLoading(true);

        if (formData.couponCode?.id) {
            coupon = formData.couponCode.id;
        }

        try {

            const checkoutPayload = {
                location: formData.selectedLocation,
                pricing: {
                    box: formData.selectedBox.id,
                    plan: formData.selectedPlan.id,
                },
                payment: {
                    method: 'NONCE',
                    token: formData.braintreeNonce,
                },
                coupon: coupon,
            }

            await FunctionsApiService.checkoutsCreate(checkoutPayload);

            trackEvent(EVENTS.PURCHASE_CONFIRMED);

            if(rootPath === checkoutPaths.checkoutRoot){
                //setIsLoading(false);
                history.push(rootPath + nextPath);
            }

        } catch(error){

            let message = '';

            if (error && error.message ) {
                message = error.message;
            }
            if (!error || !error.message || error === 'Invalid request data') {
                message = t('error');
            }

            Sentry.captureException(error);
            setIsLoading(false);
            setErrorMessage(message);
        }
    }, [history, nextPath, rootPath, formData.selectedLocation, formData.braintreeNonce, formData.couponCode, formData.selectedBox?.id, formData.selectedPlan?.id, setErrorMessage, setIsLoading, isLoading, t])

    useEffect(() =>{
        if(rootPath === signupPaths.signupRoot && checkouts?.length > 0){
            history.push(rootPath + nextPath);
        }
    }, [checkouts, rootPath, nextPath, history]);

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, rootPath, prevPath]);


    const isFormValid = formData.checkTermsConditions && formData.braintreeNonce;

    if(!selectedLocation || !fees) return null;

    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={onBack}
                progressBrackets={progressBrackets}
                onConfirm={onConfirm}
                isConfirmEnabeled={isFormValid && !isLoading}
                showBackButton={true}
            />

            <S.Content>

                <S.Description>
                    {t('checkoutSummary.subheading')}
                </S.Description>

                <ItemBoxSize name={t('selectBoxSize.sizeCard.title', {title: t('selectBoxSize.'+formData.selectedBox.meta.size)})}
                             subline1={t('selectBoxSize.sizeCard.size', { width: formData.selectedBox.meta.scale.width / 10, height: formData.selectedBox.meta.scale.height / 10, depth: formData.selectedBox.meta.scale.deep / 10 })}
                             subline2={t('selectBoxSize.sizeCard.details'+formData.selectedPlan.interval.unit)}
                             price={t('selectBoxSize.sizeCard.price', {price:formData.selectedPlan.price.unit}) + " / " + t('selectPaymentCycle.interval'+formData.selectedPlan.interval.unit)}
                             priceSubline={selectedBoxActivationFee ? t('selectBoxSize.sizeCard.activationFee', {fee:selectedBoxActivationFee.plans[0].price.unit}) : ''}
                             image={'../assets/checkout/box-'+formData.selectedBox.meta.size+'.png'}
                             isSummary={true}/>

                <ItemLocation name={t('selectLocation.location')+ ": " + selectedLocation.name} street={selectedLocation.address.split(',')[0]} city={selectedLocation.address.split(',')[1]} isSummary={true}/>

                { (customerBoxes?.length === 0 && customerPartnerBoxes?.length === 0) && <>
                    <ItemContactDetails name={[t(formData.salutation), formData.title ? t(formData.title) : '', formData.firstName, formData.lastName].join(' ')}
                                        email={formData.email}
                                        phone={formData.phone}
                                        company={formData.company}
                                        isSummary={true}
                    />

                    <ItemAddressDetails title={objectsAreEqual(formData.address, formData.billingAddress) ? "Liefer- & Rechnungsadresse" : "Lieferadresse"}
                                        addressLine1={formData.address.street + ' ' + formData.address.streetNo}
                                        addressLine2={formData.address.zip + ' ' + formData.address.city + ' - ' + formData.address.country} />

                    {!objectsAreEqual(formData.address, formData.billingAddress) && formData.billingAddress &&
                    <ItemAddressDetails title={"Rechnungsadresse"}
                                        addressLine1={formData.billingAddress.street + ' ' + formData.billingAddress.streetNo}
                                        addressLine2={formData.billingAddress.zip + ' ' + formData.billingAddress.city + ' - ' + formData.billingAddress.country} />
                    }
                    </>
                }


                {!formData.couponCode?.id && (paymentMethodSelectionStarted || formData.braintreeNonce)
                    ? null
                    : <VoucherCodeInput isDisabled={isLoading}
                                        formData={formData}
                                        updateFormData={updateFormData}
                                        t={t}
                                        onCodeEntered={ code => {
                                            trackEvent(EVENTS.COUPON_CODE_ENTERED, 'code', code)
                                        }}
                    />
                }

                {paymentMethodSelectionStarted && !formData.braintreeNonce
                    ?   <PaymentHeader>{t('checkoutSummary.paymentHeader')}</PaymentHeader>
                    :   null
                }

                <PaymentMethodSelection isDisabled={isLoading}
                                        onStarted={() => setPaymentMethodSelectionStarted(true)}
                                        onPaymentSelected={(data) => trackEvent(EVENTS.PAYMENT_METHOD_SELECTED, 'type', data.type)}
                                        on3DSValidationFailed={(status) => trackEvent(EVENTS.THREEDS_VALIDATION_FAILED, 'status', status)}
                                        formData={formData}
                                        updateFormData={updateFormData}
                                        price={formData.selectedPlan.price.unit}
                />

                {formData.braintreeNonce
                    ?   !isLoading
                        ?   <>
                                <FormRow marginTop={"16px"}>
                                    <CheckBox size={"small"} checked={formData.checkTermsConditions} onChange={(e)=>updateFormData({checkTermsConditions:e.target.checked})}/>
                                    <Label>{t('checkoutSummary.policy.part1')}
                                        <a href='https://legal.asservato.de/agb' target="_privacy" onClick={() => {trackEvent(EVENTS.TERMS_VIEWED);}}>{t('checkoutSummary.policy.agb')}</a>
                                        {t('checkoutSummary.policy.part2')}
                                    </Label>
                                </FormRow>
                                <ButtonPrimary label={t('checkoutSummary.purchaseCta')} marginTop={16} onClick={onConfirm} isDisabled={!formData.checkTermsConditions}/>
                            </>
                        :   <LoadingIndicator marginTop={24}/>
                    :   null
                }
            </S.Content>

        </S.View>
    )
}

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Label = styled(LabelRegularSmall)`
    a {
        color: ${colors.tertiary.tertiary_100};
    }
`;

const PaymentHeader = styled.div`
    display: block;
    padding-top: 16px;
    text-align: center;
`;
