import {useEffect, useState} from 'react';
import {Button, IconButton} from "@mui/material";
import {cardData} from "constants/card-data";

import {dashboardPaths} from "navigation/DashboardRoutes";
import BoxCard from "./components/BoxCard";
import StaticNotification from "components/StaticNotification";
import {useHistory} from "react-router-dom";
import { useAppState } from "contexts/AppStateContext";
import {CustomerAuthentication} from "constants/enums_customer";
import {PostIdentStatus} from "constants/enums_postident";
import {SubscriptionPaymentStatus} from "constants/enums_subscription_payment";
import AddRoundedIcon from '@mui/icons-material/Add';
import useCustomerPartnerRequests from "hooks/useCustomerPartnerRequests";
import PartnerRequestItem from "concerns/boxes/concerns/boxes-overview/components/PartnerRequestItem";
import {checkoutPaths} from "navigation/CheckoutRoutes";

import LoadingView from 'components/LoadingView';


export default function BoxesOverviewView() {
    const {authUser, strings, locations, customer, customerRegistrationIsComplete, customerBoxes, customerPartnerBoxes, customerPostIdent, customerSubscription, customerGunnebo} = useAppState();
    const history = useHistory();
    const {openPartnerRequestsByCustomer} = useCustomerPartnerRequests(authUser?.uid);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if(customer && customerBoxes && customerPartnerBoxes && openPartnerRequestsByCustomer && locations?.length > 0) setIsLoading(false);
    }, [customer, customerBoxes, customerPartnerBoxes , openPartnerRequestsByCustomer, locations, setIsLoading]);

    if(isLoading){
        return <LoadingView/>
    }

    const handleRequestClick = ((e) => {
        e.preventDefault();
        history.push(dashboardPaths.requestAccessForm);
    })

    const filteredBoxes = filterCancelledBoxes(customerBoxes);
    const sortedBoxes = sortListByCreationDate(filteredBoxes);
    const allBoxes = [].concat(sortedBoxes, customerPartnerBoxes);

    const renderHeader = () => {
        if (allBoxes && allBoxes.length > 0 && customerRegistrationIsComplete) {

            // PostIdent not started yet
            if (customer?.authentication === CustomerAuthentication.OPEN || customerPostIdent?.status === PostIdentStatus.CREATED || customerPostIdent?.status === PostIdentStatus.REQUESTED) {
                return (
                    <StaticNotification text={strings.IDENTIFICATION_OPEN_NOTIFICATION}
                                        onPress={() => {history.push(checkoutPaths.checkoutRoot + checkoutPaths.choosePostIdent)}}
                                        isPostIdent={true}/>
                );
            }

            // PostIdent process completed, waiting for response from PostIdent
            if (customerPostIdent?.status === PostIdentStatus.IN_REVIEW) {
                return (
                    <StaticNotification text={strings.IDENTIFICATION_REQUESTED_NOTIFICATION}
                                        onPress={() => history.push(dashboardPaths.postidentConfirmation + '/:notification')}
                                        isPostIdent={true}/>
                );
            }

            // PostIdent failed
            if (customerPostIdent?.status === PostIdentStatus.DECLINED) {
                return (
                    <StaticNotification text={strings.IDENTIFICATION_DECLINED_NOTIFICATION} isWarning={true}/>
                );
            }

            // Key card is blocked
            if (customerGunnebo.hasBlockedCard) {
                return (
                    <StaticNotification text={strings.CARD_BLOCKED_NOTIFICATION}
                                        onPress={() => history.push(dashboardPaths.userDataCard)} isWarning={true}/>
                );
            }

            // Key card is not registered yet
            if (!customerGunnebo.hasActiveCard) {
                return (
                    <StaticNotification text={strings.CARD_REGISTRATION_PENDING_NOTIFICATION}
                                        onPress={() => history.push(dashboardPaths.userDataCard)}/>
                );
            }

            if(customerSubscription?.paymentCurrentStatus === SubscriptionPaymentStatus.FAILED){
                return (
                    <StaticNotification text={strings.PAYMENT_ISSUE_NOTIFICATION}
                                        onPress={() => history.push(dashboardPaths.userData)} isWarning={true}/>
                );
            }
        }

        return null;
    }

    return (
        <div className="boxes-overview-container">
            <div className="page-header box-list-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                         srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                         alt="Menu Icon"
                    />
                </Button>
                {strings["BOX_LIST_VIEW_TITLE"]}
                <IconButton
                    className="add-icon"
                    onClick={() => history.push(dashboardPaths.selectBoxLocations)}
                    size="large"><AddRoundedIcon/></IconButton>
            </div>

            {renderHeader()}

            <div className="padded-container">
                <div className="boxes">
                    {allBoxes.length === 0 && openPartnerRequestsByCustomer.length === 0
                        ?   <div className="empty-box-list">
                                <img className="box-image" src={cardData['std'].imgLargeSrc} alt={cardData.imgAlt}/>

                                <p className="title">{strings["BOX_LIST_VIEW_EMPTY_TITLE"]}</p>
                                <p className="subtitle">{strings["BOX_LIST_VIEW_EMPTY_DESCRIPTION"]}</p>
                            </div>
                        :   <>
                                {openPartnerRequestsByCustomer?.map(request => <PartnerRequestItem key={request.boxId} request={request}/>)}
                                {allBoxes.map(box => <BoxCard key={box.id} box={box} locations={locations} customer={customer} customerRegistrationIsComplete={customerRegistrationIsComplete}/>)}
                            </>
                    }
                </div>

                <div className="next-btn-container">
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className="blue-button"
                        onClick={() => history.push(checkoutPaths.checkoutRoot)}
                    >
                        {allBoxes.length === 0 ? strings["BOX_LIST_VIEW_BTN_ADD_BOX"] : strings['BOX_LIST_VIEW_BTN_ADD_ANOTHER_BOX']}
                    </Button>
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="text-button" onClick={handleRequestClick}>
                    {strings["BOX_LIST_VIEW_BTN_REQUEST_BOX_ACCESS"]}
                </a>
            </div>
        </div>
    );
}

function sortListByCreationDate(list){
    return list.sort((a,b) => {
        if (a.createdAt?.seconds > b.createdAt?.seconds) {
            return -1;
        } else if (a.createdAt?.seconds < b.createdAt?.seconds) {
            return 1;
        }
        return 0;
    });
}
/*
function sortListByBoxNr(list){
    return list.sort((a,b) => {
        if (parseInt(a.gunnebo?.id, 10) < parseInt(b.gunnebo?.id, 10)) {
            return -1;
        } else if (parseInt(a.gunnebo?.id, 10) > parseInt(b.gunnebo?.id, 10)) {
            return 1;
        }
        return 0;
    });
}*/

function filterCancelledBoxes(list){

    const now = new Date().getTime();
    const dayInMillis = 24 * 60 * 60 * 1000;

    const r = list.filter( el => {
        if(el.cancelling){
            const endsAt = new Date(el.cancelling?.endsAt).getTime();
            const dayAfterCancellation = endsAt + dayInMillis;
            return now < dayAfterCancellation;
        }
        return true;
    })

    return r;
}
