import React, {useCallback, useState} from 'react';
import {Button, FormControl, TextField} from "@mui/material";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useSnackbar} from "notistack";
import {getAuth} from "firebase/auth";
import {sendPasswordResetEmail} from "firebase/auth";
import {isValidEmail} from "services/InputValidationService";

export default function ForgotPasswordView() {
    const {strings, setIsLoading, goBack} = useAppState();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const auth = getAuth();

    const handleClick = useCallback(() => {
        setIsLoading(true);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                enqueueSnackbar(strings["FORGOT_PW_VIEW_NOTIFICATION_SUCCESS"], {variant: 'success'})
            })
            .catch((error) => {
                console.error(error.code);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [email, enqueueSnackbar, setIsLoading, strings, auth]);

    const isEmailValidCheck = useCallback((email) => {
        return isValidEmail(email);
    }, [])

    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
        setIsEmailValid(isEmailValidCheck(e.target.value));
    }, [isEmailValidCheck])

    return (
        <div className="auth-action-form-container">
            <div className="page-header overlay-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["FORGOT_PW_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="screen-content">
                <p>
                    {strings["FORGOT_PW_VIEW_DESCRIPTION"]}
                </p>

                <FormControl variant="outlined" className="input-container">
                    <TextField
                        className="email-field"
                        placeholder={strings["LOGIN_VIEW_PLACEHOLDER_EMAIL"]}
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </FormControl>

                <Button
                    disabled={!isEmailValid}
                    variant="contained"
                    className="blue-button"
                    onClick={handleClick}
                >
                    {strings["FORGOT_PW_VIEW_BTN_SEND"]}
                </Button>
            </div>
        </div>
    );
}
