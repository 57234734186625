import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            de: {
                translation: {
                    error: "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.",
                    saveChangesSuccess: "Ihre Änderungen wurden erfolgreich gespeichert",
                    continue: "Weiter",
                    yes: "Ja",
                    no: "Nein",
                    MR: "Herr",
                    MS: "Frau",
                    DR: "Dr.",
                    PROF: "Prof.",
                    loading: "Daten werden geladen...",
                    save: "Speichern",
                    popular: "beliebt",
                    selectLocation: {
                        title: "Standortauswahl",
                        subheading: "Bitte wählen Sie den Standort Ihrer Filiale aus",
                        location: "Standort",
                        locationSoonAvailable: "In Vorbereitung",
                    },
                    selectBoxSize: {
                        title: "Schließfachgröße auswählen",
                        subheading: "Wählen Sie nun Ihre gewünschte Schließfachgröße aus",
                        STD: "Standard",
                        SPL: "Standard Plus",
                        MAX: "Maxi",
                        sizeCard: {
                            title: "Fach {{title}}",
                            detailsMONTHLY: "Monatlich kündbar",
                            detailsYEARLY: "Jährlich kündbar",
                            size: "{{width}} x {{height}} x {{depth}} cm (B x H x T)",
                            weightAllowance: 'Bis zu 20kg Traglast',
                            price: "{{price}} Euro",
                            activationFee: "+ einmalige Aktivierungsgebühr von {{fee}} Euro",
                            month: "Monat",
                            std: {
                                ribbon: "Beliebte Größe"
                            },
                            summary: {
                                insured: "mit 30.000 € grundversichert",
                                contractTerms: "keine lange Vertragsbindung:",
                                cancellation: "monatlich kündbar"
                            }
                        },
                        checklistItem1: "Bankenunabhängiges Wertschließfach",
                        checklistItem2: "Flexibler Zugang an 365 Tagen im Jahr",
                        checklistItem3: 'Versicherungsschutz von 30.000€*',
                        checklistItem3Fineprint: '* Kann nach der Buchung auf bis zu 1 Mio. € erhöht werden',
                    },
                    selectPaymentCycle: {
                        subheading: "Wählen Sie hier den Abrechnungszeitraum. Das Fach ist jeweils zum Ende des gewählten Zeitraums kündbar.",
                        headlineIntervalMONTHLY: "Monatlich",
                        headlineIntervalYEARLY: "Jährlich",
                        intervalMONTHLY: "Monat",
                        intervalYEARLY: "Jahr",
                        detail1MONTHLY: "Monatlich kündbar",
                        detail2MONTHLY: "Volle Flexibilität",
                        detail3MONTHLY: "15 Euro Aktivierungsgebühr",
                        detail1YEARLY: "Komfortable Jahresabrechnung",
                        detail2YEARLY: "Planungssicherheit",
                        detail3YEARLY: "Keine Aktivierungsgebühr",
                        voucherCodeNoteMONTHLY: "Sie zahlen in den ersten zwei Monaten 0 EUR. Danach gilt der angezeigte Preis.",
                        voucherCodeNoteYEARLY_STD: "Sie zahlen für das erste Jahr nur 240 EUR. Danach gilt der angezeigte Preis.",
                        voucherCodeNoteYEARLY_SPL: "Sie zahlen für das erste Jahr nur 340 EUR. Danach gilt der angezeigte Preis.",
                        voucherCodeNoteYEARLY_MAX: "Sie zahlen für das erste Jahr nur 540 EUR. Danach gilt der angezeigte Preis.",
                        voucherCodeNoteGeneral: "Sollten Sie einen Gutschein-Code besitzen, können Sie diesen vor der Bezahlung eingeben."
                    },
                    enterContactDetails: {
                        title: "Ihre Kontaktdaten",
                        subheading: "Damit wir Ihnen Ihre persönliche Kundenkarte zustellen können, benötigen wir Ihre Kontaktdaten.",
                        info: "Damit wir Ihnen Ihre persönliche Kundenkarte zustellen können, benötigen wir Ihre Kontaktdaten.",
                        errorDialog: {
                            existingUser: {
                                title: "E-Mail bereits registriert",
                                description: "Diese E-Mail ist bereits registriert. Bitte verwenden Sie eine andere E-Mail-Adresse, oder loggen Sie sich ein.",
                                back: "Zurück",
                                toLogin: "Zum Login"
                            },
                            temporaryUser: {
                                title: "Buchungsprozess bereits begonnen",
                                description: "Sie hatten den Buchungsprozess bereits zu einem früheren Zeitpunkt mit der gleichen E-Mail-Adresse begonnen. Wir haben Ihnen eine E-Mail an {{email}} geschickt. Über den darin enthaltenen Link können Sie die Buchung abschließen.",
                                ok: "Ok"
                            }
                        },
                        infoBox: {
                            std: "Eine gute Wahl. Das „Fach Standard” liefert genügend Platz für Dokumente und kleine Gegenstände.",
                            maxi: "Eine gute Wahl. Das „Fach Maxi” liefert genügend Platz für Dokumente, kleine Boxen und größere Gegenstände.",
                        },
                        salutation: "Anrede",
                        professionalTitle: "Titel (optional)",
                        firstName: "Vorname",
                        lastName: "Nachname",
                        company: "Firma (optional)",
                        email: {
                            label: "E-Mail",
                            placeholder: "Ihre E-Mail Adresse"
                        },
                        phone: {
                            label: "Telefon",
                            placeholder: "Ihre Telefonnummer (optional)"
                        },
                        policy: {
                            part1: 'Alle Informationen zum Umgang mit Ihren Daten, finden Sie in unserer ',
                            privacy: 'Datenschutzerklärung',
                            part2: ''
                        }
                    },
                    enterAddressDetails: {
                        title: "Ihre Kontaktdaten",
                        subheading: "",
                        info: "Bitte geben Sie hier die Adresse ein, an die Ihre Kundenkarte geliefert werden soll.",
                        street: "Straße",
                        streetNumber: {
                            label: "Hausnummer",
                            placeholder: "Nr."
                        },
                        postcode: "PLZ",
                        city: "Stadt",
                        country: "Land",
                    },
                    checkoutSummary: {
                        title: "Zusammenfassung",
                        subheading: "Bitte überprüfen Sie Ihre Eingaben",
                        billingAddress: "Rechnungsadresse",
                        coupon: {
                            label: "Gutschein",
                            placeholder: "Gutschein Code",
                            redeem: "Einlösen",
                            error: "Der Code ist leider nicht gültig."
                        },
                        amount: {
                            label: "Betrag",
                            time: "Monatlich",
                            code: "Code",
                            sum: "Summe",
                        },
                        paymentHeader: "Bitte wählen Sie ein Zahlungsmittel",
                        paymentCta: "Zur Bezahlung",
                        policy: {
                            part1: 'Ich habe die ',
                            agb: 'AGB',
                            part2: ' gelesen und stimme ihnen zu.'
                        },
                        purchaseCta: 'Kostenpflichtig bestellen'
                    },
                    paymentMethodSelection:{
                        paymentCta: ' Zur Bezahlung',
                        cardEnding: 'endet auf {{lastFour}}',
                        threeDSecureValidationError: "Das 3D Secure-Verfahren wurde nicht erfolgreich abgeschlossen. Bitte versuchen Sie es erneut, oder wählen Sie ein anderes Zahlungsmittel.",
                    },
                    register: {
                        title: "Registrierung abschließen",
                        subheading: "Bitte schließen Sie Ihre Registrierung ab, indem Sie ein Passwort festlegen.",
                        description: "Bitte legen Sie ein Passwort fest, um sich in Ihrem Account anmelden zu können.",
                        progressList: {
                            description: 'Dieser Vorgang kann bis zu einer Minute dauern. Bitte lassen Sie diese Seite daher so lange geöffnet.',
                            step1Loading:'Fach wird reserviert',
                            step1Done:'Fach reserviert',
                            step2Loading:'Fach wird aktiviert',
                            step2Done:'Fach aktiviert',
                            step3Loading:'Fach wird bereitgestellt',
                            step3Done:'Fach bereitgestellt',
                            successWithBoxNr:'Ihre Buchung war erfolgreich und Schließfach Nr. {{boxNr}} ist für Sie reserviert.',
                            successNoBoxNr:'Ihre Buchung war erfolgreich und ihr Schließfach ist für Sie reserviert.',
                        },
                        email: {
                            label: "E-Mail",
                            placeholder: "Ihre E-Mail Adresse",
                            existsError: "Diese Email ist bereits registriert, bitte melden Sie sich in der WebApp an.",
                        },
                        password: {
                            label: "Passwort",
                            placeholder: "Ihr Passwort wählen",
                        },
                        repeatPassword: {
                            label: "Passwort wiederholen",
                            placeholder: "Ihr Passwort wiederholen",
                            error:{
                                mismatch:'Die Passwörter stimmen nicht überein.',
                            }
                        },
                        chipCard: {
                            label: "Besitzen Sie bereits eine Kundenkarte?",
                            placeholder: "Nummer der Kundenkarte",
                            error: {
                                constraints: 'Die Kartennummer ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
                                alreadyRegistered: 'Die Kartennummer ist bereits registriert.',
                            },
                        },
                        cta: "Passwort speichern"
                    },
                    selectIdentification: {
                        title: "Identität bestätigen",
                        description: "Für die Bestätigung Ihrer Identität werden Sie zum PostIdent-Verfahren der Deutschen Post weitergeleitet.",
                        onSiteSubheading: "Bitte gehen Sie nun zurück zur Rezeption",
                        onSiteDescription: "Nachdem unsere Mitarbeiter Ihre Identität überprüft haben, werden Sie Ihnen eine Kabine zuweisen.",
                        onSiteNote: "Sie können dieses Fenster jetzt schließen.",
                        postIdentVerificationCard: {
                            title: "POSTIDENT",
                            online: {
                                title: "Online",
                                text: "Sie werden zur Website weitergeleitet.",
                                duration: "wenige Minuten",
                                features: {
                                    1: "nur Personalausweis oder Reisepass nötig",
                                    2: "keine Wartezeit",
                                    3: "Kein Videotelefonat nötig",
                                }
                            },
                            branch: {
                                title: "Filiale",
                                text: "Besuchen Sie eine der Filialen der Deutschen Post AG",
                                duration: "1 Werktag",
                            }
                        },
                        notice: "Wir belasten Ihr Zahlungsmittel erst im Falle einer erfolgreichen Authentifizierung. Die Daten Ihrer Authentifizierung werden nach unseren Datenschutzbedingungen nach DSVGO-Richtlinien behandelt",
                        transactionNumber: 'Vorgangsnummer',
                        cta: {
                            select: 'Auswählen',
                            redirect: 'Weiter zum PostIdent',
                            change: 'Methode wechseln'
                        },
                    },
                    signupComplete: {
                        subheading: "Vielen Dank, Ihre Registrierung ist nun vollständig abgeschlossen.",
                        cta: "Zum Login"
                    },
                    checkoutComplete: {
                        subheading: "Vielen Dank, Ihre Buchung war erfolgreich.",
                        cta: "Zur Übersicht"
                    },
                    dialogCancelRegistration: {
                        description: "Alle Eingaben gehen verloren.",
                        close: "Anmeldung fortfahren",
                        cancel: "Anmeldung abbrechen",
                    },
                    editContactDetails: {
                        title: "Kontaktdaten ändern",
                        changeEmail:{
                            info: "Die Änderung Ihrer E-Mail-Adresse führt auch zur Änderung Ihrer Anmeldedaten. Bitte verwenden Sie die neue E-Mail wenn sie sich das nächste Mal im Kundenportal/in der App anmelden möchten. Bitte bestätigen Sie die Änderung mit Ihrem Login-Passwort:",
                        }
                    },
                    editAddress: {
                        title: "Adresse ändern",
                    },
                    errors: {
                        wrongPassword:'Bitte überprüfen sie das Passwort',
                    }
                }
            },
            uk : {
                translation: {
                    error: "Сталася помилка, будь ласка, спробуйте ще раз.",
                    continue: "Продовжити",
                    yes: "Так",
                    no: "Ні",
                    MR: "Пан",
                    MS: "Пані",
                    DR: "Лікар",
                    PROF: "Професор",
                    loading: "Loading data...",
                    selectLocation: {
                        title: "Вибір місця розташування",
                        subheading: "Будь ласка, виберіть місцезнаходження свого відділення",
                        location: "Місцезнаходження",
                        locationSoonAvailable: "У підготовці"
                    },
                    selectBoxSize: {
                        title: "Виберіть розмір комірки",
                        subheading: "Тепер виберіть бажаний розмір комірки",
                        STD: "Standard",
                        SPL: "Standard Plus",
                        MAX: "Maxi",
                        sizeCard: {
                            title: "Тема {{title}}",
                            details: "Скасування щомісяця",
                            size: "{{width}} x {{height}} x {{depth}} см (Ш x В x Г)",
                            price: "{{price}} євро",
                            month: "Місяць",
                            std: {
                                ribbon: "Популярний розмір"
                            },
                            summary: {
                                insured: "З базовим страхуванням 30 000 євро",
                                contractTerms: "Довгострокового контракту немає",
                                cancellation: "Скасування щомісяця"
                            },
                        },
                        checklistItem1: "Незалежний від банку сейф",
                        checklistItem2: "Гнучкий доступ 365 днів на рік",
                        checklistItem3: "Страховий захист не менше €30 000*",
                        checklistItem3Fineprint: "* Після бронювання можна збільшити до 1 млн євро"
                    },
                    enterContactDetails: {
                        title: "Ваші контактні дані",
                        subheading: "Ваші контактні дані",
                        info: "Ваші контактні дані необхідні нам для того, щоб ми могли надіслати Вам персональну картку клиента.",
                        errorDialog: {
                            title: "Електронна пошта вже зареєстрована",
                            description: "Ця електронна пошта вже зареєстрована. Будь ласка, використайте іншу адресу електронної пошти або Увійдіть до облікового запису.",
                            back: "Назад",
                            toLogin: "До входу"
                        },
                        infoBox: {
                            std: "Гарний вибір. У «Комірці Стандарт» достатньо місця для документів та дрібних предметів.",
                            maxi: "Гарний вибір. У «Комірці Mаксі» достатньо місця для документів, невеликих коробок та великих предметів.”"
                        },
                        salutation: "Привітання",
                        professionalTitle: "Профессія (необов'язково)",
                        firstName: "Ім'я",
                        lastName: "Прізвище",
                        email: {
                            label: "Електронна пошта",
                            placeholder: "Ваша електронна пошта"
                        },
                        phone: {
                            label: "Телефон",
                            placeholder: "Ваш номер телефону (необов'язково)"
                        },
                        policy: {
                            part1: "Всю інформацію про використання ваших даних ви знайдете у нашій ",
                            privacy: "Політика конфіденційності",
                            part2: ""
                        },
                    },
                    enterAddressDetails: {
                        title: "Ваші контактні дані",
                        subheading: "",
                        info: "Будь ласка, введіть адресу, на яку має бути доставлена ваша персональна картка клієнта.",
                        street: "Вулиця",
                        streetNumber: {
                            label: "Номер будинку",
                            placeholder: "Номер"
                        },
                        postcode: "Поштовий індекс",
                        city: "Місто",
                        country: "Країна"
                    },
                    checkoutSummary: {
                        title: "Підсумок",
                        subheading: "Будь ласка, перевірте вірність введених даних",
                        billingAddress: "Платіжна адреса",
                        coupon: {
                            label: "Купон",
                            placeholder: "Код купона",
                            redeem: "Використати",
                            error: "На жаль, код недійсний."
                        },
                        amount: {
                            label: "Сума",
                            time: "Щомісячно",
                            code: "Код",
                            sum: "Сума"
                        },
                        paymentHeader: "Please choose a payment method",
                        paymentCta: "До оплати",
                        policy: {
                            part1: "Я ",
                            agb: "Умови",
                            part2: " Прочитав та погоджуюся"
                        },
                        purchaseCta: "Замовлення з витратами"
                    },
                    paymentMethodSelection: {
                        paymentCta: "До оплати",
                        cardEnding: "закінчується {{lastFour}}",
                        threeDSecureValidationError: "Процедура 3D - Secure не була успішно завершена. Будь ласка, спробуйте ще раз або виберіть інший спосіб оплати."
                    },
                    register: {
                        title: "Завершити реєстрацію",
                        subheading: "Будь ласка, завершіть реєстрацію, встановивши пароль.",
                        description: "Будь ласка, встановіть пароль для входу до свого облікового запису.",
                        progressList: {
                            description: "Цей процес може тривати до хвилини, тому, будь ласка,  тримайте цю сторінку відкритою.",
                            step1Loading: "Комірка буде зарезервована",
                            step1Done: "Комірка зарезервована",
                            step2Loading: "Комірка буде активована",
                            step2Done: "Комірка активована",
                            step3Loading: "Комірка буде надана",
                            step3Done: "Комірка надана",
                            successWithBoxNr: "Ваше бронювання було успішним, і комірка № {{boxNr}} зарезервована за Вами.",
                            successNoBoxNr: "Ваше бронювання було успішним, і комірка зарезервована за Вами."
                        },
                        email: {
                            label: "Електронна пошта",
                            placeholder: "Ваша електронна пошта",
                            existsError: "Ця електронна пошта вже зареєстрована, будь ласка, увійдіть у веб-програму."
                        },
                        password: {
                            label: "Пароль",
                            placeholder: "Введіть свій пароль"
                        },
                        repeatPassword: {
                            label: "Повторіть пароль",
                            placeholder: "Повторіть свій пароль",
                            error: {
                                mismatch: "Паролі не співпадають."
                            },
                        },
                        chipCard: {
                            label: "Ви вже маєте персональну картку клієнта?",
                            placeholder: "Номер персональної картки клієнта",
                            error: {
                                constraints: "Номер персональної картки недійсний. Будь ласка, перевірте свій запис.”",
                                alreadyRegistered: "Номер персональної картки вже зареєстрований."
                            },
                        },
                        cta: "Зареєструвати"
                    },
                    selectIdentification: {
                        title: "Підтвердити особу",
                        description: "Щоб ідентифікувати вашу особу, вас буде перенаправлено до процедури Постiдент Дойче Пост (PostIdent Deutsche Post).”",
                        onSiteSubheading: "Будь ласка,  зверніться до стійки реєстраціі”",
                        onSiteDescription: "Після того, як наші співробітники підтвердять вашу особу, вони призначать вам комірку.",
                        onSiteNote: "Тепер ви можете закрити це вікно.",
                        postIdentVerificationCard: {
                            title: "ПОСТІДЕНТ",
                            online: {
                                title: "Онлайн",
                                text: "Ви будете перенаправлені на веб-сайт.",
                                duration: "Кілька хвилин",
                                features: {
                                    1: "Потрібно лише посвідчення особи або паспорт",
                                    2: "Без часу очікування",
                                    3: "Відеодзвінок не потрібен"
                                },
                            },
                            branch: {
                                title: "Відділення",
                                text: "Відвідайте одну з філій Дойче Пост АГ (Deutsche Post AG)",
                                duration: "1 Робочий день"
                            },
                        },
                        notice: "Ми стягуємо кошти з ваших платіжних засобів лише у разі успішної аутентифікації. Дані вашої аутентифікації оброблятимуться відповідно до наших Умов захисту даних, відповідно до інструкцій Загального регламенту захисту даних )",
                        transactionNumber: 'Номер операції',
                        cta: {
                            select: "Виберіть",
                            redirect: "Перейдіть до PostIdent",
                            change: "Змінити метод"
                        },
                    },
                    dialogCancelRegistration: {
                        description: "Усі записи будуть втрачені.",
                        close: "Продовжити реєстрацію",
                        cancel: "Скасувати реєстрацію"
                    },
                },
            },
            en: {
                translation: {
                    error:"An error has occurred - please try again.",
                    continue:"Continue",
                    yes:"Yes",
                    no:"No",
                    MR:"Mr",
                    MS:"Ms",
                    DR:"Dr.",
                    PROF:"Prof.",
                    loading: "Loading data...",
                    save: "Save",
                    popular: "popular",
                    selectLocation:{
                        title:"Select location ",
                        subheading:"Please choose your branch's location",
                        location: "Location",
                        locationSoonAvailable:"In preparation",
                    },
                    selectBoxSize:{
                        title:"Select deposit box size",
                        subheading:"Please choose your preferred deposit box size",
                        STD:"Standard",
                        SPL:"Standard Plus",
                        MAX:"Maxi",
                        sizeCard:{
                            title:"Box {{title}}",
                            detailsMONTHLY: "Cancellation on a monthly basis",
                            detailsYEARLY: "Cancellation on a yearly basis",
                            details:"Can be cancelled on a monthly basis",
                            size:"{{width}} x {{height}} x {{depth}} cm (W x H x D)",
                            weightAllowance: 'Weight allowance up to 20kg',
                            price: "{{price}} Euro",
                            activationFee: "+ activation fee of {{fee}} Euro",
                            month:"Month",
                            std:{
                                ribbon:"Popular size",
                            },
                            summary:{
                                insured:"Insured at €30,000",
                                contractTerms:"No lengthy contractual obligations.",
                                cancellation:"Can be cancelled on a monthly basis",
                            },
                        },
                        checklistItem1:"Bank-independent safe deposit box facility",
                        checklistItem2:"Flexible entry 365 days a year",
                        checklistItem3:"Insurance cover to the value of €30,000*",
                        checklistItem3Fineprint:"* Can be increased to €1,000,000 after booking ",
                    },
                    selectPaymentCycle: {
                        subheading: "Please choose the billing cycle. The box can be cancelled at the end of the chosen term.",
                        headlineIntervalMONTHLY: "Monthly",
                        headlineIntervalYEARLY: "Yearly",
                        intervalMONTHLY: "Month",
                        intervalYEARLY: "Year",
                        detail1MONTHLY: "Can be cancelled monthly",
                        detail2MONTHLY: "Very flexible",
                        detail3MONTHLY: "15 Euro activation fee",
                        detail1YEARLY: "Convenient yearly invoice",
                        detail2YEARLY: "Planning security",
                        detail3YEARLY: "No activation fee",
                        voucherCodeNoteMONTHLY: "In the first two months you pay 0 EUR for the box. After this period you will pay the shown price.",
                        voucherCodeNoteYEARLY_STD: "For the first year you only pay 240 EUR. Afterwards you will pay the shown price.",
                        voucherCodeNoteYEARLY_SPL: "For the first year you only pay 340 EUR. Afterwards you will pay the shown price.",
                        voucherCodeNoteYEARLY_MAX: "For the first year you only pay 540 EUR. Afterwards you will pay the shown price.",
                        voucherCodeNoteGeneral: "If you own a voucher code, you can enter it before completing the booking."
                    },
                    enterContactDetails:{
                        title:"Your contact details",
                        subheading:"We need your contact details so that we can send you your personal customer card",
                        info:"We need your contact details so that we can send you your personal customer card",
                        errorDialog: {
                            existingUser: {
                                title:"E-mail address already registered ",
                                description:"This e-mail address is already in use. Please use another e-mail address, or log in.",
                                back:"Back",
                                toLogin:"To log-in",
                            },
                            temporaryUser: {
                                title: "Booking already started",
                                description: "You have previously started the booking process with the same e-mail address. We've sent an e-mail to {{email}}. Please click on the link in that email to complete your booking.",
                                ok: "Ok"
                            }
                        },
                        infoBox:{
                            std:"A good choice. Our 'Standard' sized box offers enough space for documents and small items.",
                            maxi:"A good choice. Our 'Maxi' sized box offers enough space for documents, small boxes and larger items.",
                        },
                        salutation:"Form of address",
                        professionalTitle:"Professional title",
                        firstName:"First name",
                        lastName:"Surname",
                        company:"Company (optional)",
                        email:{
                            label:"E-mail address ",
                            placeholder:"Your e-mail address",
                        },
                        phone:{
                            label:"Phone number",
                            placeholder:"Your phone number (optional)",
                        },
                        policy:{
                            part1:"You find all the information about how we handle your data in our ",
                            privacy:"privacy statement",
                            part2:"",
                        },
                    },
                    enterAddressDetails:{
                        title:"Your contact details",
                        subheading:"",
                        info:"Please give the address where you would like your customer card to be sent.",
                        street:"Street",
                        streetNumber:{
                            label:"House number",
                            placeholder:"Number",
                        },
                        postcode:"Postcode",
                        city:"City",
                        country:"Country",
                    },
                    checkoutSummary:{
                        title:"Summary",
                        subheading:"Please check your data",
                        billingAddress:"Billing address",
                        coupon:{
                            label:"Voucher",
                            placeholder:"Voucher code",
                            redeem:"Redeem",
                            error: "Code is not valid"
                        },
                        amount:{
                            label:"Amount",
                            time:"Monthly",
                            code:"Code",
                            sum:"Total",
                        },
                        paymentHeader: "Please choose a payment method",
                        paymentCta:"Go to payment",
                        policy:{
                            part1:"I have read",
                            agb:" the terms and conditions",
                            part2:" and agree to them.",
                        },
                        purchaseCta:"Confirm purchase",
                    },
                    paymentMethodSelection:{
                        paymentCta:"Payment options",
                        cardEnding:"ends on {{lastFour}}",
                        threeDSecureValidationError:"The 3D secure validation procedure was unsuccessful. Please try again, or choose an alternative method of payment.",
                    },
                    register:{
                        title:"Complete your registration",
                        subheading:"Please complete your registration by setting up a password.",
                        description:"Please enter a password that will be used to log in to your account.",
                        progressList:{
                            description:"This procedure may take up to a minute to complete. Please keep this site open during this time.",
                            step1Loading:"The deposit box is being reserved.",
                            step1Done:"The deposit box is reserved.",
                            step2Loading:"The deposit box is being activated.",
                            step2Done:"The deposit box is activated.",
                            step3Loading:"The deposit box is being made available.",
                            step3Done:"The deposit box is available.",
                            successWithBoxNr:"Your booking was successful and deposit box Nr. {{boxNr}} has been reserved for you.",
                            successNoBoxNr:"Your booking was successful and your deposit box has been reserved.",
                        },
                        email:{
                            label:"E-mail",
                            placeholder:"Your e-mail address",
                            existsError:"This e-mail address is already in use. Please sign in via the web app.",
                        },
                        password:{
                            label:"Password",
                            placeholder:"Select your password",
                        },
                        repeatPassword:{
                            label:"Repeat password",
                            placeholder:"Enter your password again",
                            error: {
                                mismatch: "The passwords do not match",
                            }
                        },
                        chipCard:{
                            label:"Do you already have a customer card?",
                            placeholder:"Customer card number",
                            error:"",
                            constraints:"The card number is not valid. Please check what you entered.",
                            alreadyRegistered:"The card number has already been registered.",
                        },
                        cta:"Save password",
                    },
                    selectIdentification:{
                        title:"Confirm identity",
                        description:"To confirm your identity, you will be redirected to Deutsche Post's 'PostIdent' procedure.",
                        onSiteSubheading:"Please return to the reception page",
                        onSiteDescription:"After our colleagues have checked your identity, you will be assigned a box.",
                        onSiteNote:"You may now close this window.",
                        postIdentVerificationCard:{
                            title:"PostIdent",
                            online:{
                                title:"Online",
                                text:"You will be redirected to the website.",
                                duration:"A few minutes",
                                features:{
                                    1:"Only your personal ID card or passport is necessary",
                                    2:"No waiting time",
                                    3:"No video calling needed",
                                },
                            },
                            branch:{
                                title:"Branch",
                                text:"Please visit one of Deutsche Post's branches",
                                duration:"One working day",
                            },
                        },
                        notice:"We will only debit your means of payment in the event of a successful authentication. The data needed to carry this out are processed in line with the German provisions of the European Data Protection Regulation.",
                        transactionNumber:"Transaction number",
                        cta:{
                            select:"Select",
                            redirect:"Go to PostIdent",
                            change:"Change method",
                        },
                    },
                    signupComplete: {
                        subheading: "Thank you! The registration is now complete.",
                        cta: "Go to Login"
                    },
                    checkoutComplete: {
                        subheading: "Thank you! Your booking was successful.",
                        cta: "Go to overview"
                    },
                    dialogCancelRegistration:{
                        description:"All entries made will be lost.",
                        close:"Continue with your registration",
                        cancel:"Cancel registration",
                    },
                },
            },
        },
        supportedLngs: ["de", "en", "uk"],
        nonExplicitSupportedLngs: false,
        fallbackLng: "de",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }

    });

export default i18n;
