import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInAnonymously, EmailAuthProvider, linkWithCredential, signOut } from "firebase/auth";

export const listenForAuthStateChanges = (callback) => {
    const auth = getAuth();
    return onAuthStateChanged(auth, (user) => {
        callback(user);
    });
}

export const createAnonymousUser = () => {
    const auth = getAuth();
    return signInAnonymously(auth);
}

export const convertAnonymousUser = (email, password) => {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(email, password);

    return linkWithCredential(auth.currentUser, credential);
}

export const loginUser = (email, password) => {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
}

export const logoutUser = () => {
    const auth = getAuth();
    return signOut(auth);
}

export const registerUser = (email, password) => {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
}

export function getCurrentAuthUser(){
    const auth = getAuth();
    return auth.currentUser;
}

