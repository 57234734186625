import React, {useEffect, useState} from "react";
import { useAppState } from "contexts/AppStateContext";
import 'UserDashboardMain.scss'
import {Button} from "@mui/material";
import {userDashboardPaths} from "constants/paths";
import {useHistory, useParams} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useBox from "hooks/useBox";
import {formatString, getDateStringFromTimestamp} from "utils/mobile-app-helpers";
import countries from "constants/countries";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import FunctionsApiService from "services/FunctionsApiService";

export default function MainCustomerInformationView() {
    const history = useHistory();
    const context = useAppState();
    const {strings, setIsLoading, goBack} = context;
    const {boxId} = useParams();
    const {box} = useBox(boxId);
    const [mainCustomer, setMainCustomer] = useState();

    useEffect(()=>{

        if(!box || mainCustomer) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const getMainCustomer = async () => {
            try {
                const res = await FunctionsApiService.getMainCustomerDetails(box?.partnerRequest?.customerId, box?.partnerRequest?.code)
                setMainCustomer(res.data);
                setIsLoading(false);
            } catch (error){
                console.log(error);
                setIsLoading(false);
            }
        }

        getMainCustomer();

    }, [box, setIsLoading, mainCustomer]);

    if (!box || !mainCustomer) {
        return <></>;
    }

    const statusUpdateDate = getDateStringFromTimestamp(box.partnerRequest.updatedAt.seconds * 1000);

    return (
        <div className='main-customer-info'>
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["SHARED_ACCESS_SETTINGS_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="overlay">
                <div className='main-customer-info-img container-blue'>
                    <img srcSet={`${process.env.PUBLIC_URL}/assets/user-icons/illu_users.png, ${process.env.PUBLIC_URL}/assets/user-icons/illu_users@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/user-icons/illu_users.png`} alt=""/>
                </div>

                <div className='main-customer-info-content'>
                    {
                        box.partnerRequest.status === ContractPartnerStatus.ACCESSIBLE
                            ? <div className='main-customers-content-text' dangerouslySetInnerHTML={{
                                __html: formatString(strings["SHARED_ACCESS_PARTNER_INFO_VIEW_DESCRIPTION"], {
                                    boxNr: '<b>' + strings.GENERAL_BOX + ' ' + strings.GENERAL_NR + ' ' + box.gunnebo.id + '</b>',
                                    date: '<b>' + statusUpdateDate + '</b>',
                                })
                            }}/>
                            : <div className='main-customers-content-text' dangerouslySetInnerHTML={{
                                __html: formatString(strings["SHARED_ACCESS_PARTNER_INFO_VIEW_DESCRIPTION_BLOCKED"], {
                                    boxNr: '<b>' + strings.GENERAL_BOX + ' ' + strings.GENERAL_NR + ' ' + box.gunnebo.id + '</b>',
                                    date: '<b>' + statusUpdateDate + '</b>',
                                })
                            }}/>
                    }

                    <div className='main-customers-content-title'>
                        <span>Hauptmieter</span>
                    </div>

                    <div>
                        <span>
                            {mainCustomer.title ? strings['GENERAL_TITLE_' + mainCustomer.title] + ' ' : ''}
                            {mainCustomer.firstName + ' '}
                            {mainCustomer.lastName}
                        </span><br/>
                        {
                            mainCustomer.address &&
                            <>
                                <span>{mainCustomer.address.street} {mainCustomer.address.streetNo}</span><br/>
                                <span>{mainCustomer.address.zip} {mainCustomer.address.city}</span><br/>
                                <span>{countries?.[mainCustomer.address.country]}</span><br/>
                            </>
                        }
                    </div>

                    <div className='line'></div>

                    <div className='main-customers-content-text'>
                        {strings["SHARED_ACCESS_PARTNER_INFO_VIEW_RENT_YOUR_OWN_BOX"]}
                    </div>

                    <Button
                        variant="contained"
                        className="blue-button"
                        onClick={() => history.push(userDashboardPaths.selectBoxLocations)}
                    >
                        {strings["SHARED_ACCESS_PARTNER_INFO_VIEW_BTN_RENT_YOUR_OWN_BOX"]}
                    </Button>
                </div>
            </div>
        </div>
    );
}
