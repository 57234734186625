import {useEffect, useState} from "react";
import FunctionsApiService from "services/FunctionsApiService";
import * as Sentry from "@sentry/browser";

export default function usePrices(location) {

    const [pricesRaw, setPricesRaw] = useState();
    const [boxPrices, setBoxPrices] = useState();
    const [insurancePrices, setInsurancePrices] = useState();
    const [partnerPrices, setPartnerPrices] = useState();
    const [fees, setFees] = useState();

    useEffect(()=>{

        if(pricesRaw) {

            let productTypes = [];

            pricesRaw.data.forEach( el => {
                if(!productTypes[el.product.type]) productTypes[el.product.type] = [];
                productTypes[el.product.type].push(el);
            });

            productTypes['BOX'].sort( (a,b) => {
                if(a.meta.scale.height > b.meta.scale.height) return 1;
                if(a.meta.scale.height < b.meta.scale.height) return -1;
                return 0;
            });

            setBoxPrices(productTypes['BOX']);

            productTypes['INSURANCE'].sort( (a,b) => {
                if(parseInt(a.product.ref, 10) < parseInt(b.product.ref, 10)) return -1;
                if(parseInt(a.product.ref,10) > parseInt(b.product.ref, 10)) return 1;
                return 0;
            });

            setInsurancePrices(productTypes['INSURANCE']);
            setPartnerPrices(productTypes['PARTNER']);
            setFees(productTypes['FEE']);
        }

    }, [pricesRaw])

    useEffect(()=>{
        if(!location) return;
        const fetchAsync = async () => {
            try {

                let prices = await FunctionsApiService.fetchPricesStatic({
                    'location': location
                });
                setPricesRaw(prices);

                prices = await FunctionsApiService.fetchPrices({
                    'location': location
                });

                if(prices) setPricesRaw(prices);

            } catch (error) {
                Sentry.captureException(error);
                console.log("There was a problem, loading the price data:", error);
            }
        }

        fetchAsync();

    }, [location]);

    return {pricesRaw, boxPrices, insurancePrices, partnerPrices, fees};
}
