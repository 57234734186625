export const allLocations = {
    data: [
        {
            "hours": {
                "Montag-Freitag": "07:00-21:00",
                "Samstag": "10:00-18:00",
                "Sonn- und Feiertage": "10:00-18:00"
            },
            "address": "Leipziger Platz 2, 10117 Berlin",
            "name": "Berlin",
            "token": "BER",
            "status": "OPEN",
            "invoicePdfAddressAdditionalParams": {
                "city": "Berlin",
                "invoicePdfAddressPart1": "Unter den Linden 26-30",
                "invoicePdfAddressPart2": "10117 Berlin"
            },
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 464000000
            }
        },
        {
            "hours": {
                "Montag-Freitag": "07:00-21:00",
                "Samstag": "10:00-18:00",
                "Sonn- und Feiertage": "10:00-18:00"
            },
            "address": "Kronprinzstraße 6, 70173 Stuttgart",
            "name": "Stuttgart",
            "token": "STR",
            "status": "OPEN",
            "invoicePdfAddressAdditionalParams": {
                "city": "Stuttgart",
                "invoicePdfAddressPart1": "Kronprinzstraße 6",
                "invoicePdfAddressPart2": "70173 Stuttgart"
            },
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 529000000
            }
        },
        {
            "name": "Düsseldorf",
            "token": "DUS",
            "status": "IN_PREPARATION",
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 676000000
            }
        },
        {
            "name": "Frankfurt am Main",
            "token": "FRA",
            "status": "IN_PREPARATION",
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 676000000
            }
        },
        {
            "name": "Hamburg",
            "token": "HAM",
            "status": "IN_PREPARATION",
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 676000000
            }
        },
        {
            "name": "München",
            "token": "MUC",
            "status": "IN_PREPARATION",
            "createdAt": {
                "_seconds": 1697115285,
                "_nanoseconds": 743000000
            }
        }
    ]
}

