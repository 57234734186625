import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import './concerns/boxes/concerns/new-box-flow/NewBoxFlow.scss';
import App from "./App";
import { I18nextProvider } from 'react-i18next';
import {BrowserRouter as Router} from "react-router-dom";
import i18n from 'constants/i18n';
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://d5a9da7fce574e43a2ed4a1bf37af2b5@o1297914.ingest.sentry.io/6527464",
    integrations: [Sentry.browserTracingIntegration()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.2,
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Router basename={process.env.PUBLIC_URL}>
                <App/>
            </Router>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('user_dashboard_root')
);
