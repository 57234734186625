export const userDashboardPaths = {
  login: '/',
  register: '/register',
  forgotPassword: '/forgot-password',
  authAction: '/auth/action',
  boxesOverview: '/boxes-overview',
  boxDetails: '/box-details',
  singleBoxRequestStatus: '/single-box-request-status',
  accessRequestConfirmation: '/access-request-confirmation',
  singleBoxRequestStatusCheckout: '/single-box-request-status-checkout',
  boxSettings: '/box-settings',
  boxInsurance: '/box-insurance',
  boxContract: '/box-contract',
  boxHistory: '/box-history',
  selectBoxLocations: '/select-box-locations',
  boxAccessConfiguration: '/box-access-configuration',
  confirmBookingAccess: '/confirm-booking-access',
  finishGrantingAccessProcess: '/finish-granting-access-process',
  userData: '/user-data',
  invoices: '/invoices',
  requestTan: '/request-tan',
  changePin: '/change-pin',
  reportLoss: '/report-loss',
  customerService: '/customer-service',
  planYourVisit: '/plan-visit',
  configureSharedAccess: '/configure-shared-access',
  requestAccessForm: '/access-request-form',
  requestAccessSuccess: '/access-request-success',
  mainCustomerInfo: '/main-customer-information',
  postidentConfirmation: '/postident-confirmation',
  postidentSelection: '/postident-selection',
}

export const userDataPaths = {
  main: '/user-data',
  address: '/address',
  email: '/email',
  telephone: '/telefon',
  card: '/card',
  password: '/password',
}
