import React from 'react';
import styled from 'styled-components';
import * as fonts from '../styles/fontStyles';
import {colors} from '../styles/colors';
import { IconSuccess } from '@asservato/shared.ui.icon-success';

export function ItemAddressDetails({title, addressLine1, addressLine2}){

    return (
        <Container>
            <IconSuccess/>
            <Right>
                <Name>{title}</Name><br/>

                <DetailsContainer>
                    <ContactDetail>{addressLine1}</ContactDetail><br/>
                    <ContactDetail>{addressLine2}</ContactDetail><br/>
                </DetailsContainer>
            </Right>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    border: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    margin-bottom: ${props => props.isLast ? 0 : "12px"};
`;

const Right = styled.div`
  margin-left: 24px;
`;

const Name = styled(fonts.Title)`
  color: ${colors.primary.primary_100};
`;

const DetailsContainer = styled.div`
  //line-height: 16px;
`;

const ContactDetail = styled(fonts.LabelRegularMedium)`
  color: ${colors.primary.primary_100};
`;
