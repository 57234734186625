import {useState, useEffect} from "react";
import FunctionsApiService from "services/FunctionsApiService";

export default function useBoxInfo(userId, boxId, sharedAccessCode) {

    const [boxInfo, setBoxInfo] = useState();

    useEffect(()=>{

        if(!userId || !boxId) return;

        async function fetchData() {
            try {
                const box = await FunctionsApiService.getBoxDetails(userId, boxId, sharedAccessCode)
                setBoxInfo(box.data);
            } catch(error) {
                console.log("Error: ", error);
            }
        }

        fetchData();

    }, [userId, boxId, sharedAccessCode])

    return {boxInfo};

}
