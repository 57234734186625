import React, {useCallback, useState, useEffect} from 'react';
import styled, { css } from "styled-components";
import FunctionsApiService from "services/FunctionsApiService";
import {InputBase, IconButton} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { IconSuccess } from "@asservato/shared.ui.icon-success";

import * as Icon from "react-feather";
import {colors, fonts} from '@asservato/shared.styles';


export default function VoucherCodeInput({isDisabled, formData, updateFormData, t, onCodeEntered}){

    const [code, setCode] = useState(formData?.couponCode?.id || "");
    const [codeDescription, setCodeDescription] = useState(formData?.couponCode?.description || "");
    const [isValid, setIsValid] = useState(!!formData?.couponCode?.description);
    const [errorMessage, setErrorMessage] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(code){
            validateCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateCode = useCallback(async ()=>{

        setErrorMessage("");
        setIsLoading(true);

        try {
            let data = await FunctionsApiService.paymentsValidateCoupon({'location': formData.selectedLocation, 'coupon': code?.trim()});
            const discount = parseFloat(data.data.amount);

            updateFormData({
                couponCode: {
                    ...data.data
                },
                couponCodeDiscount: discount,
            });

            setIsValid(true)
            setCodeDescription(data.data.description);
            setIsLoading(false);
            onCodeEntered(code)
        } catch(error){
            setErrorMessage(t('checkoutSummary.coupon.error'));
            setIsValid(false);
            setIsLoading(false);

            updateFormData({
                couponCode: null,
                couponCodeDiscount: 0,
            });
        }

    }, [code, formData.selectedLocation, updateFormData, onCodeEntered, t])

    const removeCode = useCallback(()=>{
        updateFormData({
            couponCode: null,
            couponCodeDiscount: 0,
        });

        setCode("");
        setCodeDescription("");
        setIsValid(false);
    }, [updateFormData])

    return (
        <Container isFocused={isFocused} isLoading={isLoading} isValid={isValid} isDisabled={isDisabled}>
            {!isValid
                ? <Wrapper>
                    <InputContainer>
                        <InputBase placeholder={t('checkoutSummary.coupon.placeholder')} fullWidth={true} value={code} onChange={e => setCode(e.target.value)} onBlur={()=>setIsFocused(false)} onFocus={() => {setIsFocused(true); setErrorMessage("")}} disabled={isDisabled}/>
                        <StyledButton variant={"contained"} disableElevation={true} disabled={code.length < 3 || isDisabled} onClick={validateCode} loading={isLoading}>{t('checkoutSummary.coupon.redeem')}</StyledButton>
                    </InputContainer>
                    {errorMessage
                        ?   <Error>{errorMessage}</Error>
                        :   null
                    }
                </Wrapper>
                : <>
                    <Left>
                        <IconSuccess/>
                        <Text>
                            <Name>{code}</Name><br/>
                            <Description>{codeDescription}</Description>
                        </Text>
                    </Left>


                    <IconButton onClick={removeCode} disabled={isDisabled}>
                        <Icon.X color={colors.primary.primary_350} size={20}/>
                    </IconButton>
                </>
            }

        </Container>
    )

}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.isValid ? '24px 16px 24px 24px' : '8px 8px 8px 16px'} ;
    border: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    margin-bottom: ${props => props.isLast ? 0 : "12px"};
    cursor: ${props => !props.isValid ? 'pointer' : 'inherit'};

    ${(props) => {
    if(props.isFocused){
        return css`
                border: 1px solid ${colors.primary.primary_300};
            `;
    } else if(!props.isActive && !props.isValid && !props.isDisabled){
        return css`
                &:hover {
                    border: 1px solid ${colors.primary.primary_300};
                }
            `;
    }
}}
`;

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    
    .MuiInputBase-input {
        font-size: 18px;
        
        ::placeholder {
            color: ${colors.primary.primary_300};
            opacity: 1;
        }
    }
`;

const StyledButton = styled(LoadingButton)`
   
  && {
      color: white;
      background-color: ${colors.secondary.secondary_100};
      //height: 56px;
      font-family: "Aalto SemiBold", sans-serif;
      font-size: 17px;
      text-transform: inherit;
  }
    
  &&.MuiLoadingButton-loading {
      color: transparent;
  }

  &&.Mui-disabled {
      background-color: ${colors.primary.primary_450};
  }
`;

const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const Text = styled.div`
    margin-left: 24px;
`;

const Name = styled(fonts.Title)`
    color: ${colors.primary.primary_100}
`;

const Description = styled(fonts.Body)`
  color: ${colors.primary.primary_100};
`;

const Error = styled(fonts.LabelRegularSmall)`
    display: block;
    margin-top: 8px;
    color: ${colors.semantic.warning_light};
`;
