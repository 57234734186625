import React from "react";
import {Route} from "react-router-dom";
import CheckoutFlow from "concerns/checkout/CheckoutFlow";
import {checkoutPaths} from "navigation/CheckoutRoutes";

export const accountSetupPaths = {
    accountSetupRoot: '/account-setup',
}

export const isAccountSetupPath = (path) => {
    let included = false;
    if(path === accountSetupPaths.accountSetupRoot) included = true;
    for (const arr of Object.entries(checkoutPaths)) {
        if(path === accountSetupPaths.accountSetupRoot + arr[1]) included = true;
    }
    return included;
}

export const AccountSetupRoutes = [
    <Route
        path={accountSetupPaths.accountSetupRoot}
        render={() => {return  <CheckoutFlow rootPath={accountSetupPaths.accountSetupRoot} />}}
        key={accountSetupPaths.accountSetupRoot}
    />,

];
