import React from 'react';
import styled from 'styled-components';
import {breakpoints} from "../../styles/breakpoints";
import {Title, Body, LabelSemiBold} from "../../styles/fontStyles";
import InfoBox from "../../components/InfoBox";

export default function DeleteAccountInfoView() {

    return (
        <View>
            <Container>
                <Title>Löschung Ihres Accounts</Title><br/>
                <br/>
                <p>
                <LabelSemiBold>Um die Löschung Ihres Accounts zu beantragen, sind folgende Schritte notwendig:</LabelSemiBold>
                <Body>
                    <br/><br/>
                    1. Melden Sie sich mit ihren Zugangsdaten in der App (Android oder iOS) an.
                    <br/><br/>
                    2. Navigieren Sie über das Hauptmenü zu "Meine Stammdaten"
                    <br/><br/>
                    3. Scrollen Sie die Ansicht bis ans Ende
                    <br/><br/>
                    4. Wählen Sie die Option "Account löschen"
                    <br/><br/>
                    5. Bestätigen Sie den Löschvorgang
                </Body>
                </p>

                <InfoBox message={"Nachdem Sie die Löschung beantragt haben, werden Ihre Daten innerhalb von 30 Tagen aus dem System gelöscht. Sollten Sie noch aktive Buchungen haben, kann sich dieser Zeitraum ggf. verlängern, da Ihre Daten erst gelöscht werden können, sobald alle Buchungen gekündigt wurden."}/>
            </Container>
        </View>
    )
}

const View = styled.div`
    height: 100vh;
    background: linear-gradient(45deg, #091541, #233460);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    
    @media ${breakpoints.mobileL} {
        justify-content: center;
    }
`;

const Container = styled.div`
    background-color: white;
    width: 100%;
    max-width: 540px;
    padding: 48px 24px;
    box-sizing: border-box;

    @media ${breakpoints.mobileL} {
        border-radius: 5px;
        padding: 72px;
        max-height: 90vh;
        overflow: hidden;
        overflow: scroll;
    }
`;
