import styled from 'styled-components';
import Button from "@mui/material/Button";
import {colors} from "../styles/colors";
import React from "react";

export default function PrimaryButton ({label, isDisabled, onClick, marginTop}){

    return (
        <ButtonContainer marginTop={marginTop}>
            <StyledButton variant={"contained"} disabled={isDisabled} onClick={onClick ? () => onClick() : null}>{label}</StyledButton>
        </ButtonContainer>
    )

}

const ButtonContainer = styled.div`
    margin-top: ${props => props.marginTop ? props.marginTop+'px' : 0};
    display: flex;
    justify-content: center;
`;

const StyledButton = styled(Button)`
   
  && {
      color: ${colors.primary.primary_100};
      background-color: ${colors.secondary.secondary_100};
      height: 56px;
      min-width: 240px;
      font-family: "Aalto SemiBold", sans-serif;
      font-size: 17px;
      text-transform: inherit;
  }
  
  &&:hover {
      background-color: ${colors.secondary.secondary_200};
  }
    
  &&.Mui-disabled {
      background-color: ${colors.primary.primary_450};
      color: ${colors.primary.primary_400};
  }
`;
