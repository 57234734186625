import {FirestoreApiService} from "services/FirestoreApiService";
import {useEffect, useState, useRef} from "react";
import {PartnerRequestStatus} from "constants/enums_partner_request";

export default function useCustomerBoxes(uid) {

    const [customerBoxes, setCustomerBoxes] = useState();

    let unsubscribeContractRequests = useRef([]);
    let unsubscribePartnerAccessRequests = useRef([]);
    let unsubscribePartnerRequests = useRef([]);
    let unsubscribeCustomerBoxes = useRef(null);

    useEffect(() => {

        const unsubscribeAll = () => {
            if(unsubscribeCustomerBoxes.current) unsubscribeCustomerBoxes.current();
            if(unsubscribeContractRequests.current.length > 0) unsubscribeContractRequests.current.forEach( unsubscribe => unsubscribe() );
            if(unsubscribePartnerAccessRequests.current.length > 0) unsubscribePartnerAccessRequests.current.forEach( unsubscribe => unsubscribe());
            if(unsubscribePartnerRequests.current.length > 0) unsubscribePartnerRequests.current.forEach( unsubscribe => unsubscribe());

            unsubscribeContractRequests.current = [];
            unsubscribePartnerAccessRequests.current = [];
            unsubscribePartnerRequests.current = [];
        }

        if(!uid) {
            unsubscribeAll();
            return;
        }

        unsubscribeCustomerBoxes.current = FirestoreApiService.getCustomerBoxesUpdates(uid, boxes => {

            const updateCustomerBoxes = (id, contract, requests, partners) => {
                if(contract) boxes.find(el => el.id === id).contract = contract;
                if(requests) boxes.find(el => el.id === id).requests = requests;
                if(partners) boxes.find(el => el.id === id).partners = partners;

                let completeCounter = 0;

                boxes.forEach( el => {
                    if(el.contract && el.requests && el.partners) completeCounter++;
                })

                if(completeCounter === boxes.length) {
                    const newBoxes = [...boxes];
                    setCustomerBoxes(newBoxes);
                }
            }

            if(boxes?.length > 0){

                boxes.forEach((el, i) => {
                    const unsubscribeContractRequest = FirestoreApiService.getCustomerGunneboContractUpdates(uid, el.location, el.id, (contract) => {
                        updateCustomerBoxes(el.id, contract);
                    }, error => {});

                    unsubscribeContractRequests.current.push(unsubscribeContractRequest);

                    //const unsubscribePartnerAccessRequest = FirestoreApiService.getPartnerRequestsForBoxUpdates(el.id, (req) => {
                    const unsubscribePartnerAccessRequest = FirestoreApiService.getPartnerRequestsForBoxUpdates(uid, el.id, (req) => {
                        // Filter all requests which are not relevant to the box owner
                        const openPartnerRequests = req.filter( el => el.status === PartnerRequestStatus.REQUESTED);
                        updateCustomerBoxes(el.id, null, openPartnerRequests);
                    }, error => {})

                    unsubscribePartnerAccessRequests.current.push(unsubscribePartnerAccessRequest);

                    const unsubscribePartnerRequest = FirestoreApiService.getCustomerGunneboContractPartnersUpdates(uid, el.location, el.id, (partners) => {
                        updateCustomerBoxes(el.id, null, null, partners);
                    })

                    unsubscribePartnerRequests.current.push(unsubscribePartnerRequest);
                })

            } else {
                setCustomerBoxes([]);
            }
        }, error => {});

        return () => {
            unsubscribeAll();
        }
    }, [uid]);

    const resetCustomerBoxesData = () => {
        setCustomerBoxes(null);
    }

    return {customerBoxes, resetCustomerBoxesData};
}
