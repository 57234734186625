import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import {Button} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useAppState} from "contexts/AppStateContext";
import useCustomerInvoices from "hooks/useCustomerInvoices";
import InvoiceListItem from "concerns/invoices/components/InvoiceListItem";
import {breakpoints, colors} from "@asservato/shared.styles";
import {useSnackbar} from "notistack";
import { Inbox } from 'feather-icons-react';
import LoadingView from "components/LoadingView";


export default function InvoicesListView() {
    const {strings} = useAppState();
    const {customerInvoices, sendInvoice} = useCustomerInvoices();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        setIsLoading(true);
        if(customerInvoices) setIsLoading(false);
    }, [customerInvoices, setIsLoading])

    const onSendInvoice = id => {
        sendInvoice(id);
    }

    const onDownloadInvoice = () => {
        enqueueSnackbar(strings['INVOICES_VIEW_MESSAGE_INVOICE_DOWNLOADED'], {
            variant: 'success',
            autoHideDuration: 1500,
        });
    }

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div>
            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                         srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                         alt="Menu Icon"
                    />
                </Button>

                {strings["INVOICES_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            {customerInvoices?.length === 0
                ?   <EmptyViewContainer>
                        <IconContainer>
                            <Inbox size={56} />
                        </IconContainer>
                        {strings['INVOICES_VIEW_NO_INVOICES']}
                    </EmptyViewContainer>
                :   <ListContainer>
                        {customerInvoices?.map(el => <InvoiceListItem invoiceData={el} onSendInvoice={onSendInvoice} onDownloadInvoice={onDownloadInvoice} key={el.id}/> )}
                    </ListContainer>
            }
        </div>
    )
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    @media ${breakpoints.mobileL} {
        padding: 0 100px;
    }
`;

const EmptyViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    box-sizing: border-box;
    width: 100%;
`;

const IconContainer = styled.div`
    color: ${colors.primary.primary_450};
    padding-bottom: 16px;
`;
