import {useEffect, useState, useCallback} from 'react';
import { useCheckoutState } from "concerns/checkout/contexts/CheckoutStateContext";
import { useAppState } from "contexts/AppStateContext";
import useQueryParams from "./useQueryParams";
import { loginUser, logoutUser } from "services/AuthenticationService";
import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import {useHistory} from "react-router-dom";
import {signupPaths} from "navigation/SignupRoutes";
import {checkoutPaths} from "navigation/CheckoutRoutes";
import usePrices from "hooks/usePrices";

export default function useSignupQueryHandler(isActive) {

    const { activeStep, setIsLoading, isLoading, updateFormData, authUser, checkouts } = useCheckoutState();
    const { queryLoginData, queryUserData, queryTrackingData, queryCouponCode, queryLanguage, getLoginLink, isQueryEvaluated} = useQueryParams();
    const { customer } = useAppState();
    const [isInitialised, setIsInitialised] = useState();
    const history = useHistory();
    const { boxPrices } = usePrices(customer?.signup?.location);

    const prefillFormData = useCallback(async (hasCheckout) => {

            updateFormData({
                email: customer.email,
                validatedEmail: customer.email,
                salutation: customer.salutation,
                professionalTitle: customer.title,
                firstName: customer.firstName,
                lastName: customer.lastName,
                address: customer.address,
                billingAddress: customer.billingAddress,
                checkPrivacyPolicy: true,
                activationSimulationStep: !hasCheckout ? 0 : 5
            });

            if(hasCheckout) {
                history.replace(signupPaths.signupRoot + checkoutPaths.enterPassword);
            } else if(customer.signup.location && customer.signup.box) {
                setIsLoading(true);

                try {
                    let box = boxPrices.filter(el => el.meta.size === customer.signup.box)[0];
                    updateFormData({
                        selectedLocation: customer.signup.location,
                        selectedSize: customer.signup.box,
                        selectedBox:box,
                        selectedPlan:box.plans[0]
                    });

                    if(activeStep < 5){
                        history.replace(signupPaths.signupRoot + checkoutPaths.checkoutSummary);
                    }
                } catch(error) {
                    Sentry.captureException(error);
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
    }, [customer, history, boxPrices, activeStep, setIsLoading, updateFormData]);

    const loginQueryUser = useCallback( async (email, tp) => {

        try {
            const loginData = tp ? {email: email, tp: tp} : await getLoginLink(email);
            setIsLoading(true);
            if (authUser && (authUser.email !== loginData.email)) {
                await logoutUser();
            }
            await loginUser(loginData.email, loginData.tp);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }

    })

    useEffect(()=>{

        if(!isActive) {
            setIsInitialised(true);
            return;
        }

        if(!isQueryEvaluated || authUser === undefined || isInitialised){
            return;
        }

        // is there already an authUser?
        if(authUser){
            if(customer) {
                if(customer.temporaryPassword){
                    if(checkouts) {
                        if(checkouts.length === 0) {
                            // User has not checked out yet
                            // --> prefill form data and send to checkout summary view
                            if(boxPrices) {
                                prefillFormData(false);
                                setIsInitialised(true);
                            }

                        } else if(checkouts.length === 1){

                            // user has completed the checkout
                            // -- > Send to password view
                            prefillFormData(true);
                            setIsInitialised(true);
                        }
                    }
                } else {
                    // if user doesn't have a temporary password
                    // check if user already has stored personal data
                    if(customer.firstName){
                        if(customer.authentication === "OPEN" || customer.authentication === "REQUESTED" || customer.authentication === "FAILED"){
                            if(checkouts) {

                                if (checkouts.length > 0) {
                                    prefillFormData(true);
                                    history.replace(signupPaths.signupRoot + checkoutPaths.choosePostIdent);
                                    // user has not completed PostIdent yet
                                    // -- > Send customer to PostIdent
                                }

                                setIsInitialised(true);
                                setIsLoading(false);
                            }
                        } else {
                            // user has a proper account and PostIdent
                            // TODO: Re-direct to web app
                            history.replace(signupPaths.signupRoot + checkoutPaths.checkoutComplete);
                            setIsLoading(false);
                            setIsInitialised(true);
                        }
                    } else {
                        // we have an anonymous user
                        // -- > No redirect
                        setIsInitialised(true);
                        setIsLoading(false);
                    }
                }
            }

        } else {
            if(queryLoginData?.email) {
                // if there's user data in the query string
                // load credentials
                loginQueryUser(queryLoginData?.email, queryLoginData?.tp);
            } else if(queryUserData?.email) {
                // if there's user data in the query string
                // just prefill the formData

                updateFormData({
                    email: queryUserData.email,
                    salutation: queryUserData.salutation,
                    professionalTitle: queryUserData.title,
                    firstName: queryUserData.firstName,
                    lastName: queryUserData.lastName,
                    selectedSize: queryUserData.boxSize,
                });

                history.replace(signupPaths.signupRoot + checkoutPaths.boxSizeSelection);
                setIsInitialised(true);
            } else {

                // if there's nothing in the query string, just display the first step of the flow
                setIsLoading(false);
                if(activeStep <= 1) {
                    history.replace(signupPaths.signupRoot);
                }
                setIsInitialised(true);
            }
        }

    }, [authUser, queryLoginData, customer, checkouts, boxPrices, activeStep, getLoginLink, history, isActive, prefillFormData, queryUserData, setIsLoading, isInitialised, isQueryEvaluated, updateFormData]);

    useEffect(() => {

        if(queryUserData?.location){
            updateFormData({
                selectedLocation: queryUserData.location
            });
        }

        // Example query params
        // http://localhost:3000/1?utm_source=facebook&utm_medium=CPC&utm_campaign=reisebank&utm_id=REISEBANK&utm_content=my_content&utm_term=my_term
        if(queryTrackingData){
            updateFormData({
                tracking: queryTrackingData
            });
        }

        if(queryCouponCode){
            updateFormData({
                couponCode: {
                    id:queryCouponCode
                }
            });
        }

        if(queryLanguage){
            i18n.changeLanguage(queryLanguage);
        }
    }, [queryUserData, queryTrackingData, queryCouponCode, queryLanguage, updateFormData])

    return {isInitialised};
}
