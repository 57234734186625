import React, {useCallback, useEffect, useState} from 'react';
import {userDashboardPaths} from "constants/paths";
import {InsuranceStatus} from '../../models/enums';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppState } from "contexts/AppStateContext";
import useBox from "hooks/useBox";
import {addThousandSeparator, getDateStringFromTimestamp, getFormattedCurrency} from "utils/mobile-app-helpers";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationForCancellation from "concerns/boxes/components/NotificationForCancellation";
import ButtonBadge from "../../components/ButtonBadge";
import useBoxPartnerRequests from "hooks/useBoxPartnerRequests";
import usePartners from "hooks/usePartners";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import {LocationContractStatus} from "constants/enums_location_contract";
import LoadingView from "components/LoadingView";

export default function SingleBoxDetailsView() {
    const history = useHistory();
    const {boxId} = useParams();
    const {customerSubscription, strings, customerGunnebo, subscriptionPrices, refreshSubscriptionPrices} = useAppState();
    const {location, box, cardData, isSharedAccess, visits, insurance} = useBox(boxId, true);
    const {hasOpenPartnerRequest} = useBoxPartnerRequests(box?.requests);
    const {getLastConfirmedPartner, lastConfirmedPartnerHasAccess, lastConfirmedPartnerIsLocked} = usePartners(box?.partners);

    const [lastVisit, setLastVisit] = useState(null);
    const [gunnebo, setGunnebo] = useState(null);
    const [amountInsured, setAmountInsured] = useState();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        if(isSharedAccess) return;
        if(!subscriptionPrices || !subscriptionPrices[boxId]) {
            refreshSubscriptionPrices();
        }
    }, [subscriptionPrices, boxId, isSharedAccess, refreshSubscriptionPrices])

    useEffect(() => {
        setIsLoading(true);
        if(!isSharedAccess && box && location && customerGunnebo && subscriptionPrices && subscriptionPrices[boxId]) {
            setIsLoading(false);
        } else if(isSharedAccess && box && location && customerGunnebo) {
            setIsLoading(false);
        }
    }, [box, location, customerGunnebo, subscriptionPrices, isSharedAccess, boxId, setIsLoading])

    useEffect(() => {

        // TODO: the values below appear as `null` when the page is firstly loaded right after subscription. Why?
        // Removing this check for now. The root cause for the above issue should be fixed before reintroducting the next line.
        // if(!customerSubscription || !visits) return;

        if (visits && visits?.length > 0) {
            setLastVisit(getDateStringFromTimestamp(visits[0].visitedAt.seconds * 1000, true));
        }

        if(insurance){
            if(insurance.status === InsuranceStatus.COMPLETED && !insurance.cancelling){
                setAmountInsured(insurance.amountInsured);
            } else {
                setAmountInsured(30000);
            }
        }

    }, [customerSubscription, visits, insurance]);

    const handleSharedConfigClick = useCallback(() => {
        history.push(userDashboardPaths.boxAccessConfiguration + '/' + boxId);
    }, [boxId, history]);

    const handleSharedViewClick = useCallback(() => {
        history.push(userDashboardPaths.configureSharedAccess + '/' + boxId);
    }, [boxId, history]);

    const handlePartnerViewClick = useCallback(() => {
        history.push(userDashboardPaths.mainCustomerInfo + '/' + boxId);
    }, [boxId, history]);

    const handleChipcardNotRegisteredClick = useCallback(() => {
        setDialogIsOpen(true);
    }, []);

    const handleChipcardNotRegisteredDialogClose = useCallback(e => {
        e.preventDefault();
        setDialogIsOpen(false);
    }, []);

    if(box && customerGunnebo && !gunnebo){
        let gunneboForLocation = customerGunnebo.locations.filter(el => el.id === box.location);
        setGunnebo(gunneboForLocation[0]);
    }

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div className="single-box-details-container">
            <div className="page-header">
                <IconButton
                    onClick={() => history.push(userDashboardPaths.boxesOverview)}
                    size="large">
                    <ArrowBackIcon/>
                </IconButton>

                <p className="box-name">{box.name || strings.GENERAL_BOX + " " + strings.GENERAL_NR + box.gunnebo?.id}
                    <span className="box-nr">/{strings["GENERAL_BOX_NR"]}{box.gunnebo?.id}</span></p>

                {
                    !box.contract.isOwner &&
                    <div className="box-settings-navigation"/>
                }

                {
                    box.contract.isOwner &&
                    <IconButton
                        onClick={() => history.push(userDashboardPaths.boxSettings + '/' + boxId, {box: box})}
                        size="large">
                        <SettingsIcon/>
                    </IconButton>

                }
            </div>

            <div className="padded-container">
                {box.cancelling && <NotificationForCancellation box={box}/>}

                {box.contract.status === LocationContractStatus.LOCKED && <div className="notification warning" style={{justifyContent:'center'}}>{strings['DISABLE_REASON_'+box.contract.locked.reason]}</div>}

                <div className="container-blue">
                    <div className="card-body">
                        <div className="image-container">
                            <img className="large-image" src={cardData.imgLargeSrc} alt={cardData.imgAlt}/>
                            <img className="small-image" src={cardData.imgSrc} alt={cardData.imgAlt}/>
                        </div>
                    </div>

                    <div className="shared-access">
                        {
                            box.contract.isOwner &&
                            (

                                hasOpenPartnerRequest()
                                    ? <ButtonBadge label={strings.BOX_LIST_VIEW_REQUEST_RECEIVED}
                                                   className="_white-text"
                                                   iconLeft={'user-icons/icn_user_add_light'}
                                                   onPress={handleSharedConfigClick}
                                    />
                                    : getLastConfirmedPartner() && lastConfirmedPartnerHasAccess()
                                        ? <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS}
                                                       className="_blue-text _background-white"
                                                       iconLeft={'user-icons/icn_users_dark'}
                                                       onPress={handleSharedViewClick}
                                        />
                                        : lastConfirmedPartnerIsLocked()
                                            ? <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS_BLOCKED}
                                                           className="_background-warning"
                                                           iconLeft={'icn_lock_dark'}
                                                           onPress={handleSharedViewClick}
                                            />
                                            : <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS_CONFIGURE}
                                                           className="_white-text"
                                                           iconLeft={'user-icons/icn_user_add_light'}
                                                           onPress={gunnebo?.activated?.card
                                                               ? handleSharedViewClick
                                                               : handleChipcardNotRegisteredClick}
                                            />
                            )
                        }

                        {
                            !box.contract.isOwner &&
                            (
                                box.partnerRequest.status === ContractPartnerStatus.ACCESSIBLE
                                    ? <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS}
                                                   className="_blue-text _background-white"
                                                   iconLeft={'user-icons/icn_users_dark'}
                                                   onPress={handlePartnerViewClick}
                                    />
                                    : <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS_BLOCKED}
                                                   className="_background-warning"
                                                   iconLeft={'icn_lock_dark'}
                                                   onPress={handlePartnerViewClick}
                                    />
                            )
                        }
                    </div>

                    <div className="box-info">
                        <div className="box-info-row">
                            <span>{strings["BOX_DETAIL_VIEW_LABEL_LOCATION"]}</span>
                            <span className="semi-bold">{location.address}</span>
                        </div>

                        <div className="box-info-row">
                            <span>{strings['BOX_DETAIL_VIEW_LABEL_SIZE']} </span>
                            <span className="semi-bold">{box.scale.width / 10}cm x {box.scale.height / 10}cm x {box.scale.deep / 10}cm</span>
                        </div>
                        {
                            !isSharedAccess &&
                            <>
                                <div className="box-info-row">
                                    <span>{strings["BOX_DETAIL_VIEW_LABEL_PLAN"]}</span>
                                    <span className="semi-bold-arrow" onClick={() => history.push(userDashboardPaths.boxContract + '/' + boxId)}>
                                            {strings['GENERAL_BOX_SIZE_' + box.size]} / {strings['GENERAL_INTERVAL_'+subscriptionPrices[box.id].interval.unit]}
                                        <ArrowForwardIosIcon/>
                                        </span>
                                </div>
                                <div className="box-info-row">
                                    <span>{strings["BOX_DETAIL_VIEW_LABEL_INSURANCE"]}</span>
                                    {amountInsured
                                        ?   <span className="semi-bold-arrow" onClick={gunnebo?.activated?.card
                                            ? () => history.push(userDashboardPaths.boxInsurance + '/' + boxId)
                                            : handleChipcardNotRegisteredClick}>
                                                <div className="valueContainer">
                                                    <div className="semi-bold">{amountInsured ? addThousandSeparator(amountInsured) +" "+ getFormattedCurrency('EUR') : strings['GENERAL_LOADING']}</div>
                                                </div>
                                                <ArrowForwardIosIcon/>
                                            </span>
                                        :   <span>
                                                <div className="valueContainer">
                                                        <div className="semi-bold">{strings['GENERAL_LOADING']}</div>
                                                </div>
                                            </span>
                                    }

                                </div>
                                <div className="box-info-row">
                                    <span>{strings["BOX_DETAIL_VIEW_LABEL_LAST_ACCESS"]}</span>
                                    {
                                        lastVisit &&
                                        <span className="semi-bold-arrow" onClick={() => history.push(userDashboardPaths.boxHistory + '/' + boxId)}>
                                            {lastVisit}
                                            <ArrowForwardIosIcon/>
                                        </span>
                                    }

                                    {
                                        !lastVisit &&
                                        <span className="semi-bold">Unbekannt</span>
                                    }
                                </div>
                            </>
                        }
                    </div>

                    <Dialog className="dialog-window"
                            open={dialogIsOpen}
                            onClose={handleChipcardNotRegisteredDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className="dialog-content">
                            <DialogTitle className="bold">{strings["GENERAL_CONFIRM_CHIPCARD_ALERT_TITLE"]}</DialogTitle>
                            <DialogContentText id="alert-dialog-description">
                                {strings["GENERAL_CONFIRM_CHIPCARD_ALERT_DESCRIPTION"]}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button className="default-button" onClick={handleChipcardNotRegisteredDialogClose}
                                    color="primary">{strings["GENERAL_OK"]}</Button>
                        </DialogActions>
                    </Dialog>

                    <div className="next-btn-container location-info-button">
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className="green-button"
                            onClick={() => history.push(userDashboardPaths.planYourVisit + '/' + boxId)}
                        >
                            {strings["BOX_DETAIL_BTN_PLAN_VISIT"]}
                            <img className="add-user-img"
                                 srcSet={`${process.env.PUBLIC_URL}/assets/icn_location.png, ${process.env.PUBLIC_URL}/assets/icn_location@2x.png 2x`}
                                 src={`${process.env.PUBLIC_URL}/assets/icn_location.png`} alt=""/>
                        </Button>
                    </div>
                </div>

                <div className='locked-secure-info'>
                    <div className='box-secure-description'>
                        <span>{strings["BOX_DETAIL_DESCRIPTION_SECURITY_FACTORS"]}</span>
                    </div>

                    <div className='box-secure-row'>
                        <div className='box-secure-text'>
                            <span className="box-secure-title">{strings["BOX_DETAIL_TITLE_CHIPCARD"]} </span>
                            <span>{strings["BOX_DETAIL_SUBTITLE_CHIPCARD"]}</span>
                        </div>

                        {
                            gunnebo?.activated?.card
                                ? <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_chipcard_active.png, ${process.env.PUBLIC_URL}/assets/icn_chipcard_active@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_chipcard_active.png`} alt=""/>
                                : <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_chipcard_inactive.png, ${process.env.PUBLIC_URL}/assets/icn_chipcard_inactive@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_chipcard_inactive.png`} alt=""/>
                        }
                    </div>

                    <div className='box-secure-row'>
                        <div className='box-secure-text'>
                            <span className="box-secure-title">{strings["BOX_DETAIL_TITLE_PIN"]}</span>
                            <span>{strings["BOX_DETAIL_SUBTITLE_PIN"]}</span>
                        </div>

                        {
                            gunnebo?.activated?.pin
                                ? <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_numpad_active.png, ${process.env.PUBLIC_URL}/assets/icn_numpad_active@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_numpad_active.png`} alt=""/>
                                : <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_numpad_inactive.png, ${process.env.PUBLIC_URL}/assets/icn_numpad_inactive@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_numpad_inactive.png`} alt=""/>
                        }
                    </div>

                    <div className='box-secure-row'>
                        <div className='box-secure-text'>
                            <span className="box-secure-title">{strings["BOX_DETAIL_TITLE_KEY"]}</span>
                            <span>{strings["BOX_DETAIL_SUBTITLE_KEY"]}</span>
                        </div>

                        {
                            isSharedAccess || box?.keyReceived
                                ? <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_key_active.png, ${process.env.PUBLIC_URL}/assets/icn_key_active@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_key_active.png`} alt=""/>
                                : <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_key_inactive.png, ${process.env.PUBLIC_URL}/assets/icn_key_inactive@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_key_inactive.png`} alt=""/>
                        }
                    </div>

                    <div className='box-secure-row'>
                        <div className='box-secure-text'>
                            <span className="box-secure-title">{strings["BOX_DETAIL_TITLE_BIOMETRY"]}</span>
                            <span>{strings["BOX_DETAIL_SUBTITLE_BIOMETRY"]}</span>
                        </div>

                        {
                            gunnebo?.activated?.bio
                                ? <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_fingerprint_active.png, ${process.env.PUBLIC_URL}/assets/icn_fingerprint_active@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_fingerprint_active.png`} alt=""/>
                                : <img className="box-secure-img"
                                       srcSet={`${process.env.PUBLIC_URL}/assets/icn_fingerprint_inactive.png, ${process.env.PUBLIC_URL}/assets/icn_fingerprint_inactive@2x.png 2x`}
                                       src={`${process.env.PUBLIC_URL}/assets/icn_fingerprint_inactive.png`} alt=""/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
