import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Sentry from "@sentry/browser";
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {groupDigits, removeDotsFromString} from "../services/Utils";
import {isNumbersOnly} from "../services/InputValidationService";
import FunctionsApiService from "services/FunctionsApiService";
import {getCurrentAuthUser, loginUser} from "../services/AuthenticationService";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";

import { PageHeaderLinearFlow } from "@asservato/shared.ui.page-header-linear-flow";
import { ProgressList } from "@asservato/shared.ui.progress-list";
import { TextInput } from "@asservato/shared.ui.text-input";
import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import { RadioButton } from "@asservato/shared.ui.radio-button";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";

import * as S from '../styles/sharedStyles';
import {colors} from '../styles/colors';
import * as F from "../styles/fontStyles";
import {listenForChangesInBoxesCollection} from "../services/FirestoreService";
import {checkoutPaths} from "navigation/CheckoutRoutes";
import {useAppState} from "contexts/AppStateContext";
import {CustomerAuthentication} from "constants/enums_customer";
import {useHistory} from "react-router-dom";
import {signupPaths} from "navigation/SignupRoutes";

let timeoutId;

export default function RegistrationView({ rootPath, prevPath, nextPath, index }) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, updateActiveStep, decrementActiveStep, setErrorMessage } = useCheckoutState();
    const { customer } = useAppState();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect( ()=> {

        updateFormData({heroImage:"../assets/checkout/hero_3.jpg"});
        updateActiveStep(index);

        let unsubscribe;

        try {
            unsubscribe = listenForChangesInBoxesCollection(getCurrentAuthUser().uid, (data) => {
                if(data && data.length > 0){
                    updateFormData({reservedBoxNr:data[0].gunnebo.id});
                }
            });
        } catch (e) {
            //console.log("listenForDocumentChanges failed with error: ", e);
        }

        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        if(rootPath === checkoutPaths.checkoutRoot){
            if(formData.activationSimulationStep === 5){
                if(customer.authentication !== CustomerAuthentication.AUTHENTICATED){
                    history.replace(rootPath + checkoutPaths.choosePostIdent);
                } else {
                    //updateActiveStep(8);
                    history.replace(rootPath + nextPath);
                }
            }
        }
    }, [formData.activationSimulationStep, customer?.authentication, history, rootPath, nextPath])

    useEffect(()=>{
        clearTimeout(timeoutId);

        if(formData.reservedBoxNr && formData.reservedBoxNr !== "-"){
            return;
        }

        timeoutId = setTimeout(()=>{
            updateFormData({reservedBoxNr:'-'});
        }, 30000);

    }, [formData.reservedBoxNr, updateFormData]);

    const onConfirm = useCallback(async () => {

        if(isLoading) return;

        setIsLoading(true);

        let customerPayload =   {
            title: formData.professionalTitle,
            salutation: formData.salutation,
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            billingAddress: formData.billingAddress,
            email: formData.email,
            mobilePhone: formData.phone,
            signup : {
                location:formData.selectedLocation,
                box:formData.selectedSize,
                signupSource:"WEB_SIGNUP"
            },
            company: formData.company,
        }

        if(formData.tracking) customerPayload.tracking = formData.tracking;

        if (formData.hasChipCard && formData.cardNumber) {

            try {
                await FunctionsApiService.customersValidateCard({number: removeDotsFromString(formData.cardNumber)})
                customerPayload.cardNumberPreCheckout = removeDotsFromString(formData.cardNumber);
            } catch (error) {

                setIsLoading(false);

                let message;

                switch (error?.details?.reason) {
                    case 'constraints_violation':
                        message = t('enterContactDetails.chipCard.error.constraints');
                        break;
                    case 'already_registered':
                        message = t('enterContactDetails.chipCard.error.alreadyRegistered');
                        break;
                    default:
                        message = t('error');
                }

                Sentry.captureException(error);
                setErrorMessage(message);
                return;
            }

        }

        try {
            await FunctionsApiService.customersPasswordUpdate({password:formData.password});
            await FunctionsApiService.customersUpdate(customerPayload); // update customer in case there's new utm data
            await loginUser(formData.email, formData.password); // login user with new credentials

            // only track events in signup
            if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.SIGN_UP);

            setIsLoading(false);
            history.push(rootPath + nextPath);
        } catch (error) {

            console.error("There was an error, updating the password: ", error);

            let message = t('error');

            const map = {
                'auth/weak-password': 'Das Passwort ist nicht sicher genug.',
            };

            if (map[error.code]) {
                message = map[error.code];
            }


            setIsLoading(false);
            setErrorMessage(message);
        }

    }, [history, rootPath, nextPath, isLoading, setIsLoading, setErrorMessage, formData.company, formData.email, formData.password, formData.cardNumber, formData.address, formData.billingAddress, formData.firstName, formData.lastName, formData.professionalTitle, formData.salutation, formData.hasChipCard, formData.selectedLocation, formData.selectedSize, formData.phone, formData.tracking, t]);


    const isPasswordValid = formData.repeatPassword.length >= 6 && formData.repeatPassword === formData.password;
    let isCardInputvalid = !formData.hasChipCard;

    if(formData.hasChipCard){
        let cardNumber = removeDotsFromString(formData.cardNumber);
        if (cardNumber.length === 9 && isNumbersOnly(cardNumber)) {
            isCardInputvalid = true;
        }
    }

    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={decrementActiveStep}
                progressBrackets={progressBrackets}
                showBackButton={false}
                hideProgressBar={true}
            />
            <S.Content>
                <ProgressList formData={formData} updateFormData={updateFormData} t={t}/>

                {formData.reservedBoxNr && formData.activationSimulationStep === 5
                    ?   <>
                            <S.Description>
                                {t('register.description')}
                            </S.Description>

                            <EmailContainer>
                                <EmailLabel>{t('register.email.label')}</EmailLabel><br/>
                                <Email>{formData.email}</Email>
                            </EmailContainer>

                            <TextInput label={t('register.password.placeholder')}
                                       disabled={isLoading}
                                       error={false}
                                       onChange={(e)=>updateFormData({password:e.target.value})}
                                       value={formData.password}
                                       isValid={formData.password?.length >= 6}
                                       name={'password'}
                                       margin={'24px 0 0 0'}
                                       type={'password'}
                            />

                            <TextInput label={t('register.repeatPassword.placeholder')}
                                       disabled={isLoading}
                                       error={formData.repeatPassword.length >= 6 && formData.repeatPassword !== formData.password}
                                       onChange={(e)=>updateFormData({repeatPassword:e.target.value})}
                                       value={formData.repeatPassword}
                                       isValid={formData.repeatPassword.length >= 6 && formData.repeatPassword === formData.password}
                                       name={'password'}
                                       margin={'16px 0 0 0'}
                                       type={'password'}
                            />

                            {formData.repeatPassword.length >= 6 && formData.repeatPassword !== formData.password
                                ? <WarningMessage>{t('register.repeatPassword.error.mismatch')}</WarningMessage>
                                : null
                            }

                            <ChipcardDescription>
                                {t('register.chipCard.label')}
                            </ChipcardDescription>

                            <RadioButtonContainer>
                                <RadioButton value={true} onClick={() => updateFormData({hasChipCard: true})} isActive={formData.hasChipCard} isDisabled={isLoading}/>
                                <RadioLabel>{t('yes')}</RadioLabel>
                                <RadioButton value={false} onClick={() => updateFormData({hasChipCard: false})} isActive={!formData.hasChipCard} isDisabled={isLoading}/>
                                <RadioLabel>{t('no')}</RadioLabel>
                            </RadioButtonContainer>

                            {formData.hasChipCard
                                ?   <TextInput label={t('register.chipCard.placeholder')}
                                               disabled={isLoading}
                                               error={false}
                                               onChange={(e)=>updateFormData({cardNumber:{value:e.target.value}})}
                                               value={groupDigits(formData.cardNumber, 3, ".")}
                                               isValid={isCardInputvalid}
                                               margin={'16px 0 0 0'}
                                               maxLength={11}
                                />
                                :   null
                            }

                            {!isLoading
                                ? <ButtonPrimary label={t('register.cta')} marginTop={32} onClick={onConfirm} isDisabled={!isPasswordValid ||!isCardInputvalid}/>
                                : <LoadingIndicator marginTop={24}/>
                            }
                        </>
                    :   null
                }
            </S.Content>
        </S.View>
    )
}

const EmailContainer = styled.div`
    background: ${colors.primary.primary_500};
    padding: 8px 16px;
`;

const EmailLabel = styled(F.Body)`
    color: ${colors.primary.primary_100};
`;

const Email = styled(F.Title)`
    color: ${colors.primary.primary_100};
`;

const WarningMessage = styled(F.LabelRegularSmall)`
    color: ${colors.semantic.warning};
    display: block;
    margin-top: 8px;
`;

const ChipcardDescription = styled(F.Body)`
    color: ${colors.primary.primary_100};
    display: inline-block;
    margin-top: 32px;
`;

const RadioButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px
`;

const RadioLabel = styled(F.LabelSemiBold)`
    color: ${colors.primary.primary_100};
    display: inline-block;
    margin: 0 24px 0 16px;
`;
