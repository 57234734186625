import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export default function LoadingIndicator({marginTop, padding, size}){
    return (
        <ProgressContainer marginTop={marginTop} padding={padding} height={size}>
            <CircularProgress color={"primary"} size={size || 32} thickness={4}/>
        </ProgressContainer>
    )
}

const ProgressContainer = styled.div`
    text-align: center;
    margin-top: ${props => props.marginTop ? props.marginTop + 'px' : null};
    padding: ${props => props.padding || null};
    height: ${props=> props.height ? props.height + 'px' : null};
`;
