export const EVENTS = {
    WEB_SIGNUP_STARTED: 'web_signup_started',
    PRIVACY_POLICY_VIEWED: 'privacy_policy_viewed',
    TERMS_VIEWED: 'terms_viewed',
    BRANCH_SELECTED: 'branch_selected',
    BOX_SIZE_SELECTED: 'box_size_selected',
    PLAN_SELECTED: 'plan_selected',
    CONTACT_DATA_ENTERED: 'contact_data_entered',
    ADDRESS_DATA_ENTERED: 'address_data_entered',
    COUPON_CODE_ENTERED: 'coupon_code_entered',
    //PURCHASE_SUMMARY_CONFIRMED: 'purchase_summary_confirmed',
    PAYMENT_METHOD_SELECTED: 'payment_method_selected',
    PURCHASE_CONFIRMED: 'purchase_confirmed',
    SUCCESS_VIEW_CONFIRMED: 'success_view_confirmed',
    SIGN_UP: 'sign_up',
    POSTIDENT_METHOD_SELECTED: 'postident_method_selected',
    POSTIDENT_LINK_OPENED: 'postident_link_opened',
    THREEDS_VALIDATION_FAILED: '3ds_validation_failed',
};

export default function trackEvent(event, category, action) {
    if (window.dryRun) {
        return;
    }

    try {
        const dataLayer = window.parent.dataLayer || window.dataLayer;
        if (dataLayer && typeof dataLayer.push === 'function') {
            const data = {'event': event};
            if (category !== undefined && action !== undefined) {
                data[category] = action;
            }
            dataLayer.push(data);
        }
    } catch (e) {
        console.error(e);
    }
}
