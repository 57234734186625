import {useState, useEffect, useRef} from "react";
import {FirestoreApiService} from "services/FirestoreApiService";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import FunctionsApiService from "services/FunctionsApiService";

export default function useCustomerPartnerBoxes(uid) {

    const [boxAccessInfo, setBoxAccessInfo] = useState();
    const [boxDetailInfo, setBoxDetailInfo] = useState();

    const [customerPartnerBoxes, setCustomerPartnerBoxes] = useState();

    let unsubscribeCustomerPartnerBoxes = useRef(null);
    let unsubscribeContractRequests = useRef([]);

    useEffect(()=> {

        if(!uid) {
            if(unsubscribeCustomerPartnerBoxes.current) {
                unsubscribeCustomerPartnerBoxes.current();
                unsubscribeCustomerPartnerBoxes.current = null;
            }
            return;
        }

        unsubscribeCustomerPartnerBoxes.current = FirestoreApiService.getBoxesSharedWithCustomerUpdates(uid, boxAccessInfo => {
            const activePartnerBoxes = boxAccessInfo.filter( el => {
                return (el.status !== ContractPartnerStatus.REMOVED);
            });

            if(activePartnerBoxes?.length > 0) {
                setBoxAccessInfo(activePartnerBoxes)
            } else {
                setCustomerPartnerBoxes([]);
            }
        }, error => {
            console.log("Error: ", error);
        })

        return () => {
            if(unsubscribeCustomerPartnerBoxes.current) {
                unsubscribeCustomerPartnerBoxes.current();
                unsubscribeCustomerPartnerBoxes.current = null;
            }
        }

    }, [uid]);

    useEffect(() => {
        if(!boxAccessInfo) {
            return;
        }

        let dataCount = 0;
        let boxDetailInfos = [];

        boxAccessInfo.forEach((el, i) => {
            FunctionsApiService.getBoxDetails(el.customerId, el.id, el.code)
              .then( res => {
                boxDetailInfos.push(res.data);

                dataCount++;
                if(dataCount === boxAccessInfo.length) {
                  setBoxDetailInfo(boxDetailInfos);
                }

              }).catch( error => {});
        });

    }, [boxAccessInfo])

    useEffect(() => {
        if(!boxDetailInfo) {
            if(unsubscribeContractRequests.current.length > 0) {
                unsubscribeContractRequests.current.forEach(unsubscribe => unsubscribe());
                unsubscribeContractRequests.current = [];
            }
            return;
        }

        let dataCount = 0;

        let completePartnerBoxData = boxDetailInfo.slice();

        boxDetailInfo.forEach((el, i) => {
            const unsubscribeContractRequest = FirestoreApiService.getCustomerGunneboContractUpdates(uid, el.location, el.id, (contract) => {
                completePartnerBoxData[i] = {
                    ...completePartnerBoxData[i],
                    contract: contract.hasOwnProperty('status') ? contract : null,
                    partnerRequest: boxAccessInfo[i]
                }

                dataCount++;
                if(dataCount === boxAccessInfo.length) {
                    setCustomerPartnerBoxes(completePartnerBoxData);
                }
            }, error => {});

            unsubscribeContractRequests.current.push(unsubscribeContractRequest);
        })

        return () => { if(unsubscribeContractRequests.current.length > 0) unsubscribeContractRequests.current.forEach(unsubscribe => unsubscribe()) }

    }, [boxDetailInfo, boxAccessInfo, uid])

    const resetCustomerPartnerBoxesData = () => {
        setBoxAccessInfo(null);
        setBoxDetailInfo(null);
        setCustomerPartnerBoxes(null);
    }

    return {customerPartnerBoxes, resetCustomerPartnerBoxesData}
}
