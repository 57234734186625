import { useEffect, useState } from 'react';
import {listenForAuthStateChanges} from "../services/AuthenticationService";

export default function useAuthUser() {

    const [authUser, setAuthUser] = useState();

    useEffect(() => {

        const unsubscribe = listenForAuthStateChanges(async (user) => {
            //console.log("Got user in auth listener", user);
            setAuthUser(user);
        })

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {authUser};
}
