
export const formDataDefaults = {
  dryRun: false,
  email: '',
  temporaryPassword: '',
  password: '',
  repeatPassword: '',
  checkPrivacyPolicy: false,
  checkTermsConditions: false,
  selectedLocation: 'BER',
  selectedSize: null,
  selectedBox: null,
  selectedPlan: null,
  lockerType: '',
  company:'',
  salutation: '',
  professionalTitle: '',
  firstName: '',
  lastName: '',
  phone: '',
  address: {
      appendix: null,
      street: '',
      streetNo: '',
      zip: '',
      city: '',
      country: 'DE',
  },
  billingAddress: {
      appendix: null,
      street: '',
      streetNo: '',
      zip: '',
      city: '',
      country: 'DE',
  },
  couponCode: null,
  cardNumber: '',
  hasChipCard: false,
  activationSimulationStep: 0,
  reservedBoxNr: null,
  couponCodeDiscount: 0,
  grandTotal: 0,
  braintreeRequestable: false,
  braintreeType: '',
  braintreeEmail: '',
  braintreeCardType: '',
  braintreeCardLastTwo: '',
  braintreeCardLastFour: '',
  braintreeNonce: '',
  boxCheckComplete: false,
  postIdentVerificationStatus: '',
  postIdentVerificationType: '',
  postIdentTransactionNumber: '',
  postIdentRedirectUrlWeb: '',
  tracking: null,
  signup: null,
  heroImage:'',
}
