import {Button, FormControl, FormHelperText, InputAdornment, OutlinedInput} from "@mui/material";
import React, {useEffect, useState} from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useAppState } from "contexts/AppStateContext";
import {useSnackbar} from "notistack";
import {from} from "rxjs";
import {userDataTemplate} from "./AddressView";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {formatString} from "utils/mobile-app-helpers";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FunctionsApiService from "services/FunctionsApiService";
import {FirestoreApiService} from "services/FirestoreApiService";

export default function TelephoneView() {
    const [data, setData] = useState(userDataTemplate);
    const {strings, setIsLoading, goBack, authUser} = useAppState();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const unsubscribe = FirestoreApiService.getCustomerUpdates(authUser.uid, data => {
            setData(data);
        }, error => {

        })

        return () => unsubscribe();
    }, [authUser.uid]);

    return (
        <div className="user-form-container">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {formatString(strings["EDIT_CONTACT_DETAIL_VIEW_TITLE"], {detail: strings["EDIT_CONTACT_DETAIL_VIEW_LABEL_PHONE"]})}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <FormControl variant="outlined" className="user-data-input-container phone-form">
                    <FormHelperText className="input-label" id="telephone-input-label">{strings["EDIT_CONTACT_DETAIL_VIEW_LABEL_PHONE"]}</FormHelperText>
                    <OutlinedInput
                        className={data.mobilePhone ? "underlined-input" : "disabled-input"}
                        id="telephone-input"
                        placeholder="Telefon"
                        value={data.mobilePhone}
                        onChange={(e) => setData({...data, mobilePhone: e.target.value})}
                        aria-describedby="telephone-input-label"
                        labelWidth={0}
                        endAdornment={data.mobilePhone ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <Button variant='contained' className="blue-button user-data-button" onClick={() => {
                    setIsLoading(true);
                    from(FunctionsApiService.customersUpdate(data)).subscribe(
                        {
                            next: () => {
                                setIsLoading(false);
                                enqueueSnackbar(strings["EDIT_ADDRESS_VIEW_NOTIFICATION_DATA_SAVED"], {variant: 'success'})
                            },
                            error: (err) => {
                                setIsLoading(false);
                                console.error('error ', err)
                                enqueueSnackbar(strings['ERROR/general'], {
                                    variant: 'error',
                                });
                            }
                        }
                    )
                }}>{strings["GENERAL_SAVE"]}</Button>
            </div>
        </div>
    );
}
