import {useCallback, useState} from 'react';
import {cardData} from "constants/card-data";
import {userDashboardPaths} from "constants/paths";
import {useHistory} from "react-router-dom";
import { useAppState } from "contexts/AppStateContext";
import ButtonBadge from "../../../components/ButtonBadge";
import useBoxPartnerRequests from "hooks/useBoxPartnerRequests";
import usePartners from "hooks/usePartners";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import {LocationContractStatus} from "constants/enums_location_contract";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import FunctionsApiService from "services/FunctionsApiService";

export default function BoxCard({box, locations, customer, customerRegistrationIsComplete}) {
    const context = useAppState();
    const {strings} = context;
    const history = useHistory();
    const location = locations.find(location => location.token === box.location);
    const {hasOpenPartnerRequest} = useBoxPartnerRequests(box.requests);
    const {getLastConfirmedPartner, lastConfirmedPartnerHasAccess, lastConfirmedPartnerIsLocked} = usePartners(box.partners);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleSharedBoxClick = useCallback(() => {
        if(customerRegistrationIsComplete) {
            if(box.partnerRequest.status === ContractPartnerStatus.CANCELLED){
                setDialogIsOpen(true);
            } else {
                history.push(userDashboardPaths.boxDetails + '/' + box.id);
            }
        } else {
            history.push(userDashboardPaths.singleBoxRequestStatus + '/' + box.id);
        }
    }, [box, history, customerRegistrationIsComplete]);

    const handleAccessCancelledDialogClose = useCallback(e => {
        e.preventDefault();
        setDialogIsOpen(false);
    }, []);

    const handleAccessCancelledDialogConfirm = useCallback(() => {
        setDialogIsOpen(false);
        setIsHidden(true)

        FunctionsApiService.removePartnerAccess({
            boxId:box.id,
            ownerId: box.contract.refs.ownerId,
        }).catch( error => {
            console.log("Error removePartnerAccess: ", error);
        })
    }, [box.contract?.refs?.ownerId, box.id]);

    let statusString = "";
    if(box.contract.status === LocationContractStatus.LOCKED){
        statusString = strings['GENERAL_BOX_LOCKED'];
    }
    if (box.cancelling) {
        if(box.contract.status === LocationContractStatus.LOCKED) statusString += " & ";
        statusString += strings['GENERAL_BOX_CANCELLED'];
    }

    if(!customer || isHidden) return null;

    if(box.contract?.isOwner) {
        return (
            <div className="box-row" onClick={() => history.push(userDashboardPaths.boxDetails + '/' + box.id)}>
                <div className="box-details">
                    <img src={cardData[box.size.toLowerCase()].imgLargeSrc} alt={box.name} className="box-image"/>
                    <div className="box-info">
                        <div>
                            <p>{box.name || strings.GENERAL_BOX + " " + strings.GENERAL_NR + box.gunnebo.id}</p>
                            <p>{location.address}</p>
                            <p>{strings["GENERAL_BOX_SIZE_" + box.size]}</p>
                        </div>

                        {box.contract.status === LocationContractStatus.LOCKED || box.cancelling
                            ?   <div className="_cancelled">{statusString}</div>
                            :   <div className="shared-access">
                                {box.requests
                                    ? hasOpenPartnerRequest()
                                        ? <ButtonBadge label={strings.BOX_LIST_VIEW_REQUEST_RECEIVED}
                                                       className={'_blue-text'}
                                                       iconLeft={'user-icons/icn_user_add_dark'}/>
                                        : getLastConfirmedPartner() && lastConfirmedPartnerHasAccess()
                                            ? <ButtonBadge label={strings.BOX_LIST_VIEW_SHARED_ACCESS}
                                                           className={'_blue-text'}
                                                           iconLeft={'user-icons/icn_users_dark'}/>
                                            : lastConfirmedPartnerIsLocked()
                                                ? <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS_BLOCKED}
                                                               className={'_background-warning'}
                                                               iconLeft={'icn_lock_dark'}/>
                                                : null
                                    : null
                                }
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else if(box.partnerRequest){
        return (
            <>
                <div className="box-row" onClick={handleSharedBoxClick}>
                    <div className="box-details">
                        <img src={cardData[box.size.toLowerCase()].imgSrc} alt={box.name} className="box-image"/>
                        <div className="box-info">
                            <div>
                                <p>{box.name || strings.GENERAL_BOX + " " + strings.GENERAL_NR + box.gunnebo.id}</p>
                                <p>{location.address}</p>
                                <p>{strings["GENERAL_BOX_SIZE_" + box.size]}</p>
                            </div>

                            <div className="shared-access">
                                {
                                    customerRegistrationIsComplete
                                        ? box.partnerRequest.status === ContractPartnerStatus.ACCESSIBLE
                                            ? <ButtonBadge label={strings.BOX_LIST_VIEW_SHARED_ACCESS}
                                                           className="_blue-text"
                                                           iconLeft={'user-icons/icn_users_dark'}/>
                                            : box.partnerRequest.status === ContractPartnerStatus.LOCKED
                                                ?   <ButtonBadge label={strings.BOX_DETAIL_BTN_SHARED_ACCESS_BLOCKED}
                                                           className="_background-warning"
                                                           iconLeft={'icn_lock_dark'}/>
                                                :   box.partnerRequest.status === ContractPartnerStatus.CANCELLED
                                                    ?   <ButtonBadge label={strings.BOX_LIST_VIEW_REQUEST_CANCELLED}
                                                                 className="_background-warning"/>
                                                    :   null
                                        : <ButtonBadge label={strings.BOX_LIST_VIEW_REQUEST_ACCEPTED}
                                                       className="_background-highlight"/>
                                }
                            </div>

                            {/*lockedStatus.find(el => el === box.status) || box.cancelling
                                ? <div className="_cancelled">{statusString}</div>
                                : null
                            */}
                        </div>
                    </div>
                </div>

                <Dialog className="dialog-window"
                        open={dialogIsOpen}
                        onClose={handleAccessCancelledDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <DialogContent className="dialog-content">
                        <DialogTitle className="bold">{strings["BOX_LIST_VIEW_REQUEST_CANCELLED"]}</DialogTitle>
                        <DialogContentText id="alert-dialog-description">
                            {strings["BOX_LIST_VIEW_REQUEST_CANCELLED_DIALOG"]}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button className="default-button" onClick={handleAccessCancelledDialogConfirm}
                                color="primary">{strings["GENERAL_OK"]}</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    return <></>;
}
