import { Button, FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { from } from "rxjs";
import { useSnackbar } from "notistack";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import {useAppState} from "contexts/AppStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function ChangePinView() {
    const { strings, setIsLoading } = useAppState();
    const [newPin, setNewPin] = useState('');
    const [repeatPin, setRepeatPin] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div className="user-form-container change-pin">
            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                        srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                        src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                        alt="Menu Icon"
                    />
                </Button>

                {strings["CHANGE_PIN_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon /></Button>
            </div>

            <div className="padded-container">
                <p className="description">{strings["CHANGE_PIN_VIEW_DESCRIPTION"]}</p>
                <div className="title">{strings["CHANGE_PIN_VIEW_HEADLINE_PIN"]}</div>
                <FormControl variant="outlined" className="user-data-input-container">
                    <FormHelperText className="input-label">{strings["CHANGE_PIN_VIEW_LABEL_NEW_PIN"]}</FormHelperText>
                    <OutlinedInput
                        type="password"
                        className="disabled-input"
                        id="new-pin-input"
                        placeholder={strings["CHANGE_PIN_VIEW_PLACEHOLDER_PIN"]}
                        value={newPin}
                        onChange={(e) => { if (e.target.value.length <= 6) setNewPin(e.target.value) }}
                        aria-describedby="new-pin-input-label"
                        labelWidth={0}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container">
                    <FormHelperText className="input-label">{strings["CHANGE_PIN_VIEW_LABEL_PIN_REPEAT"]}</FormHelperText>
                    <OutlinedInput
                        type="password"
                        className="disabled-input"
                        id="repeated-pin-input"
                        placeholder={strings["CHANGE_PIN_VIEW_PLACEHOLDER_PIN_REPEAT"]}
                        value={repeatPin}
                        onChange={(e) => { if (e.target.value.length <= 6) setRepeatPin(e.target.value) }}
                        aria-describedby="repeated-pin-input-label"
                        labelWidth={0}
                    />
                </FormControl>

                <div className="alert-message">
                    <ErrorOutlinedIcon />
                    <p>{strings["CHANGE_PIN_VIEW_HINTBOX"]}</p>
                </div>

                <Button variant='contained'
                    disabled={!newPin || !repeatPin || newPin.length < 6}
                    className="blue-button user-data-button"
                    onClick={() => {
                        if (newPin !== repeatPin) {
                            enqueueSnackbar(strings["CHANGE_PIN_VIEW_PIN_MISMATCH"], { variant: 'error' });
                            return;
                        }

                        setIsLoading(true);
                        from(FunctionsApiService.changePin({
                            pin: newPin,
                        })).subscribe({
                            next: result => {
                                setIsLoading(false);
                                setNewPin('')
                                setRepeatPin('')
                                enqueueSnackbar(strings["CHANGE_PIN_VIEW_NOTIFICATION_SUCCESS"], { variant: 'success' });
                            },
                            error: error => {
                                setIsLoading(false);
                                enqueueSnackbar(strings['ERROR/general'], {
                                    variant: 'error',
                                });
                            }
                        });
                    }}
                >
                    {strings["CHANGE_PIN_VIEW_BTN_SAVE_CHANGES"]}
                </Button>
            </div>
        </div>
    )
}
