import styled from 'styled-components';
import { Checkbox } from "@mui/material";
import {colors} from '../styles/colors';

export default function CheckBox({checked, onChange, disabled, size}){

    return (
        <StyledCheckBox checked={checked}
                        disabled={disabled}
                        size={size}
                        onChange={onChange ? (e) => onChange(e) : null}
        />
    )
}

const StyledCheckBox = styled(Checkbox)`
    color: ${colors.secondary.secondary_100} !important;
`;
