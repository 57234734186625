import React, { useCallback, useContext, useState, useEffect } from "react";
import useFormData from "../hooks/useFormData";
import {storageAvailable} from "../services/Utils";
import useAuthUser from "../hooks/useAuthUser";
import useCustomer from "../hooks/useCustomer";
import useLocations from "hooks/useLocations";

export const CheckoutStateContext = React.createContext(null);

const activeStepStorageKey = "@asvtactiveStep";

export const CheckoutStateProvider = ({ brackets, children }) => {

    const savedActiveStepStr = storageAvailable('localStorage') ? localStorage.getItem(activeStepStorageKey) : null;
    const savedActiveStep = parseInt(savedActiveStepStr) > 0 ? parseInt(savedActiveStepStr) : 1;

    // because we do not want to save passwords in local storage
    // checkout complete step is as far as we can load
    // practically: the auth listener in App.js will redirect
    // past this point if the user has made a checkout or has an email
    const initialActiveStep = Math.min(savedActiveStep, 8)

    // State
    const [activeStep, setActiveStep] = useState(initialActiveStep)
    const [maxActiveStep, setMaxActiveStep] = useState(initialActiveStep);
    const [totalNumSteps, setTotalNumSteps] = useState(5);
    const [progressBrackets] = useState(brackets);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const { formData, updateFormData, handleRadioChange, handleCheckboxClick, resetFormData } = useFormData();
    const { authUser } = useAuthUser();
    const { customer, checkouts, postIdent} = useCustomer(authUser?.uid);
    const { locations } = useLocations();

    const [isLoading, setIsLoading] = useState(false);
    /// Blank error message hides toast.
    const [errorMessage, setErrorMessage] = useState('');

    // Custom State Modifiers
    const updateActiveStep = useCallback((step) => {
        setActiveStep(step);
        if(step > maxActiveStep) {
            setMaxActiveStep(step);
        }
        if(storageAvailable('localStorage')) localStorage.setItem(activeStepStorageKey, step);
    }, [maxActiveStep])

    const incrementActiveStep = useCallback(() => {
        updateActiveStep(activeStep + 1);
    }, [activeStep, updateActiveStep])

    const decrementActiveStep = useCallback(() => {
        updateActiveStep(activeStep - 1);
    }, [activeStep, updateActiveStep])

    const resetCheckoutData = useCallback(() => {
        resetFormData();
        if(storageAvailable('localStorage')) localStorage.setItem(activeStepStorageKey, '1');
    }, [resetFormData]);

    // transfer temporaryPassword from customer to formData
    useEffect(()=>{
        if(customer?.temporaryPassword) {
            updateFormData({temporaryPassword:customer?.temporaryPassword});
        }
    }, [customer, updateFormData]);

    return (
        <CheckoutStateContext.Provider value={{
            activeStep,
            maxActiveStep,
            updateActiveStep,
            incrementActiveStep,
            decrementActiveStep,
            totalNumSteps,
            setTotalNumSteps,
            progressBrackets,
            isDialogVisible,
            setIsDialogVisible,
            formData,
            updateFormData,
            isLoading,
            setIsLoading,
            errorMessage,
            setErrorMessage,
            handleRadioChange,
            handleCheckboxClick,
            authUser,
            customer,
            checkouts,
            postIdent,
            locations,
            resetCheckoutData
        }}>
            {children}
        </CheckoutStateContext.Provider>
    )
}

export const useCheckoutState = () => useContext(CheckoutStateContext);
