export const countries = {
    "DE": { de: "Deutschland" },
    "AT": { de: "Österreich" },
    "CH": { de: "Schweiz" },
    "--": { de: "------" },
    "AF": { de: "Afghanistan" },
    "EG": { de: "Ägypten" },
    "AX": { de: "Åland" },
    "AL": { de: "Albanien" },
    "DZ": { de: "Algerien" },
    "AS": { de: "Amerikanisch-Samoa" },
    "VI": { de: "Amerikanische Jungferninseln" },
    "UM": { de: "Amerikanische Überseeinseln" },
    "AD": { de: "Andorra" },
    "AO": { de: "Angola" },
    "AI": { de: "Anguilla" },
    "AQ": { de: "Antarktis" },
    "AG": { de: "Antigua und Barbuda" },
    "GQ": { de: "Äquatorialguinea" },
    "AR": { de: "Argentinien" },
    "AM": { de: "Armenien" },
    "AW": { de: "Aruba" },
    "AZ": { de: "Aserbaidschan" },
    "ET": { de: "Äthiopien" },
    "AU": { de: "Australien" },
    "BS": { de: "Bahamas" },
    "BH": { de: "Bahrain" },
    "BD": { de: "Bangladesch" },
    "BB": { de: "Barbados" },
    "BY": { de: "Belarus" },
    "BE": { de: "Belgien" },
    "BZ": { de: "Belize" },
    "BJ": { de: "Benin" },
    "BM": { de: "Bermuda" },
    "BT": { de: "Bhutan" },
    "BO": { de: "Bolivien" },
    "BQ": { de: "Bonaire, Sint Eustatius und Saba" },
    "BA": { de: "Bosnien und Herzegowina" },
    "BW": { de: "Botswana" },
    "BV": { de: "Bouvetinsel" },
    "BR": { de: "Brasilien" },
    "VG": { de: "Britische Jungferninseln" },
    "IO": { de: "Britisches Territorium im Indischen Ozean" },
    "BN": { de: "Brunei" },
    "BG": { de: "Bulgarien" },
    "BF": { de: "Burkina Faso" },
    "BI": { de: "Burundi" },
    "CV": { de: "Kap Verde" },
    "CL": { de: "Chile" },
    "CN": { de: "China" },
    "CK": { de: "Cookinseln" },
    "CR": { de: "Costa Rica" },
    "CI": { de: "Côte d'Ivoire" },
    "CW": { de: "Curaçao" },
    "DK": { de: "Dänemark" },
    "DM": { de: "Dominica" },
    "DO": { de: "Dominikanische Republik" },
    "DJ": { de: "Dschibuti" },
    "EC": { de: "Ecuador" },
    "SV": { de: "El Salvador" },
    "ER": { de: "Eritrea" },
    "EE": { de: "Estland" },
    "SZ": { de: "Swasiland" },
    "FK": { de: "Falklandinseln" },
    "FO": { de: "Färöer" },
    "FJ": { de: "Fidschi" },
    "FI": { de: "Finnland" },
    "FR": { de: "Frankreich" },
    "GF": { de: "Französisch-Guayana" },
    "PF": { de: "Französisch-Polynesien" },
    "TF": { de: "Französische Süd- und Antarktisgebiete" },
    "GA": { de: "Gabun" },
    "GM": { de: "Gambia" },
    "GE": { de: "Georgien" },
    "GH": { de: "Ghana" },
    "GI": { de: "Gibraltar" },
    "GD": { de: "Grenada" },
    "GR": { de: "Griechenland" },
    "GL": { de: "Grönland" },
    "GP": { de: "Guadeloupe" },
    "GU": { de: "Guam" },
    "GT": { de: "Guatemala" },
    "GG": { de: "Guernsey" },
    "GN": { de: "Guinea" },
    "GW": { de: "Guinea-Bissau" },
    "GY": { de: "Guyana" },
    "HT": { de: "Haiti" },
    "HM": { de: "Heard und McDonaldinseln" },
    "HN": { de: "Honduras" },
    "IN": { de: "Indien" },
    "ID": { de: "Indonesien" },
    "IQ": { de: "Irak" },
    "IR": { de: "Iran" },
    "IE": { de: "Irland" },
    "IS": { de: "Island" },
    "IL": { de: "Israel" },
    "IT": { de: "Italien" },
    "JM": { de: "Jamaika" },
    "JP": { de: "Japan" },
    "YE": { de: "Jemen" },
    "JE": { de: "Jersey" },
    "JO": { de: "Jordanien" },
    "KY": { de: "Kaimaninseln" },
    "KH": { de: "Kambodscha" },
    "CM": { de: "Kamerun" },
    "CA": { de: "Kanada" },
    "KZ": { de: "Kasachstan" },
    "QA": { de: "Katar" },
    "KE": { de: "Kenia" },
    "KG": { de: "Kirgisistan" },
    "KI": { de: "Kiribati" },
    "CC": { de: "Kokosinseln" },
    "CO": { de: "Kolumbien" },
    "KM": { de: "Komoren" },
    "CG": { de: "Kongo-Brazzaville" },
    "CD": { de: "Kongo-Kinshasa" },
    "HR": { de: "Kroatien" },
    "CU": { de: "Kuba" },
    "KW": { de: "Kuwait" },
    "LA": { de: "Laos" },
    "LS": { de: "Lesotho" },
    "LV": { de: "Lettland" },
    "LB": { de: "Libanon" },
    "LR": { de: "Liberia" },
    "LY": { de: "Libyen" },
    "LI": { de: "Liechtenstein" },
    "LT": { de: "Litauen" },
    "LU": { de: "Luxemburg" },
    "MG": { de: "Madagaskar" },
    "MW": { de: "Malawi" },
    "MY": { de: "Malaysia" },
    "MV": { de: "Malediven" },
    "ML": { de: "Mali" },
    "MT": { de: "Malta" },
    "MA": { de: "Marokko" },
    "MH": { de: "Marshallinseln" },
    "MQ": { de: "Martinique" },
    "MR": { de: "Mauretanien" },
    "MU": { de: "Mauritius" },
    "YT": { de: "Mayotte" },
    "MX": { de: "Mexiko" },
    "FM": { de: "Mikronesien" },
    "MC": { de: "Monaco" },
    "MN": { de: "Mongolei" },
    "ME": { de: "Montenegro" },
    "MS": { de: "Montserrat" },
    "MZ": { de: "Mosambik" },
    "MM": { de: "Myanmar" },
    "NA": { de: "Namibia" },
    "NR": { de: "Nauru" },
    "NP": { de: "Nepal" },
    "NC": { de: "Neukaledonien" },
    "NZ": { de: "Neuseeland" },
    "NI": { de: "Nicaragua" },
    "NL": { de: "Niederlande" },
    "NE": { de: "Niger" },
    "NG": { de: "Nigeria" },
    "NU": { de: "Niue" },
    "KP": { de: "Nordkorea" },
    "MP": { de: "N\u00f6rdliche Marianen" },
    "MK": { de: "Nordmazedonien" },
    "NF": { de: "Norfolkinsel" },
    "NO": { de: "Norwegen" },
    "OM": { de: "Oman" },
    "PK": { de: "Pakistan" },
    "PS": { de: "Pal\u00e4stinensische Autonomiegebiete" },
    "PW": { de: "Palau" },
    "PA": { de: "Panama" },
    "PG": { de: "Papua-Neuguinea" },
    "PY": { de: "Paraguay" },
    "PE": { de: "Peru" },
    "PH": { de: "Philippinen" },
    "PN": { de: "Pitcairninseln" },
    "PL": { de: "Polen" },
    "PT": { de: "Portugal" },
    "PR": { de: "Puerto Rico" },
    "MD": { de: "Republik Moldau" },
    "RE": { de: "R\u00e9union" },
    "RW": { de: "Ruanda" },
    "RO": { de: "Rum\u00e4nien" },
    "RU": { de: "Russland" },
    "SB": { de: "Salomonen" },
    "ZM": { de: "Sambia" },
    "WS": { de: "Samoa" },
    "SM": { de: "San Marino" },
    "ST": { de: "S\u00e3o Tom\u00e9 und Pr\u00edncipe" },
    "SA": { de: "Saudi-Arabien" },
    "SE": { de: "Schweden" },
    "SN": { de: "Senegal" },
    "RS": { de: "Serbien" },
    "SC": { de: "Seychellen" },
    "SL": { de: "Sierra Leone" },
    "ZW": { de: "Simbabwe" },
    "SG": { de: "Singapur" },
    "SX": { de: "Sint Maarten" },
    "SK": { de: "Slowakei" },
    "SI": { de: "Slowenien" },
    "SO": { de: "Somalia" },
    "HK": { de: "Sonderverwaltungsregion Hongkong" },
    "MO": { de: "Sonderverwaltungsregion Macau" },
    "ES": { de: "Spanien" },
    "SJ": { de: "Spitzbergen und Jan Mayen" },
    "LK": { de: "Sri Lanka" },
    "BL": { de: "St. Barth\u00e9lemy" },
    "SH": { de: "St. Helena" },
    "KN": { de: "St. Kitts und Nevis" },
    "LC": { de: "St. Lucia" },
    "MF": { de: "St. Martin" },
    "PM": { de: "St. Pierre und Miquelon" },
    "VC": { de: "St. Vincent und die Grenadinen" },
    "ZA": { de: "S\u00fcdafrika" },
    "SD": { de: "Sudan" },
    "GS": { de: "S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln" },
    "KR": { de: "S\u00fcdkorea" },
    "SS": { de: "S\u00fcdsudan" },
    "SR": { de: "Suriname" },
    "SY": { de: "Syrien" },
    "TJ": { de: "Tadschikistan" },
    "TW": { de: "Taiwan" },
    "TZ": { de: "Tansania" },
    "TH": { de: "Thailand" },
    "TL": { de: "Timor-Leste" },
    "TG": { de: "Togo" },
    "TK": { de: "Tokelau" },
    "TO": { de: "Tonga" },
    "TT": { de: "Trinidad und Tobago" },
    "TD": { de: "Tschad" },
    "CZ": { de: "Tschechien" },
    "TN": { de: "Tunesien" },
    "TR": { de: "T\u00fcrkei" },
    "TM": { de: "Turkmenistan" },
    "TC": { de: "Turks- und Caicosinseln" },
    "TV": { de: "Tuvalu" },
    "UG": { de: "Uganda" },
    "UA": { de: "Ukraine" },
    "HU": { de: "Ungarn" },
    "UY": { de: "Uruguay" },
    "UZ": { de: "Usbekistan" },
    "VU": { de: "Vanuatu" },
    "VA": { de: "Vatikanstadt" },
    "VE": { de: "Venezuela" },
    "AE": { de: "Vereinigte Arabische Emirate" },
    "US": { de: "Vereinigte Staaten" },
    "GB": { de: "Vereinigtes K\u00f6nigreich" },
    "VN": { de: "Vietnam" },
    "WF": { de: "Wallis und Futuna" },
    "CX": { de: "Weihnachtsinsel" },
    "EH": { de: "Westsahara" },
    "CF": { de: "Zentralafrikanische Republik" },
    "CY": { de: "Zypern" },
}
