export const InsuranceStatus = {
    PROCESS : "PROCESS",
    CANCELLING : "CANCELLING",
    CANCELLED : "CANCELLED",
    COMPLETED : "COMPLETED"
};

export const BoxStatus = {
    CREATED : "CREATED",
    READY_FOR_PAYMENT : "READY_FOR_PAYMENT",
    WAITING_FOR_CARD_DELIVERY : "WAITING_FOR_CARD_DELIVERY",
    COMPLETED : "COMPLETED",
    TRIGGER_LOCKING : "TRIGGER_LOCKING",
    TRIGGER_UNLOCKING : "TRIGGER_UNLOCKING",
    LOCKED : "LOCKED",
    CANCELLED : "CANCELLED",
    CLEARED : "CLEARED",
    UNRENTING_GUNNEBO : "UNRENTING_GUNNEBO",
    UNRENTING_INSURANCE : "UNRENTING_INSURANCE",
    UNRENTING_PARTNERBOX : "UNRENTING_PARTNERBOX",
    UNRENTED : "UNRENTED"
}
