import React, {useCallback, useState, useEffect} from 'react';
import {Button, FormControl, TextField} from "@mui/material";
import { useAppState } from "contexts/AppStateContext";
import {useSnackbar} from "notistack";
import {useHistory, useLocation} from "react-router-dom";
import FunctionsApiService from "services/FunctionsApiService";
import {getAuth} from "firebase/auth";
import {verifyPasswordResetCode, checkActionCode, confirmPasswordReset, applyActionCode } from "firebase/auth";

const AuthAction = {
    ResetPassword: "resetPassword",
    RecoverEmail: "recoverEmail",
}

export default function AuthActionFormView() {
    const {strings, setIsLoading} = useAppState();
    const [mode, setMode] = useState('');
    const [userToken, setUserToken] = useState();
    const [password, setPassword] = useState('');
    const [isPwValid, setIsPwValid] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [isActionComplete, setIsActionComplete] = useState(false);
    const [recoveredEmail, setRecoveredEmail] = useState('');
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const history = useHistory();
    const auth = getAuth();

    useEffect(()=>{
        const params = new URLSearchParams(location.search);

        const m = params.get('mode');
        const c = params.get('oobCode');

        if(m) setMode(m);
        if(c) setUserToken(c);

        switch(m){
            case AuthAction.ResetPassword:
                verifyPasswordResetCode(auth, c).then( d =>{
                    setIsInitialLoad(false);
                }).catch( error => {
                    setIsInitialLoad(false);
                    setErrorMessage(strings["ERROR_"+error.code]);
                });
                break;
            case AuthAction.RecoverEmail:
                checkActionCode(auth, c).then( d =>{
                    setRecoveredEmail(d.data.email);
                    setIsInitialLoad(false);
                }).catch( error => {
                    setIsInitialLoad(false);
                    setErrorMessage(strings["ERROR_"+error.code]);
                });
                break;
            default:
                setIsInitialLoad(false);
                setErrorMessage(strings["ERROR_auth/invalid-action-link"]);
        }

    }, [location, strings, auth])

    const handleClick = () => {
        setIsLoading(true);

        switch(mode){
            case AuthAction.ResetPassword:
                confirmPasswordReset(auth, userToken, password)
                    .then(() => {
                        setIsActionComplete(true);
                    })
                    .catch((error) => {
                        console.error(error.code);
                        enqueueSnackbar(error.message, {variant: 'error'});
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                break;
            case AuthAction.RecoverEmail:
                applyActionCode(auth, userToken)
                    .then(() => {
                        FunctionsApiService.customersSyncAuthEmail({newEmail:recoveredEmail}).then((d)=>{
                            setIsActionComplete(true);
                            setIsLoading(false);
                        })
                    }).catch( error => {
                        console.error(error.code);
                    setIsLoading(false);
                        enqueueSnackbar(error.message, {variant: 'error'});
                    });
                break;
            default:
        }

    };

    const goToLogin = useCallback(()=>{
        history.replace("/");
    }, [history])

    const isPwValidCheck = useCallback((pw) => {
        return pw && pw.length >= 6;
    }, [])

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
        setIsPwValid(isPwValidCheck(e.target.value));
    }, [isPwValidCheck])

    return (
        <div className="auth-action-form-container">
            {isInitialLoad
                ?   <div className="screen-content">
                        <div className="centered-message-container">{strings["GENERAL_LOADING"]}</div>
                    </div>
                :   errorMessage
                    ?   <div className="screen-content">
                            <div className="centered-message-container">{errorMessage}</div>
                            <Button
                                variant="contained"
                                className="blue-button"
                                onClick={goToLogin}
                            >
                                {strings["RESET_PW_VIEW_BTN_TO_LOGIN"]}
                            </Button>
                        </div>
                    :   <div>
                            <div className="page-header overlay-header">
                                {/*<img className="logo-container"
                                     srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white@2x.png 2x`}
                                     src={`${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white.png`}
                                     alt="Logo"
                                />*/}
                                <div className="empty-placeholder"/>
                                {mode === AuthAction.ResetPassword ? strings["RESET_PW_VIEW_TITLE"] : null}
                                {mode === AuthAction.RecoverEmail ? strings["RECOVER_EMAIL_VIEW_TITLE"] : null}
                                <div className="empty-placeholder"/>
                            </div>

                            {isActionComplete
                                ?   <div className="screen-content">
                                        <div className="status-icon-container">
                                            <img src={`${process.env.PUBLIC_URL}/assets/check-white.svg`} alt='check-icon' className="payment-status"/>
                                        </div>
                                        <p className="success-message">
                                            {mode === AuthAction.ResetPassword ? strings["RESET_PW_VIEW_DESCRIPTION_SUCCESS"] : null}
                                            {mode === AuthAction.RecoverEmail ? strings["RECOVER_EMAIL_VIEW_DESCRIPTION_SUCCESS"] : null}
                                        </p>

                                        {recoveredEmail
                                            ? <span>{recoveredEmail}</span>
                                            : null
                                        }

                                        <Button
                                            variant="contained"
                                            className="blue-button"
                                            onClick={goToLogin}
                                        >
                                            {strings["RESET_PW_VIEW_BTN_TO_LOGIN"]}
                                        </Button>
                                    </div>
                                :   <div>
                                        <div className="screen-content">
                                            <p>
                                                {mode === AuthAction.ResetPassword ? strings["RESET_PW_VIEW_DESCRIPTION"] : null}
                                                {mode === AuthAction.RecoverEmail ? strings["RECOVER_EMAIL_VIEW_DESCRIPTION"] : null}
                                            </p>

                                            {mode === AuthAction.ResetPassword
                                                ?   <FormControl variant="outlined" className="input-container">
                                                        <TextField
                                                            className="email-field"
                                                            placeholder={strings["RESET_PW_VIEW_PLACEHOLDER"]}
                                                            variant="outlined"
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                            type="password"
                                                        />
                                                    </FormControl>
                                                :   null
                                            }

                                            <Button
                                                disabled={mode === AuthAction.ResetPassword  && !isPwValid}
                                                variant="contained"
                                                className="blue-button"
                                                onClick={handleClick}
                                            >
                                                {mode === AuthAction.ResetPassword ? strings["RESET_PW_VIEW_BTN_SEND"] : null}
                                                {mode === AuthAction.RecoverEmail ? strings["RECOVER_EMAIL_VIEW_BTN_SEND"] : null}
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </div>
            }

        </div>
    );
}
