import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppStateProvider, useAppState } from "contexts/AppStateContext";
import {Switch, useHistory, useLocation} from "react-router-dom";
import styled from 'styled-components';
import './UserDashboardMain.scss';
import SidebarNav from "./concerns/sidebar-navigation/SidebarNav";

import LoadingOverlay from "./components/LoadingOverlay";
import { SnackbarProvider } from "notistack";
import { getFirebaseApp } from "./services/FirebaseService";
import FunctionsApiService from "./services/FunctionsApiService";
import {DashboardRoutes, dashboardPaths, isDashboardPath} from "navigation/DashboardRoutes";
import {AuthRoutes, isAuthPath} from "navigation/AuthRoutes";
import {SignupRoutes, isSignupPath} from "navigation/SignupRoutes";
import {isCheckoutPath, CheckoutRoutes} from "navigation/CheckoutRoutes";
import { breakpoints } from "styles/breakpoints";
import {isOverlayPath, OverlayRoutes} from "navigation/OverlayRoutes";
import {AccountSetupRoutes, isAccountSetupPath} from "navigation/AccountSetupRoutes";
import {PlanMigrationRoutes, isPlanMigrationPath} from "navigation/PlanMigrationRoutes";
import {PublicRoutes, isPublicPath} from "navigation/PublicRoutes";
import * as Sentry from "@sentry/browser";

const theme = createTheme({
    typography: {
        fontFamily: ['Aalto Regular', 'sans-serif'].join(','),
        fontSize: 16,
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#eee"
                },
                track: {
                    // Controls default (unchecked) color for the track
                    backgroundColor: "#666",
                    "$checked$checked + &": {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: "#1BC9B4"
                    }
                }
            }
        }
    }
});


export default function App() {

    const [firebaseInitialised, setFirebaseInitialised] = useState(false);

    useEffect(()=> {
        getFirebaseApp();
        setFirebaseInitialised(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!firebaseInitialised) return null;

    return (
        <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            autoHideDuration={4500}
        >
            <ThemeProvider theme={theme}>
                <AppStateProvider>
                    <MainContent />
                </AppStateProvider>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

function MainContent() {
    const { customer, authUser } = useAppState();
    const [isLoadingAuth, setIsLoadingAuth] = useState(true);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if(authUser === undefined) return;
        if (authUser) {
            if (isAuthPath(location.pathname)) {
                history.replace(dashboardPaths.boxesOverview);
            } else if (!isDashboardPath(location.pathname) && !isCheckoutPath(location.pathname)  && !isSignupPath(location.pathname) && !isOverlayPath(location.pathname) && !isAccountSetupPath(location.pathname) && !isPlanMigrationPath(location.pathname) && !isPublicPath(location.pathname)){
                history.replace(dashboardPaths.boxesOverview);
                console.log("This page does not exist");
            }
            Sentry.setUser({ id: authUser?.uid });
        } else {
            if (!isAuthPath(location.pathname) && !isSignupPath(location.pathname) && !isCheckoutPath(location.pathname) && !isPlanMigrationPath(location.pathname) && !isPublicPath(location.pathname)) {
                history.replace('/');
            }
        }
        setIsLoadingAuth(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    useEffect( () => {
        // should only be called once a new customer registers through the registration form
        if(!isSignupPath(location.pathname)){
            if(customer && customer.email && !customer.signup){
                const payload = {
                    signup:{
                        signupSource:'WEB_APP'
                    }
                }
                FunctionsApiService.customersPartialUpdate(payload).catch(error => {});
            }
        }


    }, [customer]);

    return (
        <>
            <LoadingOverlay />

            {!isLoadingAuth && <>
                <div className="desktop-sidebar">
                    {(isDashboardPath(location.pathname) || isCheckoutPath(location.pathname) || isOverlayPath(location.pathname) || isAccountSetupPath(location.pathname)) && <SidebarNav />}
                </div>

                <MainContainer>
                    <Switch>
                        {SignupRoutes}
                        {AuthRoutes}
                        {DashboardRoutes}
                        {PlanMigrationRoutes}
                        {PublicRoutes}
                    </Switch>
                </MainContainer>
            </>}
            {(isCheckoutPath(location.pathname) || isOverlayPath(location.pathname) || isAccountSetupPath(location.pathname)) &&
                <CheckoutOverlay>
                    {isCheckoutPath(location.pathname) && <CheckoutContainer>{CheckoutRoutes}</CheckoutContainer>}
                    {isAccountSetupPath(location.pathname) && <CheckoutContainer>{AccountSetupRoutes}</CheckoutContainer>}
                    {isOverlayPath(location.pathname) && <OverlayContainer>{OverlayRoutes}</OverlayContainer>}
                </CheckoutOverlay>
            }

        </>
    )
}
const MainContainer = styled.div`
    width: 100%;
    min-height: 100vh;
`;

const CheckoutOverlay = styled.div`
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 10;

    @media ${breakpoints.mobileL} {
        background:rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px 32px;
        box-sizing: border-box;
    }

    @media ${breakpoints.desktop} {
        padding: 80px 80px;
    }
`;

const CheckoutContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: scroll;

    @media ${breakpoints.mobileL} {
        background: white;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 8px 30px rgba(0,0,0,.5);
        height: 100%;
    }
`;

const OverlayContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 640px;
    height: 100vh;
    box-sizing: border-box;
    overflow: scroll;
    margin: auto;
    background: white;
    padding:24px 24px 80px 24px;

    @media ${breakpoints.mobileL} {
        background: white;
        border-radius: 5px;
        box-shadow: 0 8px 30px rgba(0,0,0,.5);
        height: inherit;
    }
`;


