import {getFirestore, doc, getDoc, onSnapshot, collection, query, where, limit, orderBy, getDocs} from "firebase/firestore";
import {getFirebaseApp} from "./FirebaseService";

export const getPostIdentData = (userId) => {
    return new Promise((resolve, reject) => {

        const db = FirestoreApiService.getFirestore();
        const path = "postIdents/" + userId;
        const q = doc(db, path);

        onSnapshot(q, (snapshot) => {
            resolve(snapshot.data());
        }, error => {
            reject(error);
        });

    });
}

export class FirestoreApiService{

    static getFirestore(){
        return getFirestore(getFirebaseApp());
    }

    static getCustomer(customerId){
        return new Promise((resolve, reject)=>{

            const db = FirestoreApiService.getFirestore();
            const path = "customers/" + customerId;
            const q = doc(db, path);

            getDoc(q).then(snapshot => {
                let d = snapshot.data();
                d.id = snapshot.id;
                resolve(d);

            }).catch((error)=>{
                console.error("There was a problem retrieving the data for customerId " +customerId , error);
                reject(error);
            })
        });
    }

    static getVisitsForBox(userId, boxId, l) {
        return new Promise((resolve, reject)=>{
            const db = this.getFirestore();
            const path = "customers/" + userId + "/boxes/" + boxId + "/visits";
            const q = query(collection(db, path), limit(l), orderBy("visitedAt", 'desc') );

            getDocs(q).then(snapshot => {
                const docs = [];
                snapshot.forEach((d) => {
                    docs.push(Object.assign({}, d.data(), {id: d.id}));
                });
                resolve(docs);
            }).catch((error)=>{
                console.error("There was a problem retrieving the visits for boxId " +boxId , error);
                reject(error);
            });

        });
    }

    static getCustomerUpdates(userUid, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customers/" + userUid;
        const q = doc(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            successCallback(Object.assign({
                ...snapshot.data(),
                id:snapshot.id
            }));
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerGunneboLocationsUpdates(userUid, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customer-gunnebo/" + userUid + "/locations";
        const q = collection(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerGunneboContractUpdates(userUid, locationId, boxId, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customer-gunnebo/" + userUid + "/locations/" + locationId + "/contracts/" + boxId;
        const q = doc(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            successCallback(Object.assign({
                ...snapshot.data(),
                id:snapshot.id
            }));
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerGunneboContractPartnersUpdates(userUid, locationId, boxId, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customer-gunnebo/" + userUid + "/locations/" + locationId + "/contracts/" + boxId + "/partners";
        const q = collection(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    // SUBSCRIPTIONS

    static async getLastSubscriptionId(uid, successCallback, errorCallback) {
        try {
            const db = this.getFirestore();
            const path = "invoicings/" + uid;
            const q = doc(db, path);

            const snapshot = await getDoc(q);
            const d = snapshot.data();
            successCallback(d?.lastSubscriptionId);
        } catch(error){
            errorCallback(error);
        }
    }

    static getSubscriptionUpdates(userUid, subId, successCallback, errorCallback) {

        const db = this.getFirestore();
        const lastSubscriptionPath = "invoicings/" + userUid + "/subscriptions/" + subId;
        const lastSubscriptionQuery = doc(db, lastSubscriptionPath);

        let unsubscribe = onSnapshot(lastSubscriptionQuery, (snapshot) => {
            successCallback(snapshot.data());
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getOrdersForBox(userId, subId, boxId) {
        return new Promise((resolve, reject)=>{
            const db = this.getFirestore();
            const path = "invoicings/" + userId + "/subscriptions/" + subId + "/orders";
            const q = query(collection(db, path), where("meta.refs.boxId", "==", boxId));

            getDocs(q).then(snapshot => {
                const docs = [];
                snapshot.forEach((d) => {
                    docs.push(Object.assign({}, d.data(), {id: d.id}));
                });
                resolve(docs);
            }).catch((error)=>{
                console.error("There was a problem retrieving the visits for boxId " +boxId , error);
                reject(error);
            });

        });
    }


    static getCustomerPostIdentUpdates(userUid, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "postidents/" + userUid;
        const q = doc(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            successCallback(snapshot.data());
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerBoxUpdates(userUid, boxId, successCallback, errorCallback) {

        const db = this.getFirestore();
        const path = "customers/" + userUid + "/boxes/"+boxId;
        const q = doc(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const box = snapshot.data();
            if(box) box.id = snapshot.id;
            successCallback(box);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerBoxesUpdates(userUid, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customers/" + userUid + "/boxes";
        const q = collection(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getPartnerRequestsForBox(boxId, l) {
        return new Promise((resolve, reject)=>{
            const db = this.getFirestore();
            const path = "partner-requests/";
            const q = query(collection(db, path), limit(l), where("boxId", "==", boxId));

            getDocs(q).then(snapshot => {
                const docs = [];
                snapshot.forEach((d) => {
                    docs.push(Object.assign({}, d.data(), {id: d.id}));
                });
                resolve(docs);
            }).catch((error)=>{
                console.error("There was a problem retrieving the partner requests for boxId: " + boxId , error);
                reject(error);
            });

        });
    }

    static getPartnerRequestsForBoxUpdates(userId, boxId, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "partner-requests/";
        const q = query(collection(db, path), where("ownerId", "==", userId), where("boxId", "==", boxId));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getPartnerRequestsByCustomer(userId, successCallback, errorCallback) {

        const db = this.getFirestore();
        const path = "partner-requests/";
        const q = query(collection(db, path), where("partnerId", "==", userId));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getCustomerSharedBoxesUpdates(userUid, successCallback, errorCallback) {

        const db = this.getFirestore();
        const path = "shared-boxes";
        const q = query(collection(db, path), where('customerId', '==', userUid));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getBoxesSharedWithCustomerUpdates(userUid, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customers/" + userUid + "/shared-boxes-access";
        const q = collection(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const docs = [];
            snapshot.forEach((d) => {
                docs.push(Object.assign({}, d.data(), {id: d.id}));
            });
            successCallback(docs);
        }, error => {
            errorCallback(error);
        });

        return unsubscribe;
    }

    static getLatestInsuranceForBoxUpdates(userId, boxId, successCallback, errorCallback) {
        const db = this.getFirestore();
        const path = "customers/" + userId + "/boxes/" + boxId + "/insurances";
        const q = collection(db, path);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let data = [];

            snapshot.forEach((doc)=>{
                let d = doc.data();
                d.id = doc.id;
                data.push(d);
            });

            data.sort(( a, b ) => {
                if (a.createdAt.seconds < b.createdAt.seconds) {
                    return 1;
                }
                if (a.createdAt.seconds > b.createdAt.seconds) {
                    return -1;
                }
                return 0;
            });

            successCallback(data[0]);
        }, (error) => {
            errorCallback(error);
        });

        return unsubscribe;
    }
}
