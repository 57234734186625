export const allPriceBooks = {
    BER: {
        data: [
            {
                "id": "09737288d0c970c908537053a72980ba",
                "product": {
                    "ref": "OVERDUE_NOTICE_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 10,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "5861e7785fef210e8ff4c7ce15dfb37d"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "0ac46f5d65a5d1ec29e6f3fabd2ba1a1",
                "product": {
                    "ref": "100000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 6.42,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "4e2e4e14113fecd8f02a0b2aa81ddd6a"
                    },
                    {
                        "price": {
                            "unit": 77,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "be603ff114409321d1f52ef2f2ee9a9e"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "0dea244679f08602291015b44cce1dfd",
                "product": {
                    "ref": "STD",
                    "type": "BOX"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 24,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "a4a39a441a093adcf31710474723e6d2",
                        "requires": [
                            "BOX_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 288,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "b5ec48b32545877f169a3f348296e1cc",
                        "requires": []
                    }
                ],
                "location": "BER",
                "meta": {
                    "size": "STD",
                    "scale": {
                        "width": 235,
                        "height": 49,
                        "deep": 365
                    },
                    "volume": 3.7
                }
            },
            {
                "id": "296e4674aac1b28b027cc31d45e15677",
                "product": {
                    "ref": "1000000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 88.92,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "b4b1f2e883721529dda83d9daeb18fe2"
                    },
                    {
                        "price": {
                            "unit": 1067,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "8c6f234eeedfb8db1b474158d96b6aff"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "2a31d06f1e9ae9c5857e4ad39934d820",
                "product": {
                    "ref": "UNSCHEDULED_SAFE_OPENING_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 650,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "59d4faae2f79da2aacb60fcc3903df60"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "54c8fbd75a210e6d119d3ca61c02923d",
                "product": {
                    "ref": "MAX",
                    "type": "BOX"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 54,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "576ab14bf0a897d8386b61cba5c2bd9b",
                        "requires": [
                            "BOX_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 648,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "ac4a4ce1f5de68d6f067b339b3b3a307",
                        "requires": []
                    }
                ],
                "location": "BER",
                "meta": {
                    "size": "MAX",
                    "scale": {
                        "width": 235,
                        "height": 186,
                        "deep": 365
                    },
                    "volume": 15.9
                }
            },
            {
                "id": "66cbc5fce34d131bdb317537b733fb43",
                "product": {
                    "ref": "PARTNER",
                    "type": "PARTNER"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 5,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "885fa21da28f4e645ba2997598d6a92d",
                        "requires": [
                            "PARTNER_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 60,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "250e90c0f02d82b659320884fb797507",
                        "requires": [
                            "PARTNER_ACTIVATION_FEE"
                        ]
                    }
                ],
                "location": "BER"
            },
            {
                "id": "83d57fb37bd5afb47d7ffe90e34748d3",
                "product": {
                    "ref": "BOX_ACTIVATION_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 15,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "63976f61ec7f72f9d7dd82b48bb9cb7b"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "8ccbf27d6fbaa2273f98145531a3b1c1",
                "product": {
                    "ref": "500000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 43.08,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "66cbe97aa3cbb5312a87497f6acdb025"
                    },
                    {
                        "price": {
                            "unit": 517,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "4c14f53a3cf1167ee158c4e0c37fa33b"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "98ea3170a15d6b5e3200ee7273860976",
                "product": {
                    "ref": "250000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 20.17,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "f10ec6c2d1a39108ae0518b17849ce00"
                    },
                    {
                        "price": {
                            "unit": 242,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "1a1510d41d6bf063c197be1e4ec3bff0"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "a9860ecb034cf309f6fca857c49a8eab",
                "product": {
                    "ref": "RESET_BIOMETRIC_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 25,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "04350b4e1ae7f780e7951354439cc80f"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "bcac6d4f86fa781db3982e57cc28e9f3",
                "product": {
                    "ref": "PARTNER_ACTIVATION_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 15,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "63976f61ec7f72f9d7dd82b48bb9cb7b"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "ca94d8913071657f0fad1e7243fc6e88",
                "product": {
                    "ref": "ONBOARDING_SERVICE_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 75,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "1392b5b8e14aac9d5d60ff3cb2f8425a"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "df8fb5d31f2f76707c562b9fcf1e61de",
                "product": {
                    "ref": "REPLACE_BOX_LOCK_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 65,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "89065b2486b7e59e27f79f9281695c14"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "e61196541a50f1a71c21a6734a15917b",
                "product": {
                    "ref": "50000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 1.83,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "3ffae9ec44e389e8160a69b140639638"
                    },
                    {
                        "price": {
                            "unit": 22,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "e146587079ee240fb0e1100be7ff9bce"
                    }
                ],
                "location": "BER"
            },
            {
                "id": "e991ba8878df39a1e8f69e3f9bd8d1bb",
                "product": {
                    "ref": "REPLACE_CUSTOMER_CARD_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 50,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "1f6c4324762abb96a160628f4c39e16a"
                    }
                ],
                "location": "BER"
            }
        ]
    },
    STR: {
        data: [
            {
                "id": "1e940da3a844fde29e6bdc6cab403652",
                "product": {
                    "ref": "RESET_BIOMETRIC_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 25,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "04350b4e1ae7f780e7951354439cc80f"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "211db6695dcf37e72f724014553e804f",
                "product": {
                    "ref": "100000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 6.42,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "4e2e4e14113fecd8f02a0b2aa81ddd6a"
                    },
                    {
                        "price": {
                            "unit": 77,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "be603ff114409321d1f52ef2f2ee9a9e"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "30853236f3803ec6247bdf061ae303f4",
                "product": {
                    "ref": "PARTNER",
                    "type": "PARTNER"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 5,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "885fa21da28f4e645ba2997598d6a92d",
                        "requires": [
                            "PARTNER_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 60,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "250e90c0f02d82b659320884fb797507",
                        "requires": [
                            "PARTNER_ACTIVATION_FEE"
                        ]
                    }
                ],
                "location": "STR"
            },
            {
                "id": "3268d3ddde4fafa45b6c6ccf77e54076",
                "product": {
                    "ref": "REPLACE_BOX_LOCK_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 65,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "89065b2486b7e59e27f79f9281695c14"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "40b08545386956bed4e0ad2ded54e018",
                "product": {
                    "ref": "SPL",
                    "type": "BOX"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 34,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "5993f3de5b62ebfbfcaf81efce03379d",
                        "requires": [
                            "BOX_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 408,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "3be6e2ec0df088076b8613fd12f124a2",
                        "requires": []
                    }
                ],
                "location": "STR",
                "meta": {
                    "size": "SPL",
                    "scale": {
                        "width": 235,
                        "height": 118,
                        "deep": 325
                    },
                    "volume": 0
                }
            },
            {
                "id": "61bfedbc36c4b702d1c988c51d61140c",
                "product": {
                    "ref": "OVERDUE_NOTICE_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 10,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "5861e7785fef210e8ff4c7ce15dfb37d"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "7ddd90e832ffc0d8de3fe7e3533fc31a",
                "product": {
                    "ref": "BOX_ACTIVATION_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 15,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "63976f61ec7f72f9d7dd82b48bb9cb7b"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "a4029f73fbfbcb179d239f3ebb2f6630",
                "product": {
                    "ref": "REPLACE_CUSTOMER_CARD_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 50,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "1f6c4324762abb96a160628f4c39e16a"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "ba639210d64104f02dbecd43255b2aad",
                "product": {
                    "ref": "PARTNER_ACTIVATION_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 15,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "63976f61ec7f72f9d7dd82b48bb9cb7b"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "c0b5ce4e29f0238cb4b846a2155a3e8c",
                "product": {
                    "ref": "MAX",
                    "type": "BOX"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 54,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "576ab14bf0a897d8386b61cba5c2bd9b",
                        "requires": [
                            "BOX_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 648,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "ac4a4ce1f5de68d6f067b339b3b3a307",
                        "requires": []
                    }
                ],
                "location": "STR",
                "meta": {
                    "size": "MAX",
                    "scale": {
                        "width": 235,
                        "height": 186,
                        "deep": 325
                    },
                    "volume": 0
                }
            },
            {
                "id": "c5ae05004633419adad1720ef72eecbc",
                "product": {
                    "ref": "ONBOARDING_SERVICE_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 75,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "1392b5b8e14aac9d5d60ff3cb2f8425a"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "dfc850251cb3f35ccda43ed85e4d243a",
                "product": {
                    "ref": "1000000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 88.92,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "b4b1f2e883721529dda83d9daeb18fe2"
                    },
                    {
                        "price": {
                            "unit": 1067,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "8c6f234eeedfb8db1b474158d96b6aff"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "e08eff2fa6894fdcaa08b51c604b06ed",
                "product": {
                    "ref": "UNSCHEDULED_SAFE_OPENING_FEE",
                    "type": "FEE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 650,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "ONCE",
                            "cycles": 1
                        },
                        "id": "59d4faae2f79da2aacb60fcc3903df60"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "e941ca6b03e6a3b7445f5d9962f6c5aa",
                "product": {
                    "ref": "STD",
                    "type": "BOX"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 24,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "a4a39a441a093adcf31710474723e6d2",
                        "requires": [
                            "BOX_ACTIVATION_FEE"
                        ]
                    },
                    {
                        "price": {
                            "unit": 288,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "b5ec48b32545877f169a3f348296e1cc",
                        "requires": []
                    }
                ],
                "location": "STR",
                "meta": {
                    "size": "STD",
                    "scale": {
                        "width": 235,
                        "height": 49,
                        "deep": 325
                    },
                    "volume": 0
                }
            },
            {
                "id": "f5b0a63565953a9b91006d3a230374a6",
                "product": {
                    "ref": "500000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 43.08,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "66cbe97aa3cbb5312a87497f6acdb025"
                    },
                    {
                        "price": {
                            "unit": 517,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "4c14f53a3cf1167ee158c4e0c37fa33b"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "f92e885cf3b0bb2777d9cfb68ea0ee92",
                "product": {
                    "ref": "250000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 20.17,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "f10ec6c2d1a39108ae0518b17849ce00"
                    },
                    {
                        "price": {
                            "unit": 242,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "1a1510d41d6bf063c197be1e4ec3bff0"
                    }
                ],
                "location": "STR"
            },
            {
                "id": "fe8c6681e18e412e406a3fa291bc4b24",
                "product": {
                    "ref": "50000",
                    "type": "INSURANCE"
                },
                "plans": [
                    {
                        "price": {
                            "unit": 1.83,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "MONTHLY",
                            "cycles": 1
                        },
                        "id": "3ffae9ec44e389e8160a69b140639638"
                    },
                    {
                        "price": {
                            "unit": 22,
                            "vat": 0.19,
                            "currency": "EUR"
                        },
                        "interval": {
                            "unit": "YEARLY",
                            "cycles": 1
                        },
                        "id": "e146587079ee240fb0e1100be7ff9bce"
                    }
                ],
                "location": "STR"
            }
        ]
    }


}
