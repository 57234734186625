import { getFunctions, httpsCallable } from "firebase/functions";
import {allLocations} from "concerns/checkout/constants/Locations";
import {allPriceBooks} from "concerns/checkout/constants/PriceBooks";

export default class FunctionsApiService {
    static functions;

    static location = 'BER';

    static locationsFetchStatic(){
        return new Promise( (resolve) => {
            resolve(allLocations);
        })
    }
    static locationsFetch() {
        const locationsFetch = httpsCallable(this.getFirebaseFunctions(), 'locationsFetch');
        return locationsFetch.apply(null, arguments);
    }

    static locationsPriceBooksFetchStatic({location}){
        return new Promise( (resolve) => {
            resolve(allPriceBooks[location]);
        })
    }

    static fetchPricesStatic({location}){
        return new Promise( (resolve) => {
            resolve(allPriceBooks[location]);
        })
    }

    static fetchPrices(){
        const request = httpsCallable(this.getFirebaseFunctions(), 'fetchPrices');
        return request.apply(null, arguments);
    }

    static locationsPriceBooksFetchAllOpen() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'locationsPriceBooksFetchAllOpen');
        return request.apply(null, arguments);
    }

    static locationsPriceBooksFetch() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'locationsPriceBooksFetch');
        return request.apply(null, arguments);
    }

    static fetchSubscriptionPrices(){
        const request = httpsCallable(this.getFirebaseFunctions(), 'fetchSubscriptionPrices');
        return request.apply(null, arguments);
    }

    static customersUpdate() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersUpdate');
        return request.apply(null, arguments);
    }

    static customersPartialUpdate() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersPartialUpdate');
        return request.apply(null, arguments);
    }

    static customersValidateCard() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersValidateCard');
        return request.apply(null, arguments);
    }

    static paymentsValidateCoupon() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'paymentsValidateCoupon');
        return request.apply(null, arguments);
    }

    static paymentsClientToken() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'paymentsClientToken');
        return request.apply(null, arguments);
    }

    static checkoutsCreate() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'checkoutsCreate');
        return request.apply(null, arguments);
    }

    static postidentsCreate() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'postidentsCreate');
        return request.apply(null, arguments);
    }

    static postidentsFetch() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'postidentsFetch');
        return request.apply(null, arguments);
    }

    static inquiriesRequest() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'inquiriesRequest');
        return request.apply(null, arguments);
    }

    static customersRegisterCard() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersRegisterCard');
        return request.apply(null, arguments);
    }

    static customersGunneboLostCard() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersGunneboLostCard');
        return request.apply(null, arguments);
    }

    static getInsurancePrices() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'getInsurancePrices');
        return request.apply(null, arguments);
    }

    static boxesCreateInsuranceSubscription() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'boxesCreateInsuranceSubscription');
        return request.apply(null, arguments);
    }

    static boxesCancelInsuranceSubscription() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'boxesCancelInsuranceSubscription');
        return request.apply(null, arguments);
    }

    static changePin() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersGunneboUpdatePin');
        return request.apply(null, arguments);
    }

    static customersValidateEmail(){
        const customersValidateEmail = httpsCallable(this.getFirebaseFunctions(), 'customersValidateEmail');
        return customersValidateEmail.apply(null, arguments);
    }

    static customersPasswordUpdate() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersPasswordUpdate');
        return request.apply(null, arguments);
    }

    static generateTan() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersUpdateServiceTan');
        return request.apply(null, arguments);
    }

    static boxesLostKey() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'boxesLostKey');
        return request.apply(null, arguments);
    }

    static paymentsUpdateSelectedMethod() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'paymentsUpdateSelectedMethod');
        return request.apply(null, arguments);
    }

    static boxesCancel() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'boxesCancel');
        return request.apply(null, arguments);
    }

    static requestPartnerRequest() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'requestPartnerRequest');
        return request.apply(null, arguments);
    }

    static confirmPartnerRequest() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'confirmPartnerRequest');
        return request.apply(null, arguments);
    }

    static rejectPartnerRequest() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'rejectPartnerRequest');
        return request.apply(null, arguments);
    }

    static removePartnerRequest() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'removePartnerRequest');
        return request.apply(null, arguments);
    }

    static blockPartnerAccess() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'blockPartnerAccess');
        return request.apply(null, arguments);
    }

    static unblockPartnerAccess() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'unblockPartnerAccess');
        return request.apply(null, arguments);
    }

    static cancelPartnerAccess() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'cancelPartnerAccess');
        return request.apply(null, arguments);
    }

    static removePartnerAccess() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'removePartnerAccess');
        return request.apply(null, arguments);
    }

    static boxesUpdateName() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'boxesUpdateName');
        return request.apply(null, arguments);
    }

    static customersSyncAuthEmail() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'customersSyncAuthEmail');
        return request.apply(null, arguments);
    }

    static fetchInvoices() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'fetchInvoices');
        return request.apply(null, arguments);
    }

    static sendInvoice() {
        const request = httpsCallable(this.getFirebaseFunctions(), 'sendInvoice');
        return request.apply(null, arguments);
    }

    static getPartnerDetails(customerId, sharedAccessCode) {
        const request = httpsCallable(this.getFirebaseFunctions(), 'getPartnerDetails');
        return request({customerId: customerId, sharedAccessCode: sharedAccessCode});
    }

    static getMainCustomerDetails(customerId, sharedAccessCode) {
        const request = httpsCallable(this.getFirebaseFunctions(), 'getMainCustomerDetails');
        return request({customerId: customerId, sharedAccessCode: sharedAccessCode});
    }

    static getBoxDetails(customerId, boxId, sharedAccessCode) {
        const request = httpsCallable(this.getFirebaseFunctions(), 'getBoxDetails');
        return request({customerId: customerId, boxId: boxId, sharedAccessCode: sharedAccessCode});
    }

    static getFirebaseFunctions() {
        if (!this.functions) {
            this.functions = getFunctions();
            this.functions.region = 'europe-west3';
        }
        return this.functions;
    }

    static getSalutations() {
        return [
            {value: 'MR', label: 'Herr'},
            {value: 'MS', label: 'Frau'},
        ];
    }

    static getProfessionalTitles() {
        return [
            {value: 'DR', label: 'Dr.'},
            {value: 'PROF', label: 'Prof.'},
        ];
    }
}
