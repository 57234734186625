import React, {useCallback, useState, useEffect} from 'react';
import styled from "styled-components";
import Link from "@mui/material/Link";
import * as Sentry from "@sentry/browser";
import {useTranslation} from "react-i18next";
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import trackEvent, {EVENTS} from "../services/Tracking";

import { PageHeaderLinearFlow } from "@asservato/shared.ui.page-header-linear-flow";
import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";
import { ItemIdentificationMethod } from "@asservato/shared.ui.item-identification-method";

import * as S from '../styles/sharedStyles';
import {colors} from "../styles/colors";
import {useHistory} from "react-router-dom";

export default function IdentificationMethodView({ rootPath, prevPath, nextPath, index }) {

    const { formData, updateFormData, setErrorMessage, postIdent, updateActiveStep } = useCheckoutState();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(()=>{
        updateFormData({heroImage:"../assets/checkout/hero_4.jpg"});
        updateActiveStep(index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        if(!postIdent) return;

        updateFormData({
            postIdentVerificationStatus: postIdent.status,
            postIdentTransactionNumber: postIdent.transactionNumber,
            postIdentRedirectUrlWeb: postIdent.redirectUrlWeb,
        });

        if(postIdent.status === "REQUESTED" || postIdent.status === "SUCCESS" || postIdent.status === "DECLINED") setIsLoading(false);

        if(postIdent.status === "SUCCESS") history.push(rootPath + nextPath);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postIdent])

    const onConfirm = useCallback(async () => {
        setIsLoading(true);

        trackEvent(EVENTS.POSTIDENT_METHOD_SELECTED, 'type', formData.postIdentVerificationType);

        try {
            FunctionsApiService.postidentsCreate({'type': formData.postIdentVerificationType});
        } catch (error) {

            let msg = t('error');

            if (error && error.message ) {
                msg = error.message;
            }

            Sentry.captureException(msg);
            setIsLoading(false);
            setErrorMessage(msg);
        }

    }, [formData.postIdentVerificationType, setErrorMessage, t]);

    const onRedirect = useCallback(() => {
        const url = formData.postIdentRedirectUrlWeb;
        if (url) {
            trackEvent(EVENTS.POSTIDENT_LINK_OPENED);
            window.open(url, '_blank');
        }
    }, [formData.postIdentRedirectUrlWeb]);

    const onResetMethod = useCallback(() => {
        updateFormData({postIdentVerificationType:'', postIdentTransactionNumber:'', postIdentRedirectUrlWeb:''});
    }, [updateFormData]);

    return (
        <S.View>
            <PageHeaderLinearFlow
                showBackButton={false}
                hideProgressBar={true}
            />
            <S.Content>
                {t('selectIdentification.description')}

                <PostIdentLogoContainer>
                    <PostIdentLogo src={'../assets/checkout/postident.jpg'} alt={"Logo PostIdent"}/>
                </PostIdentLogoContainer>

                {!formData.postIdentTransactionNumber && !formData.postIdentRedirectUrlWeb
                    ?   <>
                            <ItemIdentificationMethod title={t('selectIdentification.postIdentVerificationCard.online.title')}
                                                      text={t('selectIdentification.postIdentVerificationCard.online.text')}
                                                      image={'./assets/postident-online.png'}
                                                      features={[
                                                          t('selectIdentification.postIdentVerificationCard.online.features.1'),
                                                          t('selectIdentification.postIdentVerificationCard.online.features.2'),
                                                          t('selectIdentification.postIdentVerificationCard.online.features.3')
                                                      ]}
                                                      isActive={formData.postIdentVerificationType === 'ONLINE'}
                                                      onClick={() => updateFormData({postIdentVerificationType:'ONLINE'})}
                                                      isDisabled={isLoading}
                            />

                            <ItemIdentificationMethod title={t('selectIdentification.postIdentVerificationCard.branch.title')}
                                                      text={t('selectIdentification.postIdentVerificationCard.branch.text')}
                                                      image={'./assets/postident-branch.png'}
                                                      isActive={formData.postIdentVerificationType === 'BRANCH'}
                                                      onClick={() => updateFormData({postIdentVerificationType:'BRANCH'})}
                                                      isDisabled={isLoading}
                            />

                            {!isLoading
                                ? <ButtonPrimary label={t('selectIdentification.cta.select')} marginTop={32} onClick={onConfirm} isDisabled={!formData.postIdentVerificationType}/>
                                : <LoadingIndicator marginTop={24}/>
                            }
                        </>
                    :   <>
                            <ItemIdentificationMethod title={formData.postIdentTransactionNumber}
                                                      text={t('selectIdentification.transactionNumber')}
                                                      image={formData.postIdentVerificationType === 'ONLINE' ? './assets/postident-online.png' : './assets/postident-branch.png'}
                                                      isSummary={true}
                            />

                            <ButtonPrimary label={t('selectIdentification.cta.redirect')} marginTop={32} onClick={onRedirect}/>

                            <LinkContainer>
                                <Link onClick={onResetMethod} href={'#'} underline={'hover'}>{t('selectIdentification.cta.change')}</Link>
                            </LinkContainer>
                        </>
                }

            </S.Content>
        </S.View>
    )
}

const PostIdentLogoContainer = styled.div`
    background: ${colors.special.post_ident};
    margin-top: 16px;
    display: flex;
    justify-content: center;
`;

const PostIdentLogo = styled.img`
    max-height: 60px;
`;

const LinkContainer = styled.div`
    text-align: center;
    margin-top: 24px;
    
    a {
        color: ${colors.primary.primary_350};
    }
`;

