export function generateRandomTan() {
    let tan;
    tan = 100000 + Math.ceil(Math.random() * 899999);
    return tan;
}

export function getDateInMinutes(minutes) {
    let now = new Date().getTime();
    let dayOffset = minutes * 60 * 1000;
    let newDate = getDateStringFromTimestamp(now + dayOffset, true);

    return newDate;
}

export function getPreviousDay(ts) {
    const d = new Date(ts)
    d.setDate(d.getDate() - 1)
    return [d.getFullYear(), d.getMonth()+1, d.getDate()].join("-");
}

export function getDateInDays(days, startDate, asTimestamp) {
    let now = startDate ? new Date(startDate).getTime() : new Date().getTime();
    let dayOffset = days * 24 * 60 * 60 * 1000;
    let newDate = asTimestamp ? now + dayOffset : getDateStringFromTimestamp(now + dayOffset);

    return newDate;
}

export function getDateStringFromTimestamp(ts, includeTime) {
    let d = new Date(ts);
    let dateString = hasLeadingZero(d.getDate());
    dateString += "." + hasLeadingZero(d.getMonth() + 1);
    dateString += "." + d.getFullYear();

    if (includeTime) {
        dateString += " - " + hasLeadingZero(d.getHours()) + ":" + hasLeadingZero(d.getMinutes());
    }
    return dateString;
}

export function getTimeStringFromTimestamp(ts) {
    let d = new Date(ts);
    let timestring = hasLeadingZero(d.getHours()) + ":" + hasLeadingZero(d.getMinutes());
    return timestring;
}

export function getCycleInDays(cycle) {
    let days = cycle * 30;

    return days;
}

export function hasLeadingZero(n) {
    if (n < 10) {
        n = "0" + n;
    }

    return n;
}

export function calculateTotalPrice(net, vat) {
    let vatPrice = net * vat;
    vatPrice = Math.round((vatPrice + Number.EPSILON) * 100) / 100; // round value to two decimals

    return net + vatPrice;
}

export function getFormattedPrice(price) {
    if(price === null || price === undefined ) return '';

    let pString = price.toFixed(2);
    let split = pString.split('.');
    let p = '';

    if (split.length > 1) {
        p = split[0] + ',' + split[1];
    } else {
        p = split[0] + ',00';
    }

    return p;
}

export function getFormattedCurrency(currency) {
    switch (currency) {
        case 'EUR':
            return '€';
        default:
    }
    return currency;
}

export function addThousandSeparator(value) {
    if (!value) return '';
    if (value < 1000) return value.toString();

    let v = value.toString();
    let s;

    if (value < 1000000) {
        let offset = Math.floor(value / 1000).toString();
        s = v.substring(0, offset.length) + '.' + v.substring(offset.length);
    } else {
        let offset = Math.floor(value / 1000000).toString();
        s = v.substring(0, offset.length) + '.' + v.substring(offset.length, offset.length + 3) + '.' + v.substring(offset.length + 3);
    }

    return s;
}

export const obfuscateString = function (str, visibleDigits, totalLength) {
    let obfuscatedString = [];
    let cleanString = sanitizeString(str);
    if (totalLength) {
        cleanString = cleanString.padStart(totalLength, '0');
    }

    let obfuscateUntil = cleanString.length - visibleDigits;
    for (let i = 0; i < cleanString.length; i++) {
        if (i < obfuscateUntil) {
            obfuscatedString.push('X');
        } else {
            obfuscatedString.push(cleanString[i]);
        }
    }

    const regex = /,/g;
    obfuscatedString = obfuscatedString.toString().replace(regex, '');

    return obfuscatedString;
};

export const groupDigits = function (originalStr, groupSize, separator) {
    if (originalStr.length <= groupSize) {
        return originalStr;
    }

    let cleanString = removeDotsFromString(originalStr);
    let groupedString = '';

    for (let i = 0; i < cleanString.length; i++) {

        if (i > 0 && i % groupSize === 0) {
            groupedString += separator;
        }

        groupedString += cleanString[i];
    }

    return groupedString;
};

export const sanitizeString = (str) => {
    if(str.includes('.')) return removeDotsFromString(str);
    return removeSpacesFromString(str);
}

export const removeDotsFromString = function (str) {
    const regex = /[.]/g;
    return str.replace(regex, '');
};

export const removeSpacesFromString = function (str) {
    const regex = /\s/g;
    return str.replace(regex, '');
};

export const formatString = function (string, replacements) {
    Object.keys(replacements).forEach(key => {
        string = string.replaceAll('{' + key + '}', replacements[key]);
    });
    return string;
};

export const splitSentencesIntoDivs = function(string) {
    string = '<div>' + string;
    string = string.replaceAll('.\n' || '. \n', '.</div><div>');
    return string + '</div>';
}

export const isNumbersOnly = function(text){
    var re = /^[0-9]+$/;
    return re.test(text);
};
