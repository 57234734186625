import {PartnerRequestStatus} from "constants/enums_partner_request";
import {useEffect, useState} from "react";
import {FirestoreApiService} from "services/FirestoreApiService";

export default function useCustomerPartnerRequests(uid) {

    const [partnerRequestsByCustomer, setPartnerRequestsByCustomer] = useState();
    const [openPartnerRequestsByCustomer, setOpenPartnerRequestsByCustomer] = useState();

    useEffect(() => {
        if(!uid) return;

        const unsubscribe = FirestoreApiService.getPartnerRequestsByCustomer(uid, requests => {
            setPartnerRequestsByCustomer(requests);
        }, error => {
            console.log("Error: ", error);
        })

        return () => unsubscribe();
    }, [uid]);

    useEffect(() => {
        if(!partnerRequestsByCustomer) return [];

        let open = [];

        partnerRequestsByCustomer.forEach( (el) => {
            if(el.status === PartnerRequestStatus.REQUESTED || el.status === PartnerRequestStatus.REJECTED) open.push(el);
        })

        setOpenPartnerRequestsByCustomer(open);

    }, [partnerRequestsByCustomer]);

    return {partnerRequestsByCustomer, openPartnerRequestsByCustomer}
}
