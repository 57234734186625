import {FirestoreApiService} from "services/FirestoreApiService";
import {useEffect, useRef, useState} from "react";
import {CustomerStatus} from "constants/enums_customer";

export default function useCustomer(uid) {

    const [customer, setCustomer] = useState();
    const [customerRegistrationIsComplete, setCustomerRegistrationIsComplete] = useState();
    let unsubscribeCustomer = useRef();

    useEffect(() => {
        if(!uid) {
            if(unsubscribeCustomer.current) unsubscribeCustomer.current();
            return;
        }
        unsubscribeCustomer.current = FirestoreApiService.getCustomerUpdates(uid, data => {
            if(data.status === CustomerStatus.REGISTERED && data.address?.country && data.address?.city && data.address?.street && data.address?.streetNo && data.address?.zip) setCustomerRegistrationIsComplete(true);
            setCustomer(data);
        }, error => {
            console.log("Error: ", error);
        });
        return () => {if(unsubscribeCustomer.current) unsubscribeCustomer.current()};
    }, [uid])

    const resetCustomerData = () => {
        setCustomer(null);
    }


    return {customer, customerRegistrationIsComplete, resetCustomerData};
}
