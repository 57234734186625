import React from "react";
import { useAppState } from "contexts/AppStateContext";
import 'UserDashboardMain.scss'
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useBox from "hooks/useBox";
import {useParams} from "react-router-dom";
import {Button} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function PlanVisitView() {
    const context = useAppState();
    const {strings, goBack} = context;
    const {boxId} = useParams();
    const {location} = useBox(boxId);

    if (!location || location.length === 0) {
        return null;
    }

    const mapUrl = 'https://www.google.com/maps/search/?api=1&query=Asservato | Filiale ' + location.address;

    return (
        <div className="plan-visit">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["BOX_DETAIL_BTN_PLAN_VISIT"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="plan-visit-info padded-container">
                <img className="plan-visit-img"
                     srcSet={`${process.env.PUBLIC_URL}/assets/branch_BER.png, ${process.env.PUBLIC_URL}/assets/branch_BER@2x.png 2x`}
                     src={`${process.env.PUBLIC_URL}/assets/branch_BER.png`}
                     alt="Location"
                />

                <div>
                    <div className='plan-visit-title'>
                        <span>{strings["PLAN_VISIT_VIEW_HEADLINE_LOCATION"]}</span>
                    </div>
                    <div className='plan-visit-text'>
                        <span>{location.address}</span>
                    </div>
                </div>

                <div>
                    <div className='plan-visit-title'>
                        <span>{strings["PLAN_VISIT_VIEW_HEADLINE_OPENING_HOURS"]}</span>
                    </div>
                    <table className='plan-visit-text-date'>
                        <tbody>
                        {
                            Object.keys(location.hours).map(key => {
                                return <tr key={key}>
                                    <td>{key}</td>
                                    <td className='date'>{location.hours[key]}</td>
                                </tr>;
                            })
                        }
                        </tbody>
                    </table>
                    <div className="line"></div>
                    <a href={mapUrl} target="_blank" className='plan-visit-text' rel="noreferrer">
                        <img className='plan-visit-logo'
                             srcSet={`${process.env.PUBLIC_URL}/assets/illu_route.png, ${process.env.PUBLIC_URL}/assets/illu_route@2x.png 2x`}
                             src={`${process.env.PUBLIC_URL}/assets/illu_route.png`}
                             alt="Location icon"
                        />
                    </a>
                    <div className='plan-visit-title'>
                        <span>{strings["PLAN_VISIT_VIEW_BTN_PLAN_ROUTE_TITLE"]}</span>
                    </div>
                    <div className='plan-visit-text'>
                        <span>{strings["PLAN_VISIT_VIEW_BTN_PLAN_ROUTE_SUBTITLE"]}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
