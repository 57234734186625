import React from 'react';
import styled from 'styled-components';
import { colors } from "styles/colors";
import * as fonts from "styles/fontStyles";
import {IconButton} from "@mui/material";
import * as Icon from "react-feather";

export default function ItemProfileDetails({title, detailOne, detailTwo, detailThree, isDisabled, onClick, id}){

    return (
        <Container isDisabled={isDisabled}>
            <Right>
                <Name>{title}</Name><br/>
                <DetailsContainer>
                    {detailOne && <><Detail>{detailOne}</Detail><br/></>}
                    {detailTwo && <><Detail>{detailTwo}</Detail><br/></>}
                    {detailThree && <><Detail>{detailThree}</Detail><br/></>}
                </DetailsContainer>
            </Right>

            {!isDisabled &&
            <ActionContainer>
                <IconButton
                    variant="contained"
                    size="large"
                    onClick={onClick}
                >
                    <Icon.Edit2 color={colors.primary.primary_300} size={24}/>
                </IconButton>
            </ActionContainer>
            }

        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid ${colors.primary.primary_450};
    margin-bottom: ${props => props.isLast ? 0 : "12px"};
`;

const Right = styled.div`
  //margin-left: 24px;
`;

const ActionContainer = styled.div`
  
`;

const Name = styled(fonts.Title)`
  color: ${colors.primary.primary_100};
`;

const DetailsContainer = styled.div`
  //line-height: 16px;
`;

const Detail = styled(fonts.Body)`
  color: ${colors.primary.primary_100};
`;

