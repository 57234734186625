export const PostIdentStatus = {
    CREATED : "CREATED",
    REQUESTED : "REQUESTED",
    IN_REVIEW : "IN_REVIEW",
    SUCCESS : "SUCCESS",
    DECLINED : "DECLINED"
};

export const PostIdentType = {
  BRANCH : "BRANCH",
  ONLINE : "ONLINE"
}