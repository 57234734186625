import {userDashboardPaths} from "constants/paths";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import React from "react";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function RequestAccessSuccessView() {
    const {strings} = useAppState();
    const history = useHistory();

    return (
        <div className="access-request-container">
            <div className="page-header overlay-header">
                <IconButton className="placeholder-close-button" size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["REQUEST_BOX_ACCESS_VIEW_TITLE"]}
                <Button onClick={() => history.push(userDashboardPaths.boxesOverview)}><CloseRoundedIcon/></Button>
            </div>

            <div className="overlay">
                <img
                    srcSet={`${process.env.PUBLIC_URL}/assets/grant-access/icn_check_big.png, ${process.env.PUBLIC_URL} /assets/grant-access/icn_check_big@2x.png 2x`}
                    src={`${process.env.PUBLIC_URL}/assets/grant-access/icn_check_big.png`} alt=""/>
                {/* TODO: this copy is hardcoded – it should use i18n instead */}
                <p className="text-success">
                    Ihre Zugangsanfrage wurde erfolgreich gesendet. Sobald der Fachbesitzer Ihre Anfrage bestätigt hat, werden Sie per E-Mail darüber
                    benachrichtigt und können Ihre Registrierung hier fortsetzen.
                </p>

                <Button variant="contained"
                        className="finish-granting-access-button blue-button"
                        onClick={() => history.push(userDashboardPaths.boxesOverview)}
                >
                    {strings["GENERAL_CLOSE"]}
                </Button>
            </div>
        </div>
    );
}

