import React, {useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { useAppState } from "contexts/AppStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import {LocationContractStatus} from "constants/enums_location_contract";
import {from} from "rxjs";
import {formatString} from "utils/mobile-app-helpers";
import {useSnackbar} from "notistack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function ReportLossView() {
    const {setIsLoading, strings, authUser, customerBoxes, customerGunnebo, customerPartnerBoxes} = useAppState();
    const [dialog, setDialog] = useState({open: false, type: null, selectedBox: null});
    const {enqueueSnackbar} = useSnackbar();
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        if(!customerBoxes || ! customerPartnerBoxes) return;

        let sharedBoxes = customerPartnerBoxes.filter(box => box.keyReceived && box.contract.status !== LocationContractStatus.LOCKED &&  box.partnerRequest.status !== ContractPartnerStatus.CANCELLED);
        let myBoxes = customerBoxes.filter(box => box.keyReceived && box.contract.status !== LocationContractStatus.LOCKED);
        let allBoxes = myBoxes.concat(sharedBoxes);

        setBoxes(allBoxes);

        setIsLoading(false);

    }, [setIsLoading, customerBoxes, customerPartnerBoxes]);

    const handleReportChipcardLossClick = useCallback(() => {
        setDialog({
            open: true,
            type: strings['REPORT_LOSS_VIEW_TYPE_CHIPCARD']
        });
    }, [setDialog, strings]);

    const handleReportKeyLossClick = useCallback(() => {
        // If there is only one available box, then preselect it.
        setDialog({
            open: true,
            type: strings['REPORT_LOSS_VIEW_TYPE_KEY'],
            selectedBox: boxes.length === 1 ? boxes[0] : null
        });
    }, [setDialog, strings, boxes]);

    const handleBoxClick = useCallback(box => {
        setDialog(dialog => ({
            ...dialog,
            selectedBox: box
        }));
    }, [setDialog]);

    const handleDialogClose = useCallback(() => {
        setDialog({
            open: false
        });
    }, [setDialog]);

    const handleDialogConfirm = useCallback(() => {
        setIsLoading(true);

        if (dialog.type === strings['REPORT_LOSS_VIEW_TYPE_CHIPCARD']) {
            from(FunctionsApiService.customersGunneboLostCard({
                number: customerGunnebo?.cardNumber || '',
            })).subscribe({
                next: result => {
                    setIsLoading(false);
                    enqueueSnackbar(strings['REPORT_LOSS_VIEW_NOTIFICATION_CHIPCARD_REPORTED'], {
                        variant: 'success',
                    });
                },
                error: error => {
                    setIsLoading(false);
                    enqueueSnackbar(strings['ERROR/general'], {
                        variant: 'error',
                    });
                }
            });
        } else if (dialog.type === strings['REPORT_LOSS_VIEW_TYPE_KEY']) {
            from(FunctionsApiService.boxesLostKey({
                id: dialog.selectedBox.id,
                customerId: dialog.selectedBox.contract.isOwner ? authUser.uid : dialog.selectedBox.contract.refs.ownerId,
            })).subscribe({
                next: result => {
                    setIsLoading(false);
                    enqueueSnackbar(strings['REPORT_LOSS_VIEW_NOTIFICATION_KEY_REPORTED'], {
                        variant: 'success',
                    });
                },
                error: error => {
                    setIsLoading(false);
                    enqueueSnackbar(strings['ERROR/general'], {
                        variant: 'error',
                    });
                }
            });
        }

        setDialog({
            open: false
        });
    }, [dialog, setDialog, customerGunnebo?.cardNumber, enqueueSnackbar, setIsLoading, strings, authUser?.uid]);

    const getBoxName = useCallback(box => {
        if (box.name) {
            return box.name;
        }
        return strings['GENERAL_BOX'] + ' ' + strings['GENERAL_NR'] + box.gunnebo.id;
    }, [strings]);

    if(!customerGunnebo) return null;

    return (
        <div className="report-loss">
            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                         srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                         alt="Menu Icon"
                    />
                </Button>

                {strings["REPORT_LOSS_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <div className="title">{strings["REPORT_LOSS_VIEW_HEADLINE_CHIPCARD"]}</div>
                <p className="description">{strings["REPORT_LOSS_VIEW_DESCRIPTION_CHIPCARD"]}</p>
                <Button variant='contained' className="blue-button" onClick={handleReportChipcardLossClick}
                        disabled={!customerGunnebo.hasActiveCard || customerGunnebo.hasBlockedCard}>
                    {strings["REPORT_LOSS_VIEW_BTN_CHIPCARD"]}
                </Button>

                <Dialog className="dialog-window"
                        open={dialog.open}
                        onClose={handleDialogClose}
                        aria-describedby="alert-dialog-description"
                >
                    {
                        (
                            dialog.type === strings['REPORT_LOSS_VIEW_TYPE_CHIPCARD'] ||
                            (dialog.type === strings['REPORT_LOSS_VIEW_TYPE_KEY'] && dialog.selectedBox)
                        ) &&
                        <>
                            <DialogContent className="dialog-content">
                                <div className="dialog-title">{strings["REPORT_LOSS_VIEW_BTN_CONFIRMATION"]}</div>
                                <DialogContentText id="alert-dialog-description">
                                    {
                                        dialog.type === strings['REPORT_LOSS_VIEW_TYPE_CHIPCARD'] &&
                                        strings["REPORT_LOSS_VIEW_CONFIRMATION_CHIPCARD"]
                                    }
                                    {
                                        dialog.type === strings['REPORT_LOSS_VIEW_TYPE_KEY'] &&
                                        formatString(strings["REPORT_LOSS_VIEW_CONFIRMATION_KEY"], {box: getBoxName(dialog.selectedBox)})
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogConfirm} className="green-button">{strings["REPORT_LOSS_VIEW_BTN_CONFIRMATION"]}</Button>
                                <Button onClick={handleDialogClose} className="default-button">{strings["GENERAL_CANCEL"]}</Button>
                            </DialogActions>
                        </>
                    }
                    {
                        dialog.type === strings['REPORT_LOSS_VIEW_TYPE_KEY'] && !dialog.selectedBox &&
                        <>
                            <DialogContent className="dialog-content">
                                <div className="dialog-title">{strings["REPORT_LOSS_VIEW_SELECT_BOX"]}</div>
                                {
                                    boxes.map(box => (
                                        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                                        <a className="report-loss-box-list-item" href="#" onClick={() => handleBoxClick(box)} key={box.id}>{getBoxName(box)}</a>
                                    ))
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDialogClose} className="default-button">{strings["GENERAL_CANCEL"]}</Button>
                            </DialogActions>
                        </>
                    }
                </Dialog>

                <div className="title">{strings["REPORT_LOSS_VIEW_HEADLINE_KEY"]}</div>
                <p className="description">{strings["REPORT_LOSS_VIEW_DESCRIPTION_KEY"]}</p>
                <Button className="blue-button" variant="contained"
                        onClick={handleReportKeyLossClick}
                        disabled={boxes.length === 0 || !customerGunnebo.hasActiveCard}>
                    {strings["REPORT_LOSS_VIEW_BTN_KEY"]}
                </Button>

                <div className="alert-message">
                    <ErrorOutlinedIcon/>
                    <p>{strings["REPORT_LOSS_VIEW_DESCRIPTION"]}</p>
                </div>
            </div>
        </div>
    )
}
