import {ContractPartnerStatus} from "constants/enums_contract_partner";

export default function usePartners(partners) {

    partners?.sort( (a, b) => {
        if (a.createdAt.seconds < b.createdAt.seconds) return -1
        if (a.createdAt.seconds > b.createdAt.seconds) return 1
        return 0;
    })

    const getLastConfirmedPartner = () => {

        if(!partners || partners.length === 0) return null;

        let lastConfirmedPartner = null;

        for(let i=0; i < partners.length; i++){
            if(partners[i].status === ContractPartnerStatus.ACCESSIBLE || partners[i].status === ContractPartnerStatus.LOCKED) {
                lastConfirmedPartner = partners[i];
                break;
            }
        }

        return lastConfirmedPartner;
    }

    const lastConfirmedPartnerHasAccess = () => {
        return getLastConfirmedPartner()?.status === ContractPartnerStatus.ACCESSIBLE;
    }

    const lastConfirmedPartnerIsLocked = () => {
        return getLastConfirmedPartner()?.status === ContractPartnerStatus.LOCKED;
    }

    return {getLastConfirmedPartner, lastConfirmedPartnerHasAccess, lastConfirmedPartnerIsLocked}

}
