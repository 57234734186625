import {useEffect, useState} from "react";
import FunctionsApiService from "../services/FunctionsApiService";
import {useAppState} from "contexts/AppStateContext";
import {useSnackbar} from "notistack";

export default function useCustomerInvoices(){

    const {strings, setIsLoading} = useAppState();
    const {enqueueSnackbar} = useSnackbar();
    const [customerInvoices, setCustomerInvoices] = useState();

    useEffect(() => {
        FunctionsApiService.fetchInvoices().then( invoices => {
            setCustomerInvoices(invoices.data);
        }).catch( error => {
            console.log("Error fetchInvoices: ", error);
            setCustomerInvoices([]);
        })

    }, [setIsLoading])

    const sendInvoice = id => {
        setIsLoading(true);
        FunctionsApiService.sendInvoice({id:id}).then( d => {
            setIsLoading(false);
            enqueueSnackbar(strings['INVOICES_VIEW_MESSAGE_INVOICE_SENT'], {
                variant: 'success',
                autoHideDuration: 2000,
            });
        }).catch( () => {
            setIsLoading(false);
            enqueueSnackbar(strings['ERROR/general'], {
                variant: 'error',
                autoHideDuration: 3000,
            });
        })
    }

    return {customerInvoices, sendInvoice}
}
