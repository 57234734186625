import {Button, FormControl, FormHelperText, InputAdornment, OutlinedInput} from "@mui/material";
import React, {useEffect, useState} from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useAppState } from "contexts/AppStateContext";
import {userDataTemplate} from "./AddressView";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {from} from "rxjs";
import {formatString} from "utils/mobile-app-helpers";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FunctionsApiService from "services/FunctionsApiService";
import {getCurrentAuthUser, reauthenticateUser, updateEmailOnUser} from "services/AuthenticationService";
import {FirestoreApiService} from "services/FirestoreApiService";

export default function EmailView() {
    const {strings, setIsLoading, authUser, goBack} = useAppState();
    const [userData, setUserData] = useState(userDataTemplate);
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        FirestoreApiService.getCustomerUpdates(authUser.uid, data => {
            setUserData(data);
            setNewEmail(data.email)
        }, error => {

        })
    }, [authUser.uid]);

    function toggle() {
        setShowConfirmation(expanded => !expanded);
    }

    function onEmailChange(val){
        setNewEmail(val);
        setUserData({...userData, email:val});
    }

    function onSaveNewEmail(){

        setIsLoading(true);

        let user = getCurrentAuthUser();

        reauthenticateUser(user.email, password)
            .then((userCredential) => {
                from(updateEmailOnUser(newEmail)).subscribe({
                    next: value => {
                        from(FunctionsApiService.customersUpdate(userData)).subscribe({
                            next: () => {
                                setPassword('');
                                toggle();
                                enqueueSnackbar(strings["EDIT_ADDRESS_VIEW_NOTIFICATION_DATA_SAVED"], {variant: 'success'});
                                setIsLoading(false);
                            },
                            error: (error) => {
                                console.error('error ', error)
                                throw error;
                            }
                        })
                    }, error: error => {
                        console.error('error ', error)
                        throw error;
                    }
                })

            }).catch((error) => {
                enqueueSnackbar(strings["ERROR_customers/save-failed"], {variant: 'error'});
                console.error(error);
                setIsLoading(false);
            }
        )
    }

    return (
        <div className="user-form-container email-container">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {formatString(strings["EDIT_CONTACT_DETAIL_VIEW_TITLE"], { detail: strings["EDIT_CONTACT_DETAIL_VIEW_LABEL_EMAIL"]})}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <FormControl variant="outlined" className="user-data-input-container email-form">
                    <FormHelperText className="input-label" id="email-input-label">{strings["REGISTER_VIEW_LABEL_EMAIL"]}</FormHelperText>
                    <OutlinedInput
                        className={newEmail ? "underlined-input" : "disabled-input"}
                        id="email-input"
                        placeholder="E-mail"
                        value={newEmail}
                        onChange={(e) => onEmailChange(e.target.value)}
                        aria-describedby="email-input-label"
                        labelWidth={0}
                        endAdornment={newEmail ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                    />
                </FormControl>

                <Button variant='contained'
                        className={showConfirmation ? "hide" : "user-data-button blue-button"}
                        onClick={toggle}
                >
                    {strings["GENERAL_CONFIRM"]}
                </Button>

                {showConfirmation && (
                    <div className="password-confirmation-text">
                        <p className="add-password">{strings["EDIT_CONTACT_DETAIL_VIEW_CONFIRM_WITH_PASSWORD"]}</p>

                        <FormControl variant="outlined" className="user-data-input-container password-form">
                            <FormHelperText className="input-label" id="password-input-label">{strings["REGISTER_VIEW_LABEL_PASSWORD"]}</FormHelperText>
                            <OutlinedInput
                                type="password"
                                className={password ? "underlined-input" : "disabled-input"}
                                id="password-input"
                                placeholder={strings["REGISTER_VIEW_PLACEHOLDER_PASSWORD"]}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                aria-describedby="password-input-label"
                                labelWidth={0}
                                endAdornment={password ? <InputAdornment position="start"><DoneIcon className="input-valid"/></InputAdornment> : null}
                            />
                        </FormControl>

                        <Button variant='contained'
                                disabled={!password}
                                className="blue-button user-data-button"
                                onClick={() => {onSaveNewEmail()}}>
                            {strings["GENERAL_SAVE"]}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
