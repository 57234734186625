import {userDashboardPaths} from "constants/paths";
import {Button} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { useAppState } from "contexts/AppStateContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useBox from "hooks/useBox";
import {formatString} from "utils/mobile-app-helpers";
import LoadingIndicator from "components/LoadingIndicator";

export default function FinishGrantingAccessProcessView() {
    const {strings} = useAppState();
    const history = useHistory();
    const {boxId} = useParams();
    const {latestPartner} = useBox(boxId);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(latestPartner) setIsLoading(false);
    }, [latestPartner, setIsLoading])

    return (
        <div className="finish-granting-access-container">
            <div className="page-header overlay-header">
                <div style={{width:'32px', margin:'12px'}}></div>

                {strings["SHARED_ACCESS_CHECKOUT_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            {!isLoading
                ?   <div className="overlay">
                        <img className="finish-granting-access-img"
                             srcSet={`${process.env.PUBLIC_URL}/assets/grant-access/icn_check_big.png, ${process.env.PUBLIC_URL}/assets/grant-access/icn_check_big@2x.png 2x`}
                             src={`${process.env.PUBLIC_URL}/assets/grant-access/icn_check_big.png`}
                             alt=""
                        />

                        <p className="access-activated-text">
                            {strings["SHARED_ACCESS_CHECKOUT_VIEW_SUCCESS"]}
                        </p>

                        <div className="access-activated-row">
                            <img className="finish-granting-access-img"
                                 srcSet={`${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_id.png, ${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_id@2x.png 2x`}
                                 src={`${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_id.png`}
                                 alt=""
                            />
                            <p>
                                <span className="medium">
                                    {latestPartner.firstName + ' ' + latestPartner.lastName}
                                </span>
                                {formatString(strings["SHARED_ACCESS_CHECKOUT_VIEW_BULLET_ID"], {
                                    pronoun: strings['GENERAL_PERSONAL_PRONOUN_' + latestPartner.salutation]
                                })}
                            </p>
                        </div>

                        <div className="access-activated-row">
                            <img className="finish-granting-access-img"
                                 srcSet={`${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_key.png, ${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_key@2x.png 2x`}
                                 src={`${process.env.PUBLIC_URL}/assets/grant-access/illu_circle_key.png`}
                                 alt=""
                            />
                            <p>
                                {strings["SHARED_ACCESS_CHECKOUT_VIEW_BULLET_KEY_PART1"]} <span
                                className="medium">{latestPartner.firstName + ' ' + latestPartner.lastName}</span> {strings["SHARED_ACCESS_CHECKOUT_VIEW_BULLET_KEY_PART2"]}
                            </p>
                        </div>

                        <Button variant="contained"
                                className="finish-granting-access-button blue-button"
                                onClick={() => history.push(userDashboardPaths.boxDetails + '/' + boxId)}
                        >
                            {strings["GENERAL_DONE"]}
                        </Button>
                    </div>
              : <>
                    <LoadingIndicator marginTop={48}/>
                    <p>
                        Daten werden geladen...
                    </p>
                </>
            }
        </div>
    );
}
