import { useCallback, useState, useEffect } from "react";
import { storageAvailable } from "../services/Utils";
import { formDataDefaults } from "../constants/FormData";

const formDataStorageKey = "@asvtformData";

export default function useFormData() {
  const savedFormData = storageAvailable('localStorage') ? localStorage.getItem(formDataStorageKey) : null;
  const initialValue = JSON.parse(savedFormData);

  // Load stored form data as initial state, while merging with defaults
  const [formData, setFormData] = useState({ ...formDataDefaults, ...initialValue });

  // Listens to any changes in formData and persists them to local storage
  useEffect(() => {
    // Prevent passwords from being saved to local storage
    const formDataCopy = { ...formData }
    delete formDataCopy.temporaryPassword;
    delete formDataCopy.password;
    delete formDataCopy.repeatPassword;

    if(storageAvailable('localStorage')) localStorage.setItem(formDataStorageKey, JSON.stringify(formDataCopy));
  }, [formData]);

  const updateFormData = useCallback((data) => {
    setFormData(prevState => {
      return { ...prevState, ...data }
    });
  }, [])

  const handleRadioChange = useCallback(({ formKey, event }) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [formKey]: event.target.value,
      }
    });
  }, [])

  const handleCheckboxClick = useCallback(({ formKey, event }) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [formKey]: {
          value: event.target.checked
        }
      }
    });
  }, [])

  const resetFormData = useCallback(() => {
      if(storageAvailable('localStorage')) localStorage.setItem(formDataStorageKey, JSON.stringify(formDataDefaults));
      setFormData(formDataDefaults);
  }, []);

  return { formData, updateFormData, handleRadioChange, handleCheckboxClick, resetFormData };
}
