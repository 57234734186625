import {Route} from "react-router-dom";
import LoginView from "concerns/authentication/LoginView";
import RegisterView from "concerns/authentication/RegisterView";
import ForgotPasswordView from "concerns/authentication/ForgotPasswordView";
import AuthActionFormView from "concerns/authentication/AuthActionFormView";
import React from "react";

export const authPaths = {
    login: '/',
    register: '/register',
    forgotPassword: '/forgot-password',
    authAction: '/auth/action',
}

export const isAuthPath = (path) => {
    let included = false;
    for (const arr of Object.entries(authPaths)) {
        if(path === arr[1]) included = true;
    }
    return included;
}

export const AuthRoutes = [
        <Route
            path={authPaths.login}
            render={() => {return <LoginView />;}}
            exact
            key={authPaths.login}
        />,
        <Route
            path={authPaths.register}
            render={() => <RegisterView />}
            key={authPaths.register}
        />,
        <Route
            path={authPaths.forgotPassword}
            render={() => <ForgotPasswordView />}
            key={authPaths.forgotPassword}
        />,
        <Route
            path={authPaths.authAction}
            render={() => <AuthActionFormView />}
            key={authPaths.authAction}
        />
]

