import {Button, FormControl, FormHelperText, OutlinedInput} from "@mui/material";
import React, {useState} from "react";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSnackbar} from "notistack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {getCurrentAuthUser, loginUser} from "services/AuthenticationService";
import FunctionsApiService from "services/FunctionsApiService";

export default function PasswordChangeView() {
    const {strings, setIsLoading, goBack} = useAppState();
    const [actualPassword, setActualPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const {enqueueSnackbar} = useSnackbar();

    return (
        <div className="user-form-container">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["CHANGE_PASSWORD_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <FormControl variant="outlined" className="user-data-input-container">
                    <FormHelperText className="input-label">{strings["CHANGE_PASSWORD_VIEW_LABEL_OLD_PW"]}</FormHelperText>
                    <OutlinedInput
                        type="password"
                        className="disabled-input"
                        id="actual-password-input"
                        placeholder={strings["CHANGE_PASSWORD_VIEW_PLACEHOLDER_OLD_PW"]}
                        value={actualPassword}
                        onChange={(e) => setActualPassword(e.target.value)}
                        aria-describedby="actual-password-input-label"
                        labelWidth={0}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container">
                    <FormHelperText className="input-label">{strings["CHANGE_PASSWORD_VIEW_LABEL_NEW_PW"]}</FormHelperText>
                    <OutlinedInput
                        type="password"
                        className="disabled-input"
                        id="new-password-input"
                        placeholder={strings["CHANGE_PASSWORD_VIEW_PLACEHOLDER_NEW_PW"]}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        aria-describedby="new-password-input-label"
                        labelWidth={0}
                    />
                </FormControl>

                <FormControl variant="outlined" className="user-data-input-container">
                    <FormHelperText className="input-label">{strings["CHANGE_PASSWORD_VIEW_LABEL_PW_REPEAT"]}</FormHelperText>
                    <OutlinedInput
                        type="password"
                        className="disabled-input"
                        id="repeated-password-input"
                        placeholder={strings["CHANGE_PASSWORD_VIEW_PLACEHOLDER_PW_REPEAT"]}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        aria-describedby="repeated-password-input-label"
                        labelWidth={0}
                    />
                </FormControl>

                <div className="alert-message">
                    <ErrorOutlinedIcon/>
                    <p>{strings["CHANGE_PASSWORD_VIEW_HINTBOX"]}</p>
                </div>

                <Button variant='contained'
                        disabled={!actualPassword || !newPassword || !repeatPassword}
                        className="blue-button user-data-button"
                        onClick={async () => {
                            if (newPassword !== repeatPassword) {
                                enqueueSnackbar(strings["CHANGE_PASSWORD_VIEW_PW_MISMATCH"], {variant: 'error'});
                                return;
                            }

                            setIsLoading(true);

                            try {
                                // make sure that user has current password
                                await loginUser(getCurrentAuthUser().email, actualPassword);
                                // then change it
                                await FunctionsApiService.customersPasswordUpdate({password:newPassword});
                                // and then log user in with new password
                                await loginUser(getCurrentAuthUser().email, newPassword);

                                setIsLoading(false);
                                enqueueSnackbar(strings["CHANGE_PASSWORD_VIEW_NOTIFICATION_NEW_PW_SAVED"], {variant: 'success'});
                                setActualPassword('');
                                setNewPassword('');
                                setRepeatPassword('');

                            } catch (error) {
                                setIsLoading(false);

                                let message = strings["ERROR_auth/default"];
                                if (error && error.message) {
                                    if(error.code === "auth/wrong-password"){
                                        message = strings["ERROR_re_auth/wrong-password"];
                                    } else {
                                        message = strings["ERROR_" + error.code];
                                    }
                                }
                                enqueueSnackbar(message, {variant: 'error'});
                            }
                        }}
                >
                    {strings["CHANGE_PASSWORD_VIEW_BTN_SAVE_CHANGES"]}
                </Button>
            </div>
        </div>
    );
}
