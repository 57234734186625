import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useCallback, useState, useEffect} from "react";
import styled from "styled-components";
import useBox from "hooks/useBox";
import {useParams} from "react-router-dom";
import { useAppState } from "contexts/AppStateContext";
import {addThousandSeparator, getDateStringFromTimestamp, getFormattedCurrency, getFormattedPrice} from "utils/mobile-app-helpers";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FunctionsApiService from "services/FunctionsApiService";
import {useSnackbar} from "notistack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { colors, fonts } from "@asservato/shared.styles";
import NotificationForCancellation from "concerns/boxes/components/NotificationForCancellation";
import LoadingView from "components/LoadingView";
import LoadingIndicator from "components/LoadingIndicator";

export default function BoxContractView() {
    const [open, setOpen] = useState(false);
    const {boxId} = useParams();
    const {strings, goBack, customerSubscription, replacePlaceholderInStrings, subscriptionPrices, refreshSubscriptionPrices} = useAppState();
    const {box, cardData, insurance} = useBox(boxId);
    const [isCancelled, setIsCancelled] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [partnerAccessPrice, setPartnerAccessPrice] = useState();
    const [insurancePrice, setInsurancePrice] = useState();
    const [amountInsured, setAmountInsured] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isCancelling, setIsCancelling] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect( () => {
        setIsLoading(true);
        if(customerSubscription && subscriptionPrices) setIsLoading(false);
    }, [customerSubscription, subscriptionPrices]);

    useEffect( () => {
        if(!subscriptionPrices || !subscriptionPrices[boxId]) {
            refreshSubscriptionPrices();
            return;
        };

        subscriptionPrices[boxId].addOns?.forEach( el => {
            if(el.object === "INSURANCE") {
                setInsurancePrice(el.price);
                setAmountInsured(el.amountInsured);
            }
            if(el.object === "PARTNER") {
                setPartnerAccessPrice(el.price);
            }
        })
    }, [subscriptionPrices, boxId, refreshSubscriptionPrices])


    const handleCancelPlanClick = useCallback(() => {
        setIsCancelling(true);

        const cancelCall = async () => {

            try {
                await FunctionsApiService.boxesCancel({id: boxId});
                enqueueSnackbar(strings['BOX_PLAN_DETAILS_CANCEL_PLAN_SUCCESS'], {variant: 'success'});
                setIsCancelled(true);
                setOpen(false);
                setIsCancelling(false);
            } catch (error) {
                enqueueSnackbar(strings['BOX_PLAN_DETAILS_CANCEL_PLAN_ERROR'], {variant: 'error'});
                setIsCancelling(false);
            }

        }

        cancelCall();

    }, [boxId, enqueueSnackbar, strings, setIsCancelling]);

    const handleClose = () => {
        setOpen(false);
    };

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div className="box-contract-section">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["BOX_SETTINGS_VIEW_LABEL_OVERVIEW"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <div className="card-body-contract">
                    <div className="image-container">
                        <img className="large-image" src={cardData.imgLargeSrc} alt={cardData.imgAlt}/>
                        <img className="small-image" src={cardData.imgLargeSrc} alt={cardData.imgAlt}/>
                    </div>

                    <div className="box-info">
                        <div className="box-info-row">
                            <span>{strings["GENERAL_BOX_SIZE"]}: </span>
                            <span className="semi-bold">{strings['GENERAL_BOX_SIZE_' + box.size]}</span>
                        </div>
                        <div className="box-info-row">
                            <span>{strings['GENERAL_BOX_PLAN']}: </span>
                            <span className="semi-bold">{strings['GENERAL_INTERVAL_'+subscriptionPrices[boxId].interval.unit]}</span>
                        </div>

                        {subscriptionPrices[boxId] &&
                            <>
                            <div className="box-info-row">
                                <span>{strings["GENERAL_BOX_PRICE"]}: </span>
                                <span className="semi-bold">
                                {getFormattedPrice(subscriptionPrices[boxId]?.price.unit)}{getFormattedCurrency(subscriptionPrices[boxId].price.currency)}
                                </span>
                            </div>
                            <div className="box-info-row">
                                <span>{strings["GENERAL_AUTOMATIC_EXTENSION"]} {strings['GENERAL_ON_THE_DATE_SUFFX']}: </span>
                                <span className="semi-bold">{getDateStringFromTimestamp(subscriptionPrices[boxId]?.nextBillingAt)}</span>
                            </div>
                            </>
                        }
                    </div>
                </div>


                {partnerAccessPrice
                    ? <AddOnContainer>
                        <fonts.Body>{strings['BOX_PLAN_DETAILS_ADDON_SHARED_ACCESS']}</fonts.Body>
                        <fonts.LabelSemiBold>+ {getFormattedPrice(partnerAccessPrice.unit)}{getFormattedCurrency(partnerAccessPrice.currency)} {strings['CHECKOUT_SUMMARY_VIEW_PER_'+subscriptionPrices[boxId].interval.unit]}</fonts.LabelSemiBold>
                    </AddOnContainer>
                    : null
                }

                {insurance && insurancePrice
                    ? <AddOnContainer>
                        <fonts.Body>{replacePlaceholderInStrings(strings['BOX_PLAN_DETAILS_ADDON_INSURANCE'], addThousandSeparator(amountInsured))}€</fonts.Body>
                        <fonts.LabelSemiBold>+ {getFormattedPrice(insurancePrice.unit)}{getFormattedCurrency(insurancePrice.currency)} {strings['CHECKOUT_SUMMARY_VIEW_PER_'+subscriptionPrices[boxId].interval.unit]}</fonts.LabelSemiBold>
                      </AddOnContainer>
                    : null
                }

                {
                    box.cancelling || isCancelled ?
                        <NotificationForCancellation box={box}/> :
                        <ButtonContainer>
                            <Button variant='outlined' className="outline-button" onClick={handleClickOpen}>{strings["BOX_PLAN_DETAILS_BTN_CANCEL_PLAN"]}</Button>
                        </ButtonContainer>
                }
                <Dialog className="dialog-window"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <DialogContent className="dialog-content">
                        <DialogTitle className="bold">{strings["BOX_PLAN_DETAILS_CONFIRM_CANCEL_TITLE"]}</DialogTitle>
                        <DialogContentText id="alert-dialog-description">
                            {strings["BOX_PLAN_DETAILS_CONFIRM_CANCEL_DESCRIPTION"]}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {isCancelling
                            ?   <LoadingIndicator marginTop={38}/>
                            :   <>
                                    <Button onClick={handleClose} className="default-button">{strings["GENERAL_CANCEL"]}</Button>
                                    <Button className="green-button" onClick={handleCancelPlanClick}>{strings["BOX_PLAN_DETAILS_BTN_CONFIRM_CANCEL"]}</Button>
                                </>
                        }

                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

const AddOnContainer = styled.div`
    background-color: ${colors.primary.primary_500};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;  
    padding: 16px 24px;
    color: ${colors.primary.primary_200};
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
