import {useLocation} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {Button, FormControl, OutlinedInput} from "@mui/material";
import { useAppState } from "contexts/AppStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useSnackbar} from "notistack";

export default function BoxSettingsView() {
    const {strings, goBack, setIsLoading, replacePlaceholderInStrings} = useAppState();
    const location = useLocation();
    const box = location.state?.box;
    const [name, setName] = useState(box?.name);
    const [buttonText, setButtonText] = useState(strings["BOX_SETTINGS_VIEW_LABEL_EDIT"]);
    const [disabled, setDisabled] = useState(true);
    const [displayName, setDisplayName] = useState(box?.name || '');
    const {enqueueSnackbar} = useSnackbar();

    const handleEditClick = useCallback( (e) => {
        e.preventDefault();

        setButtonText(disabled ? strings["BOX_SETTINGS_VIEW_LABEL_SAVE"] : strings["BOX_SETTINGS_VIEW_LABEL_EDIT"]);
        if (disabled) {
            setDisabled(false);
            return;
        }

        setIsLoading(true);

        let payload = {
            id: box.id,
            name: name,
        };

        FunctionsApiService.boxesUpdateName(payload).then( (d) => {
            setDisabled(true);
            setDisplayName(name);
            setIsLoading(false);
        }).catch( (error) =>{
            setIsLoading(false);
            setDisabled(true);
            enqueueSnackbar(strings['ERROR/general'], {
                variant: 'error',
            });
        })

    }, [disabled, strings, box.id, name, enqueueSnackbar, setIsLoading])

    const handleInputChange = (e) => {
        if (e.target.value.length > 20) {
            e.preventDefault();
            return;
        }
        setName(e.target.value);
    }

    return (
        <div className="box-settings-container">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                <div className="semi-bold">
                {displayName ? replacePlaceholderInStrings(strings["BOX_SETTINGS_VIEW_TITLE"], displayName) :
                    replacePlaceholderInStrings(
                        strings["BOX_SETTINGS_VIEW_TITLE"],
                        strings.GENERAL_BOX + " " + strings.GENERAL_NR + box.gunnebo.id
                    )
                }
                </div>

                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="edit-box-name-container padded-container">
                <p className="input-title">
                    {strings["BOX_SETTINGS_VIEW_LABEL_NAME"]}
                </p>

                <FormControl variant="outlined" className="change-name-input">
                    <OutlinedInput
                        disabled={disabled}
                        id="name-input"
                        placeholder={strings["BOX_SETTINGS_VIEW_PLACEHOLDER_NAME"]}
                        value={name}
                        onChange={handleInputChange}
                        aria-describedby="name-input-label"
                        labelWidth={0}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#"
                       className="link"
                       onClick={handleEditClick}
                    >
                        {buttonText}
                    </a>
                </FormControl>

                <p className="counter">
                    {name ? `${name.length}/20` : "0/20"}
                </p>
            </div>
        </div>
    );
}
