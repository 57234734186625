import { useEffect, useState } from 'react';
import FunctionsApiService from "services/FunctionsApiService";

export default function useLocations() {

    const [locations, setLocations] = useState();

    useEffect(() => {
        const loadData = async () => {
            try {

                let result = await FunctionsApiService.locationsFetchStatic();
                setLocations(result.data);

                result = await FunctionsApiService.locationsFetch();

                result.data.sort((a,b) => {
                    if(a.status === "OPEN" && b.status !== "OPEN"){
                        return -1;
                    }

                    if(a.status !== "OPEN" && b.status === "OPEN"){
                        return 1;
                    }

                    return 0;
                })

                setLocations(result.data);

            } catch (error) {
                //console.log("There was an error, getting the locations data : ", error);
            }
        }

        loadData();

    }, []);

    return {locations};

}
