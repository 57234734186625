import { TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import {colors} from 'styles/colors';

export default function TextInput({value, onChange, disabled, label, error, helperText, margin, width, isValid, name, type, maxLength}){

    return (
        <StyledField value={value}
                     disabled={disabled}
                     label={label}
                     variant='outlined'
                     error={error}
                     helperText={helperText}
                     size={"small"}
                     fullWidth={true}
                     marginoverride={margin}
                     width={width}
                     onChange={onChange ? (e) => onChange(e) : null}
                     isvalid={isValid?.toString() || 'false'}
                     name={name}
                     type={type || null}
                     inputProps={
                         {
                             maxLength: maxLength
                         }
                     }
        />
    )
}

const StyledField = styled(TextField)`

    margin: ${props => props.marginoverride || null} !important;
    width: ${props => props.width || null};

    & label.Mui-focused {
        color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_300};
        top: 0px;
    }
    
    .MuiInputLabel-root {
        color: ${colors.primary.primary_300};
        font-size: 18px;
        top: ${props => props.value?.length > 0 ? '0px' : '3px'};
    }

    .MuiInputLabel-shrink{
        color: ${props => props.disabled ? colors.primary.primary_350 : props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_400};
    }

    .MuiOutlinedInput-root {
        height: 48px;

        border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_450};
        border-width: 1px;
        border-radius: 0;
        font-size: 18px;

        & fieldset{
            border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_450};
            border-width: 1px;
            border-radius: 0;
        }

        ${(props) => {
            if (!props.disabled) {
                return css`
                  &:hover fieldset {
                      border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning_light : colors.primary.primary_100};
                  }`;
            }
        }}

        &.Mui-focused fieldset {
            border-color: ${props => props.isvalid === "true" ? colors.secondary.secondary_100 : props.error ? colors.semantic.warning : colors.primary.primary_100};
            border-width: 1px;
        }
    }
`;
