import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";

import {ButtonPrimary} from "@asservato/shared.ui.button-primary";
import {PageHeaderLinearFlow} from "@asservato/shared.ui.page-header-linear-flow";
import {ItemBoxSize} from "components/ItemBoxSize";

import * as S from '../styles/sharedStyles';
import {useHistory} from "react-router-dom";
import usePrices from "hooks/usePrices";
import LoadingIndicator from "components/LoadingIndicator";
import {signupPaths} from "navigation/SignupRoutes";
import InfoBox from "components/InfoBox";

export default function SelectPaymentCycleView({ rootPath , prevPath, nextPath, index}) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, setIsLoading, updateActiveStep} = useCheckoutState();
    const { t } = useTranslation();
    const history = useHistory();
    const { boxPrices } = usePrices(formData.selectedLocation);
    const [ selectedInterval, setSelectedInterval ] = useState(formData.selectedPlan?.interval.unit || 'YEARLY')

    useEffect(() => {
        updateFormData({heroImage:"../assets/checkout/hero_0.jpg"});
        updateActiveStep(index);

        // only track events in signup
        if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.BOX_SIZE_SELECTED, 'size', formData.selectedSize);

        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        setIsLoading(false);
    }, [boxPrices, setIsLoading]);

    const onSelection = useCallback( (interval) => {
        setSelectedInterval(interval);
    }, []);

    const onConfirm = useCallback(async () => {
        let plan = formData.selectedBox.plans.filter( el => el.interval.unit === selectedInterval)[0];
        updateFormData({selectedPlan:plan});
        history.push(rootPath + nextPath);
    }, [history, rootPath, nextPath, updateFormData, formData.selectedBox.plans, selectedInterval]);

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, prevPath, rootPath]);

    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={onBack}
                progressBrackets={progressBrackets}
                onConfirm={onConfirm}
                isConfirmEnabeled={formData.selectedSize}
                showBackButton={true}
            />
            <S.Content>


                <S.Description>
                    {t('selectPaymentCycle.subheading')}
                </S.Description>

                {!boxPrices
                    ?   <S.CenteredView>
                            <LoadingIndicator />
                        </S.CenteredView>
                    :   <>
                            <List>
                                {formData.selectedBox.plans.sort((a, b) => b.price.unit - a.price.unit).map((el,i)=>{
                                  return (
                                    <ItemBoxSize name={t('selectPaymentCycle.headlineInterval'+el.interval.unit)}
                                                 subline1={t('selectPaymentCycle.detail1'+el.interval.unit)}
                                                 subline2={t('selectPaymentCycle.detail2'+el.interval.unit)}
                                                 subline3={t('selectPaymentCycle.detail3'+el.interval.unit)}
                                                 price={t('selectBoxSize.sizeCard.price', {price:el.price.unit}) + " / " + t('selectPaymentCycle.interval'+el.interval.unit)}
                                                 note={(formData.selectedLocation === "STR" && formData.couponCode?.id === "STUTTGART2")
                                                     ? el.interval.unit === "YEARLY"
                                                        ? t('selectPaymentCycle.voucherCodeNote'+el.interval.unit+'_'+formData.selectedSize)
                                                        : t('selectPaymentCycle.voucherCodeNote'+el.interval.unit)
                                                     : null
                                                 }
                                                 image={'../assets/checkout/interval_'+el.interval.unit+'.png'}
                                                 isActive={selectedInterval === el.interval.unit}
                                                 onClick={onSelection}
                                                 id={el.interval.unit}
                                                 key={i}
                                                 badgeLabel={el.interval.unit === "YEARLY" ? t('popular').toUpperCase() : null}
                                    />
                                  )
                                })}
                            </List>

                            {(formData.selectedLocation !== "STR" || formData.couponCode?.id !== "STUTTGART2") &&
                                <div style={{marginTop:'-20px'}}>
                                    <InfoBox message={t('selectPaymentCycle.voucherCodeNoteGeneral')} />
                                </div>
                            }

                           <ButtonPrimary isDisabled={!formData.selectedSize}
                                          label={t('continue')}
                                          marginTop={24}
                                          onClick={onConfirm}
                           />
                        </>
                }
            </S.Content>

        </S.View>
    );
}

const List = styled.div`
    display: flex;
    flex-direction: column;
`;
