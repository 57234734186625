import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import React from "react";
import styled from 'styled-components';
import {colors} from 'styles/colors'

export default function InfoBox({message, children}) {
    return (
        <Container>
            <Left>
                <ErrorOutlinedIcon/>
            </Left>
            <Right>
                <p>{message}</p>
                {children}
            </Right>
        </Container>
    )
}

const Container = styled.div`

    border: 1px dashed ${colors.primary.primary_400};
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px 30px;
    text-align: left;
    
    display: flex;
    justify-content: center;
`;

const Left = styled.div`
    align-self: center;
`;

const Right = styled.div`
    align-self: center;
    margin: 0;
    padding-left: 20px;
    color: ${colors.primary.primary_300};
`;
