import React, {useEffect, useState} from "react";
import {formatString, splitSentencesIntoDivs} from "utils/mobile-app-helpers";
import { useAppState } from "contexts/AppStateContext";
import {useHistory, useParams} from "react-router-dom";
import useBox from "hooks/useBox";
import {CustomerStatus} from "constants/enums_customer";
import RequestAccessSuccess from "../request-shared-access/RequestAccessSuccessView";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Button} from "@mui/material";
import {cardData} from "constants/card-data";
import {userDashboardPaths} from "constants/paths";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {ContractPartnerStatus} from "constants/enums_contract_partner";
import {accountSetupPaths} from "navigation/AccountSetupRoutes";
import {checkoutPaths} from "navigation/CheckoutRoutes";
import FunctionsApiService from "services/FunctionsApiService";

export default function SingleBoxRequestStatusView() {
    const {strings, customer} = useAppState();
    const history = useHistory();
    const {boxId} = useParams();
    const {location, box} = useBox(boxId);
    const [mainCustomer, setMainCustomer] = useState();

    useEffect(() => {

        if(!box) return;

        const getMainCustomer = async () => {
            try {
                const res = await FunctionsApiService.getMainCustomerDetails(box?.partnerRequest?.customerId, box?.partnerRequest?.code)
                setMainCustomer(res.data);

            } catch (error){
                console.log(error);

            }
        }

        getMainCustomer();

    }, [box])

    if (!boxId) {
        return <RequestAccessSuccess/>;
    }

    if (!box || !customer || !mainCustomer) {
        return <></>;
    }


    if (box.partnerRequest.status === ContractPartnerStatus.ACCESSIBLE) {
        const sizeCardData = cardData[box.size.toLowerCase()];
        const boldName = '<b>' + mainCustomer.firstName + ' ' + mainCustomer.lastName + '</b>';
        return (
            <div className="access-request-container status">
                <div className="page-header overlay-header">
                    <IconButton className="placeholder-close-button" size="large">
                        <ArrowBackIcon/>
                    </IconButton>

                    {strings["ACCESS_REQUEST_STATUS_VIEW_TITLE_ACCEPTED"]}
                    <Button onClick={() => history.push(userDashboardPaths.boxesOverview)}><CloseRoundedIcon/></Button>
                </div>

                <div className="overlay">
                    <div className="box-container">
                        <div className="image-container">
                            <img className="large-image" src={sizeCardData.imgLargeSrc} alt={sizeCardData.imgAlt}/>
                            <img className="small-image" src={sizeCardData.imgSrc} alt={sizeCardData.imgAlt}/>
                        </div>
                        <div className="box-info">
                            <div>
                                Schließfachnummer: <span>{box.gunnebo.id}</span>
                            </div>
                            <div>
                                Hauptmieter: <span>{mainCustomer.firstName} {mainCustomer.lastName}</span>
                            </div>
                            <div>
                                Standort: <span>{location.address}</span>
                            </div>
                        </div>
                    </div>

                    <div className="text-success">
                        <p className="_content" dangerouslySetInnerHTML={{
                            __html: customer.status !== CustomerStatus.REGISTERED
                                ? splitSentencesIntoDivs(
                                    formatString(strings.ACCESS_REQUEST_STATUS_VIEW_DESCRIPTION_ACCEPTED, {name: boldName}))
                                : splitSentencesIntoDivs(
                                    formatString(strings.ACCESS_REQUEST_STATUS_VIEW_DESCRIPTION_ACCEPTED_REGISTERED, {name: boldName}))
                        }}/>

                        <Button variant="contained"
                                className="finish-granting-access-button blue-button"
                                onClick={() => history.push(accountSetupPaths.accountSetupRoot + checkoutPaths.enterContactDetails)}
                        >
                            {strings["GENERAL_CONTINUE"]}
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return <RequestAccessSuccess/>;
    }
}
