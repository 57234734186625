export const colors = {
    primary: {
        primary_100:'#091541',
        primary_200:'#23315E',
        primary_300:'#626A87',
        primary_350:'#9FA6BF',
        primary_400:'#CED0D9',
        primary_450:'#E4E6F0',
        primary_500:'#F4F7FD',
    },
    secondary: {
        secondary_100:'#1EE2C8',
        secondary_200:'#1BC9B2',
    },
    tertiary: {
       tertiary_100:'#1F97E2',
    },
    neutral: {
        background:'#F7F1EF'
    },
    semantic: {
      warning_dark:'#66393A',
      warning_light:'#FF8E91',
      warning:'#d32f2f',
    },
    special: {
        post_ident:'#FFCD00',
    }
};

