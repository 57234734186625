import React from "react";
import {Route} from "react-router-dom";
import EditContactDetailsView from "concerns/user-data/EditContactDetailsView";
import EditAddressView from "concerns/user-data/EditAddressView";

export const overlayPaths = {
    editContactDetails: '/edit-contact-details',
    editAddress: '/edit-address',
}

export const isOverlayPath = (path) => {
    let included = false;
    for (const arr of Object.entries(overlayPaths)) {
        if(path === arr[1]) included = true;
    }
    return included;
}

export const OverlayRoutes = [
    <Route
        path={overlayPaths.editContactDetails}
        render={() => {return  <EditContactDetailsView/>}}
        key={overlayPaths.editContactDetails}
    />,
    <Route
        path={overlayPaths.editAddress}
        render={() => {return  <EditAddressView/>}}
        key={overlayPaths.editAddress}
    />
];
