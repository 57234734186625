import * as Sentry from "@sentry/browser";

export const groupDigits = function(originalStr, groupSize, separator){

    if(originalStr.length <= groupSize){
        return originalStr;
    }

    let cleanString = removeDotsFromString(originalStr);
    let groupedString = '';

    for(let i=0; i<cleanString.length;i++){

        if(i > 0 && i % groupSize === 0){
            groupedString += separator;
        }

        groupedString += cleanString[i];
    }

    return groupedString;

};

export const removeDotsFromString = function(str){
    if(!str) return "";
    const regex = /[.]/g;
    return str.replace(regex, '');
};

export const storageAvailable = function(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        Sentry.captureException(e);
        return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

export const generateRandomString = function(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charLength = chars.length;
    let result = '';
    for ( var i = 0; i < length; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

export const objectsAreEqual = (obj1, obj2) => {
    if(!obj1 || !obj2) return false;
    let equal = true;
    for (const [key, value] of Object.entries(obj1)) {
        if(obj2[key] !== value) equal = false;
    }
    return equal;
}
