import React from "react";
import {Route} from "react-router-dom";
import CheckoutFlow from "concerns/checkout/CheckoutFlow";

export const checkoutPaths = {
    checkoutRoot: '/checkout',
    locationSelection: '/location',
    boxSizeSelection: '/size',
    paymentCycleSelection: '/price',
    enterContactDetails: '/contact',
    enterAddressDetails: '/address',
    checkoutSummary: '/summary',
    enterPassword: '/activation',
    choosePostIdent: '/identification',
    checkoutComplete: '/complete',
}

export const isCheckoutPath = (path) => {
    let included = false;
    if(path === checkoutPaths.checkoutRoot) included = true;
    for (const arr of Object.entries(checkoutPaths)) {
        if(path === checkoutPaths.checkoutRoot + arr[1]) included = true;
    }
    return included;
}

export const CheckoutRoutes = [
        <Route
            path={checkoutPaths.checkoutRoot}
            render={() => {return  <CheckoutFlow rootPath={checkoutPaths.checkoutRoot}/>}}
            key={checkoutPaths.checkoutRoot}
        />
    ];
