import { Button } from "@mui/material";
import React, {useCallback, useState} from "react";
import { useSnackbar } from "notistack";
import { useAppState } from "contexts/AppStateContext";
import {groupDigits} from "utils/mobile-app-helpers";
import FunctionsApiService from "services/FunctionsApiService";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoadingIndicator from "components/LoadingIndicator";

export default function RequestTanView() {
    const {strings} = useAppState();
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedTan, setGeneratedTan] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const generateTan = useCallback(()=>{
        setIsGenerating(true);

        const generate = async () => {
            try {
                let tan = 100000 + Math.ceil(Math.random() * 899999);
                await FunctionsApiService.generateTan({ tan: `${tan}` });
                setIsGenerating(false);
                setGeneratedTan(`${tan}`)
            } catch (error) {
                setIsGenerating(false);
                enqueueSnackbar(strings['ERROR_tan/create-failed'], {
                    variant: 'error',
                });
            }
        }

        generate();
    }, [enqueueSnackbar, strings])

    return (
        <div className="user-form-container request-tan">
            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                        srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                        src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                        alt="Menu Icon"
                    />
                </Button>

                {strings["REQUEST_TAN_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon /></Button>
            </div>

            <div className="padded-container">
                <div className="subtitle">{strings["REQUEST_TAN_VIEW_INTRO"]}</div>
                <p />
                <div className="title">{strings["REQUEST_TAN_VIEW_HEADLINE_CUSTOMER_SERVICE"]}</div>
                <p className="description">{strings["REQUEST_TAN_VIEW_DESCRIPTION_CUSTOMER_SERVICE"]}</p>

                <div className="tan-container">
                    <img className="tan-container-background"
                        srcSet={`${process.env.PUBLIC_URL}/assets/background_diagonal_lines.png, ${process.env.PUBLIC_URL}/assets/background_diagonal_lines@2x.png 2x`}
                        src={`${process.env.PUBLIC_URL}/assets/background_diagonal_lines.png`} alt="" />
                    <div className="tan-field-container">
                        {isGenerating ?
                            <div className="description">{strings["PLAN_VISIT_VIEW_LABEL_GENERATING_TAN"]}</div>
                            : <div className="tan-field">
                                <div className="description tan-field-label">{strings["PLAN_VISIT_VIEW_LABEL_ENTRY_TAN"]}</div>
                                <div className="tan-field-value">{generatedTan ? groupDigits(generatedTan, 3, ' ') : '------'}</div>
                            </div>
                        }
                    </div>
                </div>

                {isGenerating
                    ?   <LoadingIndicator marginTop={48}/>
                    :   <Button variant='contained'
                                disabled={false}
                                className="blue-button user-data-button"
                                onClick={generateTan}
                        >
                            {strings["PLAN_VISIT_VIEW_BTN_REQUEST_TAN"]}
                        </Button>
                }

            </div>
        </div>
    )
}
