import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Button} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {userDashboardPaths} from "constants/paths";
import React, {useCallback, useState} from "react";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useBox from "hooks/useBox";
import {groupDigits} from "utils/mobile-app-helpers";
import {from} from "rxjs";
import FunctionsApiService from "services/FunctionsApiService";
import {useSnackbar} from "notistack";

export default function BoxAccessConfigurationView() {
    const context = useAppState();
    const {setIsLoading, strings, goBack} = context;
    const history = useHistory();
    const {boxId} = useParams();
    const {box, latestPartnerRequest} = useBox(boxId);
    const [isRequestAccepted, setIsRequestAccepted] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [isCodeCopied, setIsCodeCopied] = useState(false)

    const onAcceptClick = useCallback(() => {
        setIsRequestAccepted(true);
    }, []);

    const onRejectClick = useCallback(() => {
        setIsLoading(true);
        from(FunctionsApiService.rejectPartnerRequest({
            boxId: box.id,
            partnerId: latestPartnerRequest.partnerId,
        })).subscribe({
            next: result => {
                setIsLoading(false);
                history.push(userDashboardPaths.boxDetails + '/' + box.id);
            },
            error: err => {
                setIsLoading(false);
                enqueueSnackbar(strings['ERROR/loading-failed'], {variant: 'error'});
            }
        });
    }, [box, latestPartnerRequest, enqueueSnackbar, history, setIsLoading, strings]);

    const onContinueClick = useCallback(() => {
        history.push(userDashboardPaths.confirmBookingAccess + '/' + box.id);
    }, [box, history]);

    if (!box) {
        return <></>;
    }

    const copyAccessCodeToClipboard = () => {
        const range = document.createRange();
        range.selectNode(document.querySelector('.access-code'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        enqueueSnackbar('Code in die Zwischenablage kopiert', {variant: 'success'});
        setIsCodeCopied(true);
    }

    return (
        <div className="box-access-configuration-container">
            <div className="page-header overlay-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["CONFIGURE_SHARED_ACCESS_VIEW_TITLE"]}
                <Button onClick={() => history.push(userDashboardPaths.boxDetails + '/' + box.id)}><CloseRoundedIcon/></Button>
            </div>

            <div className="overlay">
                <p className="_description">{strings["CONFIGURE_SHARED_ACCESS_VIEW_DESCRIPTION"]}</p>

                <div className="generate-access-code-container current-user-action-container">
                    <div className="_header-section">
                        <div>
                            <p className="_for-current-user">{strings["CONFIGURE_SHARED_ACCESS_VIEW_LABEL_YOU"]}</p>

                            <p className="_title">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_1_TITLE"]}</p>
                        </div>

                        {
                            (latestPartnerRequest || isCodeCopied) &&
                            <CheckCircleIcon className="checked-step"/>
                        }
                    </div>

                    {
                        !latestPartnerRequest &&
                        <>
                            <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_1_DESCRIPTION"]}</p>
                            <div className="access-code-container">
                                <span className="access-code">{groupDigits(box?.contract?.nextPartnerTan, 3, ' ')}</span>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#"
                                   className="copy-code"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       copyAccessCodeToClipboard();
                                   }}
                                >
                                    Kopieren
                                </a>
                            </div>
                        </>
                    }
                </div>

                <div className="other-user-confirmation-container">
                    <div className="_header-section">
                        <div>
                            <p className="_for-other-user">{strings["CONFIGURE_SHARED_ACCESS_VIEW_LABEL_OTHER_PERSON"]}</p>

                            <p className="_title">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_2_TITLE"]}</p>
                        </div>

                        {
                            latestPartnerRequest &&
                            <CheckCircleIcon className="checked-step"/>
                        }
                    </div>

                    {
                        !latestPartnerRequest &&
                        <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_2_DESCRIPTION"]}</p>
                    }
                </div>

                <div className="other-user-confirmation-container">
                    <div className="_header-section">
                        <div>
                            <p className="_for-other-user">{strings["CONFIGURE_SHARED_ACCESS_VIEW_LABEL_OTHER_PERSON"]}</p>

                            <p className="_title">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_3_TITLE"]}</p>
                        </div>

                        {
                            latestPartnerRequest &&
                            <CheckCircleIcon className="checked-step"/>
                        }
                    </div>

                    {
                        !latestPartnerRequest &&
                        <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_3_DESCRIPTION"]}</p>
                    }
                </div>

                <div className="other-user-confirmation-container">
                    <div className="_header-section">
                        <div>
                            <p className="_for-other-user">{strings["CONFIGURE_SHARED_ACCESS_VIEW_LABEL_OTHER_PERSON"]}</p>

                            <p className="_title">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_4_TITLE"]}</p>
                        </div>

                        {
                            latestPartnerRequest &&
                            <CheckCircleIcon className="checked-step"/>
                        }
                    </div>

                    {
                        !latestPartnerRequest &&
                        <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_4_DESCRIPTION"]}</p>
                    }
                </div>

                <div className="current-user-action-container">
                    <div className="_header-section">
                        <div>
                            <p className="_for-current-user">{strings["CONFIGURE_SHARED_ACCESS_VIEW_LABEL_YOU"]}</p>

                            <p className="_title">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_5_TITLE"]}</p>
                        </div>
                    </div>

                    {
                        !latestPartnerRequest &&
                        <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_5_DESCRIPTION"]}</p>
                    }

                    {
                        latestPartnerRequest &&
                        <>
                            <p className="_content">{strings["CONFIGURE_SHARED_ACCESS_VIEW_BULLET_5_DESCRIPTION_REQUESTED"]}</p>

                            <div className="_partner-container">
                                <div className="_partner-info">
                                    <span className="_name">{latestPartnerRequest.partnerData.firstName + ' ' + latestPartnerRequest.partnerData.lastName}</span>
                                    <span className="_mail">{latestPartnerRequest.partnerData.email}</span>
                                </div>

                                {
                                    isRequestAccepted &&
                                    <CheckCircleIcon className="checked-step"/>
                                }
                            </div>

                            {
                                !isRequestAccepted &&
                                <>
                                    <Button className="proceed-granting-access blue-button"
                                            onClick={onAcceptClick}
                                    >
                                        {strings["CONFIGURE_SHARED_ACCESS_BTN_CONFIRM_REQUEST"]}
                                    </Button>
                                    <Button className="proceed-granting-access default-button"
                                            onClick={onRejectClick}
                                    >
                                        Anfrage ablehnen
                                    </Button>
                                </>
                            }

                            {
                                isRequestAccepted &&
                                <Button className="proceed-granting-access green-button"
                                        onClick={onContinueClick}
                                >
                                    {strings["GENERAL_CONTINUE"]}
                                </Button>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
