import React, {useEffect} from "react";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Button} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useParams} from "react-router-dom";
import useBox from "hooks/useBox";
import {getDateStringFromTimestamp, getTimeStringFromTimestamp} from "utils/mobile-app-helpers";

export default function BoxAccessHistoryView() {
    const {strings, setIsLoading, authUser, goBack, replacePlaceholderInStrings} = useAppState();
    const {boxId} = useParams();
    const {box, visits} = useBox(boxId, true);

    useEffect(() => {
        setIsLoading(!visits);
    }, [setIsLoading, visits]);

    return (
        <div className="box-history">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {box?.name ? replacePlaceholderInStrings(strings["BOX_ACCESS_HISTORY_TITLE"], box.name) :
                    replacePlaceholderInStrings(strings["BOX_ACCESS_HISTORY_TITLE"],
                        strings.GENERAL_BOX + " " + strings.GENERAL_NR + box?.gunnebo?.id)}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <div className="box-description">
                    {strings["BOX_ACCESS_HISTORY_DESCRIPTION"]}
                </div>

                <div className="visits">
                    {
                        visits?.map((visit, index) => {
                            return (
                                <div key={index} className="visit">
                                    <div>
                                        <span className="semi-bold">
                                            {getDateStringFromTimestamp(visit.visitedAt.seconds * 1000)}
                                        </span>
                                        {getTimeStringFromTimestamp(visit.visitedAt.seconds * 1000)}
                                    </div>
                                    <span className={visit.visitedBy === authUser.uid ? 'visit-tag-sie' : 'visit-tag-partner'}>
                                        {visit.visitedBy === authUser.uid ? 'SIE' : 'PARTNER'}
                                    </span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}
