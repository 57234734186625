import React from 'react';
import {Button, List, ListItem, ListItemText} from "@mui/material";
import {Link, useHistory, useLocation} from "react-router-dom";
import {dashboardPaths} from "navigation/DashboardRoutes";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAppState } from "contexts/AppStateContext";
import {logoutUser} from "../../services/AuthenticationService";
import {authPaths} from "navigation/AuthRoutes";

export default function SidebarNav() {
    const {strings, customerGunnebo, resetAppData} = useAppState();
    const history = useHistory();
    const location = useLocation();
    const currentRoute = location.pathname;

    const customerServiceButton = () => {
        history.push(dashboardPaths.customerService);
        document.body.classList.remove('show-mobile-menu');
    }

    const screensWithoutNavbar = [
        dashboardPaths.selectBoxLocations,
        dashboardPaths.postidentConfirmation,
        dashboardPaths.postidentSelection,
        dashboardPaths.requestAccessForm,
        dashboardPaths.requestAccessSuccess,
        dashboardPaths.userDataCard,
    ];

    if (screensWithoutNavbar.includes(currentRoute) || currentRoute.startsWith(dashboardPaths.singleBoxRequestStatus) || currentRoute.startsWith(dashboardPaths.accessRequestConfirmation)) {
        document.body.classList.remove('show-menu');
    } else {
        document.body.classList.add('show-menu');
    }

    if (currentRoute.includes(dashboardPaths.boxAccessConfiguration) || currentRoute.includes(dashboardPaths.confirmBookingAccess) ||
        currentRoute.includes(dashboardPaths.finishGrantingAccessProcess) || currentRoute.includes(dashboardPaths.configureSharedAccess) ||
        currentRoute.includes('/:notification')) {
        document.body.classList.remove('show-menu');
    }

    const list = () => (
        <div role="presentation">
            <List className="menu">

                <div className="top-menu">
                    <div className="menu-header">
                        <img className="menu-logo"
                             srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white@2x.png 2x`}
                             src={`${process.env.PUBLIC_URL}/assets/menu-icons/logo_asservato_white.png`}
                             alt="Logo"
                        />
                        <Button className="close-button-nav" onClick={() => document.body.classList.remove('show-mobile-menu')}><CloseRoundedIcon/></Button>
                    </div>
                    <Link className={currentRoute.includes(dashboardPaths.boxesOverview) ? "link-list-item active" : "link-list-item"}
                          to={dashboardPaths.boxesOverview} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                        <ListItem button>
                            <ListItemText>
                                {strings["BOX_LIST_VIEW_TITLE"]}
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <Link className={currentRoute.includes(dashboardPaths.userData) ? "link-list-item active" : "link-list-item"}
                          to={dashboardPaths.userData} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                        <ListItem button>
                            <ListItemText>
                                {strings["ENTER_USER_DATA_VIEW_TITLE"]}
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <Link className={currentRoute.includes(dashboardPaths.invoices) ? "link-list-item active" : "link-list-item"}
                          to={dashboardPaths.invoices} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                        <ListItem button>
                            <ListItemText>
                                {strings["INVOICES_VIEW_TITLE"]}
                            </ListItemText>
                        </ListItem>
                    </Link>

                    { customerGunnebo?.hasPin &&
                        <Link className={currentRoute.includes(dashboardPaths.changePin) ? "link-list-item active" : "link-list-item"}
                            to={dashboardPaths.changePin} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                            <ListItem button>
                                <ListItemText>
                                    {strings["CHANGE_PIN_VIEW_TITLE"]}
                                </ListItemText>
                            </ListItem>
                        </Link>
                    }

                    <Link className={currentRoute.includes(dashboardPaths.requestTan) ? "link-list-item active" : "link-list-item"}
                          to={dashboardPaths.requestTan} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                        <ListItem button>
                            <ListItemText>
                                {strings["REQUEST_TAN_VIEW_TITLE"]}
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <Link className={currentRoute.includes(dashboardPaths.reportLoss) ? "link-list-item active" : "link-list-item"}
                          to={dashboardPaths.reportLoss} onClick={() => document.body.classList.remove('show-mobile-menu')}>
                        <ListItem button>
                            <ListItemText>
                                {strings["REPORT_LOSS_VIEW_TITLE"]}
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <a href="https://www.asservato.de/faq#global-nav" target="_blank" rel="noopener noreferrer">
                        <div className="link-list-item">
                            <ListItem button className="faq">
                                <ListItemText>
                                    {strings["FAQ_VIEW_TITLE"]}
                                </ListItemText>
                            </ListItem>
                        </div>
                    </a>

                    <div className="sidebar-btn">
                        <Button className="green-button"
                                onClick={customerServiceButton}>{strings["GENERAL_CUSTOMER_SERVICE"]}</Button>
                    </div>
                </div>

                <Link className="logout-list-item logout-button" to={authPaths.login} onClick={() => {
                    logoutUser().then(() => {
                        resetAppData();
                    }).catch((err) => console.error(err));
                }}
                >

                    <ListItem button>
                        <img className="logout-icon"
                             srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_logout_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_logout_white@2x.png 2x`}
                             src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_logout_white.png`}
                             alt="Logout"
                        />
                        <ListItemText>
                            Logout
                        </ListItemText>
                    </ListItem>
                </Link>

            </List>
        </div>
    );

    return (
        <div className="sidebar-drawer-container">
            <div className="desktop-menu">
                {list()}
            </div>
        </div>
    );
};
